import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import TextsLayout from '../ui/TextsLayout/TextsLayout'
import Texts from '@components/common/Texts/Texts'
import Picture from '@components/ui/Picture'
import { isStr } from '@components/helper'

// 需要依赖特定数据结构 data
const Banner = ({
  id = '',
  data, // CMS 数据（内容 + CMS 样式配置）
  className = null,
  pictureClass = 'w-full', // 为优化 CLS 请使用 aspect 补充图片比例
  // textPosition = 'hover', // 文本位置
  // textsAlign = 'left', // 文本对齐
  customTextClass = '', // 多行文本样式，默认样式请参考 @components/common/Texts.module.css
  loading = 'lazy',
  responsive: originalResponsive = [
    {
      breakpoint: 769,
      settings: {
        gridStyle: {
          rowStart: 2,
          colStart: 1,
          colEnd: 13,
        },
        align: 'center',
      },
    },
  ],
  ...rest
}) => {
  if (!data) return null

  let responsive = originalResponsive
  const {
    gridStyle,
    color,
    align,
    justify,
    position,
    responsive: cmsResponsive,
  } = data.textStyle || {}

  if (cmsResponsive) {
    responsive = cmsResponsive
  }

  const cssId = id ? id : uuidv4().slice(0, 5)
  return (
    <>
      <style suppressHydrationWarning={true}>
        {`${
          responsive
            ?.map((point) => {
              const { position } = point.settings || {}
              return `
                @media (max-width: ${point.breakpoint}px) {
                  .banner-container-${cssId} {
                    flex-direction: ${
                      position === 'above' ? 'column-reverse' : 'column'
                    }
                  }
                }
                `
            })
            .join(' ') || ''
        }`
          .replace(/\s+/g, ' ')
          .replace(/\n/g, ' ')}
      </style>
      <div
        suppressHydrationWarning={true}
        className={cn(
          'relative flex flex-col',
          `banner-container-${cssId}`,
          className,
          {
            ['flex-col-reverse']: position === 'above',
          }
        )}
        tabIndex={'onClick' in rest ? '0' : '-1'}
        role={'onClick' in rest ? 'button' : ''}
        onKeyPress={
          'onClick' in rest
            ? (e) => {
                if (e.key == 'Enter') {
                  rest.onClick()
                }
              }
            : () => {}
        }
        {...rest}
      >
        {(data.bgUrl || data.bg_url) && (
          <Picture
            source={`${data.bgUrl || data.bg_url}, ${
              data.mobileBgUrl || data.mobile_bg_url
            } 769`}
            alt={data.alt}
            className={pictureClass}
            loading={loading}
          />
        )}
        {data.videoUrl && (
          <video
            className={'w-full h-[98vh] md:h-[83vh] object-cover'}
            poster={data.posterUrl}
            src={data.videoUrl}
            autoPlay
            playsInline
            muted
            loop
          ></video>
        )}
        {Array.isArray(data.texts) && !isStr(data.texts[0]) ? (
          <div>
            {data.texts?.map((texts, index) => {
              const { gridStyle, responsive, color, align, justify, position } =
                texts.textStyle || {}
              return (
                <TextsLayout
                  id={`${id}-${index}`}
                  key={`TextsLayout-${index}`}
                  position={position === 'above' ? 'below' : position}
                  gridStyle={gridStyle}
                  align={align}
                  justify={justify}
                  responsive={responsive}
                >
                  <Texts
                    id={`${id}-${index}`}
                    texts={texts}
                    color={color}
                    responsive={responsive}
                    className={customTextClass}
                    align={align || 'left'}
                  />
                </TextsLayout>
              )
            })}
          </div>
        ) : (
          <TextsLayout
            id={cssId}
            position={position === 'above' ? 'below' : position}
            gridStyle={gridStyle}
            align={align}
            justify={justify}
            responsive={responsive}
          >
            <Texts
              id={cssId}
              texts={data.texts}
              color={color}
              align={align || 'left'}
              responsive={responsive}
              className={customTextClass}
            />
          </TextsLayout>
        )}
      </div>
    </>
  )
}

export default Banner
