import Icon from '@components/icons'
import Layout from './Layout'
import cn from 'classnames'
import s from './Manuals.module.css'

const typeMapping = {
  app: 'APP',
  declaration_of_conformity: 'Declaration of Conformity',
  driver: 'Driver',
  manual: 'Manual',
  software: 'Software',
}

const Documents = ({ data }) => {
  if (!data?.items) return null
  return (
    <Layout title={data.title}>
      <div className={cn(s.textSmall)}>
        {data.items.map((item, index) => {
          return (
            <a key={index} href={item.url} target="_blank" rel="noreferrer">
              <div className={s.document}>
                <span
                  className={cn(s.documentCol, 'truncate whitespace-normal')}
                  dangerouslySetInnerHTML={{
                    __html: typeMapping[item.type] || item.type,
                  }}
                ></span>
                <span
                  className={cn(s.documentCol, 'truncate whitespace-normal')}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                ></span>
                <span
                  className={cn(
                    s.documentCol,
                    'whitespace-nowrap md:whitespace-normal'
                  )}
                  dangerouslySetInnerHTML={{ __html: item.uploaded_at }}
                ></span>
                <Icon
                  iconKey="download"
                  className={cn('w-[21px] h-[20px]')}
                ></Icon>
              </div>
            </a>
          )
        })}
      </div>
    </Layout>
  )
}

export default Documents
