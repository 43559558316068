/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react'
import { atobID } from '@lib/utils/tools'
import { cn } from '@components/helper'
import { useRouter } from 'next/router'
import BannerLink from '@components/common/BannerLink'
import jump from 'jump.js'

import s from './index.module.css'

export type StarRatingProps = {
  productId: string
  type?: 'yotop' | 'judgeme' // anker 目前在 us,uk 使用 yotop 在 de 使用 judgeme
  judgemeBadge?: string // judgemeBadge 由 app 自动生成存储在 metafields
  to?: string // 点击之后跳转链接
  className?: string
  // hideWriteReviews?: Boolean // 没有 review 时隐藏 waite a review 文案（此连接仅在 listing 生效
}

/**
 * 星级评分
 * props: productId
 * 需要对productId进行base64处理
 * 在页面使用此组件需要在页面引用刷新 reviews 方法 (大部分页面多次使用此组件，因此只能放在页面
 * import { refreshStarRating } from '@lib/utils/tools'
 *   useEffect(() => {
    refreshStarRating()
  })
 */

const yotpoInstanceId = (locale = 'us') =>
  ({
    us: '637044',
    uk: '638361',
    ca: '638351',
  }[locale] || '')

const StarRating: FC<StarRatingProps> = ({
  productId,
  type = 'yotop',
  judgemeBadge = '',
  to = '',
  className,
  // hideWriteReviews = true,
}) => {
  if (!productId) return null

  const { locale } = useRouter()

  const id = atobID(productId)

  const handleJump = (id: string) => {
    const el = document.getElementById(id)
    if (el) {
      jump(`#${id}`, { duration: 200, offset: -75 })
    }
  }

  switch (type) {
    case 'judgeme':
      return (
        <div className={cn('relative flex', s.starRatingWrap, className)}>
          <div
            className="jdgm-widget jdgm-preview-badge"
            // data-id={id}
            // data-template="product.toggleVariant"
            // data-auto-install="false"
            dangerouslySetInnerHTML={{ __html: judgemeBadge }}
          />
          {/* 重写了星级跳转 */}
          <BannerLink
            to={`${to}#reviews-jdgm`}
            onClick={() => handleJump('reviews-jdgm')}
          />
        </div>
      )
    default:
      return (
        <>
          {/* {hideWriteReviews && (
            <style>
              {`
              .write-review-btn-hidden {
                display: none;
              }
            `}
            </style>
          )} */}
          <div className={cn('relative', className)}>
            <div
              className={cn(s.StarRating, 'yotpo-widget-instance')}
              data-yotpo-instance-id={yotpoInstanceId(locale)}
              data-yotpo-product-id={id}
              data-yotpo-cart-product-id={id}
              data-yotpo-section-id=""
            ></div>
            <BannerLink
              to={`${to}#reviews-yotpo`}
              onClick={() => handleJump('reviews-yotpo')}
            />
          </div>
        </>
      )
  }
}

export default StarRating
