const MyStyles = ({}) => (
  <style global jsx>{`
    .swiper-container-free-mode > .swiper-wrapper {
      transition-timing-function: linear;
    }
    .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      -o-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }
    .swiper {
      position: static;
    }
    .swiper-scrollbar-drag {
      background-color: var(--anker-color);
    }
    .swiper-pagination {
      display: none;
    }
    .my-pagination {
      display: flex;
      height: 3px;
      margin-top: 30px;
      background: #c8c8c8;
      border-radius: 999px;
    }
    @media (max-width: 769px) {
      .swiper {
        position: relative;
      }
      .my-pagination {
        margin-top: 10px;
      }
    }
    .swiper-pagination-bullet {
      display: block !important;
      margin: 0 !important;
      width: 50% !important;
      height: 100% !important;
      background: none;
    }
    .swiper-pagination-bullet-active {
      border-radius: 999px !important;
      background-color: var(--anker-color);
    }
    .swiper-button-next {
      right: -40px !important;
      color: #000;
    }
    .swiper-button-next::after {
      font-size: 28px;
      font-weight: 800;
    }
    .swiper-button-prev {
      left: -40px !important;
      color: #000;
    }
    .swiper-button-prev::after {
      font-size: 28px;
      font-weight: 800;
    }
  `}</style>
)

export default MyStyles
