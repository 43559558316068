import s from './index.module.css'
import cn from 'classnames'

function NextArrow({ gs = {}, isDisabled = false }) {
  // start_ai_generated
  // 优化操作数和操作符
  const arrowSvgClass = cn(s?.arrow_svg, {
    [s?.arrow_svg_disabled]: isDisabled,
  })
  const circleStroke = gs?.circleStroke || 'black'
  const pathStroke = gs?.pathStroke || 'black'
  // end_ai_generated

  return (
    <svg
      className={arrowSvgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
    >
      <circle
        className={s?.arrow_circle}
        cx="15.1426"
        cy="15"
        r="14"
        stroke={circleStroke}
        strokeWidth="2"
      />
      <path
        className={s?.arrow_path}
        d="M12.9995 9.64285L18.3567 15L12.9995 20.3571"
        stroke={pathStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function PrevArrow({ gs = {}, isDisabled = false }) {
  // start_ai_generated
  // 优化操作数和操作符
  const arrowSvgClass = cn(s?.arrow_svg, {
    [s?.arrow_svg_disabled]: isDisabled,
  })
  const circleStroke = gs?.circleStroke || 'black'
  const pathStroke = gs?.pathStroke || 'black'
  // end_ai_generated

  return (
    <svg
      className={arrowSvgClass}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle
        className={s?.arrow_circle}
        cx="15"
        cy="15"
        r="14"
        transform="matrix(-1 0 0 1 30 0)"
        stroke={circleStroke}
        strokeWidth="2"
      />
      <path
        className={s?.arrow_path}
        d="M17.1431 9.64285L11.7859 15L17.1431 20.3571"
        stroke={pathStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Arrow({
  isStart,
  isEnd,
  prevClass = 'custom-swiper-button-prev',
  nextClass = 'custom-swiper-button-next',
  className = '',
}) {
  // start_ai_generated
  // 优化操作数和操作符
  const containerClass = cn(
    'mt-[24px] flex items-center justify-center gap-[16px] md:hidden',
    className
  )
  const prevArrowClass = cn(prevClass, 'h-[30px] w-[30px] cursor-pointer')
  const nextArrowClass = cn(nextClass, 'h-[30px] w-[30px] cursor-pointer')
  const prevArrowStroke = isStart ? '#C4C4C6' : 'black'
  const nextArrowStroke = isEnd ? '#C4C4C6' : 'black'
  // end_ai_generated

  return (
    <div className={containerClass}>
      <div className={prevArrowClass}>
        {/* start_ai_generated */}
        <PrevArrow
          isDisabled={isStart}
          gs={{
            circleStroke: prevArrowStroke,
            pathStroke: prevArrowStroke,
          }}
        ></PrevArrow>
        {/* end_ai_generated */}
      </div>
      <div className={nextArrowClass}>
        {/* start_ai_generated */}
        <NextArrow
          isDisabled={isEnd}
          gs={{
            circleStroke: nextArrowStroke,
            pathStroke: nextArrowStroke,
          }}
        ></NextArrow>
        {/* end_ai_generated */}
      </div>
    </div>
  )
}

export { PrevArrow, NextArrow, Arrow }
