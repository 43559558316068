// 禁用React hooks的ESLint规则
/* eslint-disable react-hooks/rules-of-hooks */

// 导入Next.js的路由钩子和其他必要组件
import { useRouter } from 'next/router'
import { cn } from 'components/helper'
import { atobID } from '@lib/utils/tools'
import { Picture, Text } from '@components/ui'
import { Cta, Price } from '@components/product'
import { getAmazonLinkParams } from '@lib/utils/thirdparty'

// 导入样式模块
import s from './index.module.css'

// 导入产品相关组件
import Code from '@components/product/Code'
import Affirm from '@components/page/Affirm'
import Klarna from '@components/product/Klarna'
import BannerLink from '@components/common/BannerLink'
import Discount from '@components/product/Discount/Discount.js'
import useGradientGiftsCampaign from '@components/product/ProductSidebar/useGradientGiftsCampaign'

// 定义Card组件
export const Card = ({
  data,
  product,
  pageHandle,
  bundle,
  buttons,
  isMobile,
  summerCampaignDiscount,
}) => {
  // 使用Next.js的路由钩子获取当前语言环境
  const { locale } = useRouter()

  // 使用自定义钩子获取夏季活动的变体和行
  const { variants: summerGiveawayVariants, lines: summerGiveawayLines } =
    useGradientGiftsCampaign({
      quantity: 1,
      product,
      variant: {
        sku: product?.sku,
        price: summerCampaignDiscount?.useTotalAmount
          ? product?.price
          : product?.basePrice || product?.originPrice,
      },
      coupons: [],
      campaign: summerCampaignDiscount,
    })

  // 如果没有产品信息，则不渲染组件
  if (!product) return null

  // 构建亚马逊链接或者产品页面链接
  const amazonLink =
    product?.amazonLink ||
    product?.variants?.[0]?.metafields?.infos?.amazonLink ||
    product?.variantMetafields?.infos?.amazonLink ||
    ''
  const chunkedLink = amazonLink.split('/') // 分割亚马逊链接以获取ASIN
  const asin = chunkedLink[chunkedLink.length - 1] // 获取ASIN

  let link = ''
  if (data?.isAmazon) {
    link = amazonLink
      ? `${amazonLink}?${getAmazonLinkParams(asin, data.adCampaign, locale)}`
      : ''
  } else {
    const prefix = locale === 'us' ? '' : `/${locale}`
    const variant = atobID(product.variantId)
    const ref = data.ref || pageHandle
    link = `${prefix}/products/${product.handle}?variant=${variant}&ref=${ref}`
  }

  const handleBuried = (current_sku) => {
    if (typeof fbq !== 'undefined' && data?.isAmazon) {
      fbq('track', 'BuyAtAmazon', {
        content_ids: [].concat(current_sku),
      })
    }
  }

  // 渲染卡片内容
  return (
    <div
      className={cn(
        'autoDealsContent relative flex flex-col items-stretch bg-[#27374F]',
        {
          [s.delRoundedWrap]: data?.delRounded,
        }
      )}
    >
      {/* // 折扣组件 */}
      <Discount
        data={data}
        product={product}
        className="left-0 top-0 h-[40px] text-white md:left-0 md:top-0 md:h-[25px]"
        cns={{
          discountValue: 'text-[18px] font-[600] md:text-[12px]',
          discountText: `text-[18px] font-[500] md:text-[12px] ml-[2px]`,
          discount: 'flex-row left-0 top-[6px] w-[80%] h-[60%] md:top-[3px]',
        }}
      />

      {/* // 产品图片 */}
      <div className="relative flex items-center justify-center pt-[18px] md:p-[19px]">
        <BannerLink to={link} onClick={() => handleBuried(product?.sku)} />
        <Picture
          source={product.image}
          className={
            'h-[224px] object-cover md:h-[127px] md:w-[127px] [&_img]:!h-full [&_img]:w-full [&_img]:object-contain'
          }
        />
      </div>

      {/* // 产品信息和价格 */}
      <div
        className={
          'md:py-0-[12px] flex h-full flex-col justify-between px-[24px] py-[24px] md:p-[16px]'
        }
      >
        <div className="relative flex-1">
          <BannerLink to={link} onClick={() => handleBuried(product?.sku)} />
          <Text
            html={product.title}
            className={
              'text-[18px] l:text-[16px] font-[500] text-[#F5F6F7] md:!text-[14px]'
            }
            variant="paragraph"
          />
        </div>

        {/* // 分割线 */}
        <hr
          className={cn(
            'my-[18px] h-[1px] w-full border-none bg-[rgba(255,255,255,0.55)] md:my-[8px]',
            { ['hidden']: !data?.dividingLine || !product?.availableForSale }
          )}
        />

        {/* // 价格组件 */}
        <Price
          copy={data}
          product={product}
          className={cn({ ['mt-[8px] text-[#fff]']: !data?.dividingLine })}
          s={{
            price: 'text-[24px] font-[700] md:text-[14px] text-[#F90]',
            basePrice: 'text-[16px] font-[500] md:text-[12px] text-[#ffffff8c]',
          }}
        />
        {/* // 根据地区显示不同的分期付款信息 */}
        {locale === 'us' ? (
          <>
            {!data?.hideAffirm && (
              <Affirm
                price={product.price}
                className={
                  'min-h-[17px] flex-1 pt-[8px] font-[500] md:min-h-[10px] md:text-[12px]'
                }
              />
            )}
          </>
        ) : (
          <>
            {!data?.hideKlarna && (
              <Klarna
                className="min-h-[20px] pt-[8px] md:min-h-[12px]"
                price={product.price}
                settings={data.klarnaSettings}
              />
            )}
          </>
        )}

        {/* // 如果产品可售且不是亚马逊产品，则显示代码组件 */}
        {product?.availableForSale && !data?.isAmazon && (
          <Code
            data={data}
            code={product?.code}
            className="mb-[20px] mt-[8px] min-h-[20px] text-[14px] text-[#fff] md:mb-[16px] md:mt-[10px] md:text-[12px]"
            s={{
              copy: `text-[#00BEFA] font-[500]`,
              code: 'text-[rgba(255,255,255,0.85)] font-[500]',
              codePrefix: 'text-[rgba(255,255,255,0.85)] font-[500]',
            }}
          />
        )}

        {/* // 调用操作组件 */}
        <Cta
          copy={data}
          autoOpenSidebar={data?.autoOpenSidebar || false}
          autoApplyCode={!data?.remove_autodiscount}
          products={bundle ? bundle : [product]}
          pageHandle={pageHandle}
          traceProduct={product}
          buttons={
            isMobile && !data?.isAmazon
              ? data?.mobButtons || [
                  {
                    type: 'buyAddBundle',
                    theme: 'buyAddBundle',
                    addCartIcon:
                      'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/icon_cart.png?v=1727064306',
                  },
                ]
              : buttons
          }
          buynowGiftLineItems={summerGiveawayLines}
          gtmParam={{
            itemListName: data?.gtmTitle || data?.title,
          }}
          className={cn('md:!grid-cols-1', {
            [s.amazonBtnWrap]: data?.isAmazon,
          })}
          s={{
            button: 'bg-[#00BEFA] ',
            btnWrapper: data?.delRounded && s.btnWrapper,
            button: data?.delRounded && '!rounded-none',
            mask: data?.delRounded && '!rounded-none',
            buyAddBundle: 'bg-[#00BEFA] col-span-9 order-[2] !max-w-full',
            buyAddBundleCart: 'border-none',
            ...s,
          }}
        />
      </div>
    </div>
  )
}
