/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import { Button, Picture, Text } from '@components/ui'
import Icon from '@components/icons'
import usePrice from '@commerce/product/use-price'
import useProduct from '@shopify/product/use-product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { ColorSwatch } from '@components/product'
import { useUI } from '@components/ui'
import { atobID } from '@lib/utils/tools'
import { useAddItem } from 'lib/shopify/api/cart'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { appendRefParameter } from '@lib/utils/tools'
import s from './Comparison.module.css'
import sA1760 from './A1760.module.css'
import { useRouter } from 'next/router'

const Comparison = ({
  data,
  product: pageProduct = {},
  trace = defaultTrace,
  btnType = 'addToCart',
  model,
  shopCommon,
  pageHandle,
}) => {
  if (!data || !data.products) return null
  const { setRelativeLink } = useRelativeLink()
  const { openSidebar } = useUI()
  const addItem = useAddItem()
  const container = useRef()
  const [scroll, setScroll] = useState(false)
  const [loading, setLoading] = useState(false)
  const { locale } = useRouter()

  const keys =
    Object.keys(
      data.products[0]?.compare_fields || data.products[0]?.compareFields || {}
    ) || []

  const { data: recommendsProducts } = useProduct({
    handles: data.products.map((product) => product?.handle).join(','),
  })

  data.products?.map((product, i) => {
    product.result = recommendsProducts && recommendsProducts.products[i]
  })

  useEffect(() => {
    // 移动端的滑动监听
    const handler = () => {
      if (container.current.scrollLeft === 0) {
        setScroll(false)
      } else {
        setScroll(true)
      }
    }
    container.current && container.current.addEventListener('wheel', handler)
    return () => removeEventListener('wheel', handler)
  }, [])

  const addToCart = async ({ product, variant }) => {
    setLoading(true)
    trace.addToCart &&
      trace.addToCart({
        product,
        variant,
        quantity: 1,
        pageProduct,
      })
    try {
      await addItem({
        variantId: String(variant ? variant?.id : product.variants[0]?.id),
        quantity: 1,
      })
      openSidebar()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('err:', err)
    }
  }

  const getLink = ({ product, variant }) => {
    if (product.link) {
      return product.link
    } else if (variant) {
      return `${
        locale === 'us' ? '' : '/' + locale
      }/products/${product?.handle?.toLowerCase()}?variant=${atobID(
        variant?.id || ''
      )}&ref=${trace.ref}`
    }
    return `${
      locale === 'us' ? '' : '/' + locale
    }/products/${product?.handle?.toLowerCase()}?ref=${trace.ref}`
  }

  const handleJumpClick = ({ product, variant }) => {
    trace.jump({ product, variant, pageProduct })
  }

  const TitleCell = ({ product, variant, first, className, styles }) => {
    const price = usePrice({
      amount: variant?.price,
      currencyCode: product.price.currencyCode,
    })
    return (
      <div className={className}>
        {product.productType && data.showSeries !== false && (
          <p
            className={cn(
              s.series,
              'border',
              first && model !== 'normal-first' ? 'bg-black text-white' : ''
            )}
          >
            {product.productType}
          </p>
        )}
        <p
          className={cn(
            ' min-h-[60px] text-center md:min-h-fit md:text-left md:font-bold',
            data.products.length > 4 ? 'text-sm' : 'text-xl md:text-base'
          )}
          dangerouslySetInnerHTML={{ __html: product.name }}
        />
        {!styles && data?.styles !== 'a1760' && (
          <p className="min-h-[32px] pt-1 font-[500]">
            {variant?.availableForSale ? price.price : ''}
          </p>
        )}
      </div>
    )
  }

  const getButtonText = ({ variant, product }, sku) => {
    // 是否精确到 variant, 没有的话使用 product 的 availableForSale
    if (
      (sku && variant?.availableForSale) ||
      (!sku && product.availableForSale)
    ) {
      return data.buy_btn_text || data.btnText || shopCommon.add_to_Cart
    }
    return (
      variant?.metafields?.infos?.comingSoon ||
      product.metafields?.productInfos?.comingSoon ||
      data.soldOut ||
      shopCommon.soldOut
    )
  }
  const styles = {
    pcRow: cn(s.pcRow, { [sA1760.bg]: data?.styles === 'a1760' }),
    title: cn(s.title, { [sA1760.title]: data?.styles === 'a1760' }),
    firstRowItem: cn(s.firstRowItem, {
      [sA1760.firstRowItem]: data?.styles === 'a1760',
    }),
  }
  return (
    <>
      {data.title && (
        <div className={styles.title}>
          <Text html={data.title}></Text>
          <hr
            className={cn(
              'only-in-mobile mt-[20px] h-[1px] w-[90%] pb-[44px] text-[#999999] md:w-full md:pb-[5px]',
              {
                [sA1760.titleHr]: data?.styles === 'a1760',
              }
            )}
          />
        </div>
      )}
      {/* pc */}
      <div className="flex flex-col md:hidden">
        {/* first row */}
        <div className={styles.pcRow}>
          <p
            className={styles.firstRowItem}
            style={{ maxWidth: 1200 * (1 / (data.products.length + 1)) }}
          ></p>
          {data.products.map((p, i) => {
            const { result: product } = p
            if (!product?.variants) return null
            let variant = product.variants[0]
            if (Boolean(p.sku)) {
              variant = product.variants.find(
                (variant) => variant.sku === p.sku
              )
            }

            return (
              <div
                className={styles.firstRowItem}
                style={{ maxWidth: 1200 * (1 / (data.products.length + 1)) }}
                key={i}
              >
                {data?.mode === 'static' ? (
                  <div className="w-full">
                    <Picture
                      source={
                        p?.img ||
                        variant?.image?.url ||
                        product.images?.[0]?.url
                      }
                      alt={variant?.image?.alt || product.images?.[0]?.alt}
                      className="aspect-[4/5] w-full pb-2"
                      imgClassName="h-full object-contain"
                    />
                  </div>
                ) : (
                  <a
                    className="w-full"
                    href={getLink({ product, variant })}
                    onClick={() => handleJumpClick({ product, variant })}
                  >
                    <Picture
                      source={
                        p?.img ||
                        variant?.image?.url ||
                        product.images?.[0]?.url
                      }
                      alt={variant?.image?.alt || product.images?.[0]?.alt}
                      className="aspect-[4/5] w-full pb-2"
                      imgClassName="h-full object-contain"
                    />
                  </a>
                )}

                <TitleCell
                  styles={data?.styles}
                  product={product}
                  variant={variant}
                  first={i === 0}
                  className={cn(s.cell, 'text-xl')}
                />
                {data?.mode !== 'static' && btnType === 'addToCart' && (
                  <Button
                    className={cn(
                      'mb-4 mt-2 rounded-3xl border border-anker-color bg-anker-color px-5  py-1 font-semibold text-white hover:border hover:bg-white hover:text-anker-color '
                    )}
                    onClick={() => addToCart({ product, variant })}
                    loading={loading}
                    variant="plain"
                    disabled={
                      p.sku
                        ? !variant?.availableForSale
                        : !product.availableForSale
                    }
                  >
                    {getButtonText({ variant, product }, p.sku)}
                  </Button>
                )}
                {data?.mode !== 'static' && btnType !== 'addToCart' && (
                  <a
                    className="mb-4 mt-2 rounded-3xl border border-anker-color bg-anker-color px-5  py-1 font-semibold text-white hover:border hover:bg-white hover:text-anker-color "
                    href={getLink({ product, variant })}
                    onClick={() => handleJumpClick({ product, variant })}
                  >
                    {data.buy_btn_text || data.btnText}
                  </a>
                )}
              </div>
            )
          })}
        </div>
        {/* other rows */}
        {keys?.map((key, index) => {
          return (
            <div
              key={index}
              className={cn({ [sA1760.bg]: data?.styles === 'a1760' })}
            >
              <hr
                className={cn('h-[1px] w-full text-[#999999]', {
                  [sA1760.titleHr]: data?.styles === 'a1760',
                })}
              />
              <div className={styles.pcRow}>
                <Text
                  style={{ maxWidth: 1200 * (1 / (data.products.length + 1)) }}
                  className={cn(s.pcCell, '!pl-0', {
                    [sA1760.pcCellfirst]: data?.styles === 'a1760',
                    [sA1760.normalText]: data?.styles === 'a1760' && index > 1,
                  })}
                  html={key}
                  variant="paragraph"
                />
                {data.products.map((product, i) => {
                  if (product.compareFields?.[key] === '_color') {
                    return (
                      <div
                        key={`_color${i}`}
                        className={cn(
                          s.pcCell,
                          'gap-x-1.5',
                          i === 0 && model !== 'normal-first'
                            ? 'bg-[#FCFCFC]'
                            : ''
                        )}
                        style={{
                          maxWidth: 1200 * (1 / (data.products.length + 1)),
                        }}
                      >
                        {product.result?.options?.map((opt) =>
                          opt.displayName == 'color'
                            ? opt.values.map((v, i) => (
                                <ColorSwatch
                                  key={`${opt.id}-${i}`}
                                  border={false}
                                  className="!h-4 !w-4"
                                  imagePath={product.result?.images[0]?.url}
                                  color={v.hexColors ? v.hexColors[0] : v.label}
                                  Component="div"
                                />
                              ))
                            : ''
                        )}
                      </div>
                    )
                  }
                  return (
                    <div
                      key={i}
                      style={{
                        maxWidth: 1200 * (1 / (data.products.length + 1)),
                      }}
                      className={cn(
                        s.pcCell,
                        i === 0 && model !== 'normal-first'
                          ? 'bg-[#FCFCFC]'
                          : '',
                        {
                          [sA1760.pcCell]: data?.styles === 'a1760',
                          [sA1760.normalText]:
                            data?.styles === 'a1760' && index > 1,
                          [sA1760.boldText]:
                            data?.styles === 'a1760' && index < 2,
                          [sA1760.bgBlue1]:
                            data?.styles === 'a1760' && index < 2,
                          [sA1760.bgBlue2]:
                            data?.styles === 'a1760' && index > 1 && index < 7,
                          [sA1760.bgBlue3]:
                            data?.styles === 'a1760' && index > 6,
                        }
                      )}
                    >
                      <CellValue
                        variant="paragraph"
                        value={
                          product.compare_fields?.[key] ||
                          product.compareFields?.[key]
                        }
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      {/* mobile */}
      <div ref={container} className="only-in-mobile overflow-auto leading-[0]">
        {/* first row */}
        <div className={cn(s.mobileRow, s.keyRow)}>
          <p
            className={cn(
              s.mobileCell,
              s.firstColumnCell,
              scroll ? s.borderRight : ''
            )}
          ></p>
          <p className={cn(s.titleCell, s.mobileCell)}></p>
          {keys?.map((key, index) => {
            return (
              <div
                className={s.mobileKey}
                key={index}
                dangerouslySetInnerHTML={{ __html: key }}
              ></div>
            )
          })}
        </div>
        {/* other rows */}
        {data.products.map((p, i) => {
          const { result: product } = p
          if (!product?.variants) return null
          let variant = product.variants[0]
          if (Boolean(p.sku)) {
            variant = product.variants.find((variant) => variant.sku === p.sku)
          }
          return (
            <div
              key={i}
              className={cn(s.mobileRow, { [s.firstMobileRow]: i === 0 })}
            >
              {/* first column */}
              <div
                className={cn(
                  s.mobileCell,
                  s.firstColumnCell,
                  scroll ? s.borderRight : ''
                )}
              >
                {data?.mode === 'static' ? (
                  <div>
                    <Picture
                      source={variant?.image?.url || product.images?.[0]?.url}
                      alt={
                        p?.img ||
                        variant?.image?.alt ||
                        product.images?.[0]?.alt
                      }
                      className={s.productImage}
                      imgClassName="h-full object-scale-down"
                    />
                  </div>
                ) : (
                  <a
                    href={getLink({ product, variant })}
                    onClick={() => handleJumpClick({ product, variant })}
                  >
                    <Picture
                      source={variant?.image?.url || product.images?.[0]?.url}
                      alt={
                        p?.img ||
                        variant?.image?.alt ||
                        product.images?.[0]?.alt
                      }
                      className={s.productImage}
                      imgClassName="h-full object-scale-down"
                    />
                  </a>
                )}
                {data?.mode !== 'static' && (
                  <Icon
                    aria-label="add to cart"
                    iconKey="cart"
                    onClick={() => addToCart({ product, variant })}
                    className="w-[15px] cursor-pointer self-end text-[#00BEFA]"
                  />
                )}
              </div>
              {/* title and price column */}
              <TitleCell
                product={product}
                variant={variant}
                first={i === 0}
                className={cn(s.titleCell, s.mobileCell)}
              />
              {/* other column */}
              {keys?.map((key, index) => {
                if (p.compareFields?.[key] === '_color') {
                  return (
                    <div
                      key={`_color2${index}`}
                      className={cn(
                        s.mobileCell,
                        '!flex-row flex-wrap items-center gap-x-1.5'
                      )}
                    >
                      {product?.options?.map((opt) =>
                        opt.displayName == 'color'
                          ? opt.values.map((v, i) => (
                              <ColorSwatch
                                key={`${opt.id}-${i}`}
                                border={false}
                                imagePath={product?.images[0]?.url}
                                className="!h-4 !w-4"
                                color={v.hexColors ? v.hexColors[0] : v.label}
                                Component="div"
                              />
                            ))
                          : ''
                      )}
                    </div>
                  )
                }
                return (
                  <div className={s.mobileCell} key={index}>
                    <CellValue
                      value={p.compare_fields?.[key] || p.compareFields?.[key]}
                    />
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      {data?.tips?.text && (
        <a
          href={appendRefParameter({
            url: setRelativeLink({
              link: data?.tips?.href,
            }),
            refValue: data?.resourcesRef || data?.urlRef || pageHandle || '',
          })}
          className="text-center block mx-auto mt-5 md:mt-3 text-[#878787] text-[16px] font-[500] leading-[1.1] l:text-[12px] l:leading-[1.1] hover:text-[var(--brand-color)] underline"
          dangerouslySetInnerHTML={{
            __html: data?.tips?.text,
          }}
          target={data?.target || '_blank'}
        ></a>
      )}
    </>
  )
}

export default Comparison

const CellValue = ({ value, variant }) => {
  if (value === '%N%') {
    return <XCircleIcon className="mx-auto h-8 w-8" />
  }
  if (value === '%Y%') {
    return <CheckCircleIcon className="mx-auto h-8 w-8" />
  }
  return <Text html={value} variant={variant} />
}

const defaultTrace = {
  ref: 'crossbuy',
  addToCart: ({ product, variant, quantity, pageProduct }) => {
    pageGTMEvent({
      event: 'eeEvent',
      eventCategory: 'EnhancedEcommerce',
      eventAction: 'Added Product',
      eventLabel: variant?.sku, //读取点击推荐的SKU
      ecommerce: {
        currencyCode: product.price.currencyCode,
        add: {
          products: [
            {
              name: product.name,
              id: variant?.sku, //SKU
              category: product.productType,
              categoryName: '',
              brand: product.vendor,
              variant: variant?.name,
              price: variant?.price,
              quantity: quantity,
            },
          ], //取点击推荐产品的信息
        },
      },
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'add_to_cart',
      event_parameters: {
        page_group: 'Product Detail Page_' + pageProduct.variants[0].sku,
        position: 'cross_buy',
        currency: product.price.currencyCode,
        value: '', //?
        items: [
          {
            item_id: variant?.sku, //SKU
            item_name: product.name,
            item_brand: product.vendor,
            item_category: product.productType,
            item_variant: variant?.name,
            price: variant?.price,
            quantity,
          },
        ], //取点击推荐产品的信息
      },
    })
  },
  jump: ({ product, variant, pageProduct }) => {
    pageGTMEvent({
      event: 'uaEvent', // Trigger
      eventCategory: 'Product Detail Page_' + pageProduct.variants[0].sku, //该listing页的SKU
      eventAction: 'cross_buy',
      eventLabel: variant?.sku, //读取跳转页的SKU
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'lp_button',
      event_parameters: {
        page_group: 'Product Detail Page_' + pageProduct.variants[0].sku,
        button_name: variant?.sku,
        position: 'cross_buy',
      },
    })
  },
}
