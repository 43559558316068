const normalizeSkuData = (item, result, coupons, data) => {
  const {
    handle,
    sku,
    title,
    img,
    img_mob,
    custom_img,
    custom_mb_img,
    bgImg,
    mobBgimg,
    label,
    description,
    price,
    sellingPointList,
    variant_url,
    progressInfo,
  } = item || {}

  const product = result?.products?.find(
    (p) => p?.handle?.toUpperCase() === handle?.toUpperCase()
  )

  if (product) {
    let variant = product.variants?.find(
      (product) => product?.sku.toUpperCase() === sku?.toUpperCase()
    )
    if (!variant) {
      return null
      // variant = product.variants?.[0] || {}
    }
    // 来自接口
    const discount = coupons[variant?.sku]?.[0] || {}
    return {
      ...item,
      label: label || product.name,
      image: img || variant?.image?.url, //待废弃
      mobileImage: img_mob || img || variant?.image?.url, //待废弃
      // image: img || variant?.image?.url,
      // mobileImage: img_mob || img || variant?.image?.url,
      img: img || custom_img || variant?.image?.url,
      img_mob: img_mob || custom_mb_img || variant?.image?.url,
      bgImg: bgImg,
      mobileBgImg: mobBgimg,
      title:
        title ||
        variant?.metafields?.infos?.page_short_title ||
        variant?.name ||
        product.name,
      description: description,
      code: product.title,
      handle: product.handle?.toLowerCase(),
      id: product.id,
      sku: variant.sku,
      metafields: product.metafields,
      variantMetafields: variant?.metafields,
      availableForSale: variant.availableForSale,
      basePrice: discount.variant_price4wscode
        ? variant.price
        : variant.listPrice,
      price: discount.variant_price4wscode ?? variant.price,
      discountType: discount.value_type || 'fixed_amount',
      currencyCode: product.price?.currencyCode,
      discountValue:
        Math.abs(discount.value) ||
        (variant.listPrice &&
          new Decimal(variant.listPrice)
            .minus(variant.price)
            .toFixed(0, Decimal.ROUND_DOWN)) ||
        0,
      code: discount.title,
      variantId: variant.id,
      brand: product.vendor,
      category: product.productType,
      tags: product.tags,
      giftImage: variant?.metafields?.infos?.gift_image,
      sellingPointList:
        sellingPointList || variant?.metafields?.infos?.sellingPointList || [],
      progressInfo:
        progressInfo || variant?.metafields?.infos?.progressInfo || {},
    }
  } else {
    return null
  }
}

export default normalizeSkuData
