import { Picture } from '@components/ui'
import s from './SeriesIntro.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const SeriesIntro = ({ data = [], product }) => {
  const { setRelativeLink } = useRelativeLink()
  const copywriting =
    data && data?.find((series) => series.product_type === product.productType)

  if (!copywriting) return null
  return (
    <div
      style={{ color: copywriting.text_color || '#000' }}
      className={s.container + ' margin-center'}
    >
      <Picture
        source={`${copywriting.pc_bg_url},${copywriting.mobile_bg_url} 769`}
        className="aspect-[12/7] w-full rounded-3xl md:aspect-[3/4] md:rounded-none"
      />
      <div className={s.textContainer}>
        {copywriting.texts.map((text, index) => {
          return (
            <div
              key={index}
              className={s.text}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          )
        })}
        <div className="flex items-center justify-center">
          {copywriting.links?.map((link, index) => {
            return (
              <div
                style={{ borderColor: copywriting.text_color || '#000' }}
                className={s.link}
                key={`link-${index}`}
              >
                <a
                  href={setRelativeLink({ link: link.url })}
                  dangerouslySetInnerHTML={{ __html: link.description }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SeriesIntro
