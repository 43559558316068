import { Text } from '@components/ui'
import cn from 'classnames'
import s from './index.module.css'

const TitleComp = ({
  data,
  isMobile,
  boxCName,
  cName,
  subCName,
  descCName,
  variant,
  subVariant,
  descVariant,
}) => {
  if (!(data?.title || data?.subTitle || data?.desc)) return null
  return (
    <div className={cn(boxCName, s.titleBox)}>
      {data?.title && (
        <Text
          variant={variant}
          html={data?.title}
          className={cn(cName, s.title, {
            [s.bgTitle]: data?.bgTitle,
          })}
        />
      )}

      {data?.subTitle && (
        <Text
          variant={subVariant}
          html={data?.subTitle}
          className={cn(subCName, s.subTitle)}
        />
      )}

      {data?.desc && (
        <Text
          variant={descVariant}
          html={data?.desc}
          className={cn(descCName, s.desc)}
        />
      )}
    </div>
  )
}

export default TitleComp
