/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { useState, useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Picture, Text } from '@components/ui'
import Cookies from 'js-cookie'
import Player from '@vimeo/player'
import { parse } from 'query-string'
import { AdvancedVideo } from '@cloudinary/react'
import { Cloudinary } from '@cloudinary/url-gen'
import { scale } from '@cloudinary/url-gen/actions/resize'
import TextsLayout from '@components/ui/TextsLayout/TextsLayout'
import { useTimer } from 'react-timer-hook'
import Form from './Form'
import styles from './Subscription.module.css'
import preSaleStyles from './PreSale.module.css'
import PreSaleTest from './PreSaleTest.module.css'
import A1780PreStyles from './A1780PreStyles.module.css'

const Subscription = ({
  data,
  copywriting,
  styles: gs,
  trace = () => {},
  shopCommon,
}) => {
  if (!data) return null
  // 可传递样式进来替换原样式
  const stylesModule = {
    preSaleStyles,
    PreSaleTest,
    A1780PreStyles,
  }
  const s = stylesModule[data?.stylesModule]
    ? stylesModule[data?.stylesModule]
    : { ...styles, ...gs }

  const [modal, setModal] = useState(null)
  const [title, setTitle] = useState(data.title)
  const [isPlayed, setIsPlayed] = useState(false)
  const [cldVideoId, setCldVideoId] = useState('')

  useEffect(() => {
    if (data.utmContent) {
      let utmCookie = Cookies.get('utm_a1780')
      const q = (key) => parse(window.location.search)[key]
      let utmText = q(data.utmContent)
      if (utmCookie) {
        let uCookie = JSON.parse(utmCookie)
        if (!utmText && data?.utm[uCookie?.key]) {
          setTitle(data?.utm[uCookie?.key])
        }
      }

      if (utmText && data?.utm[utmText]) {
        Cookies.set(
          'utm_a1780',
          {
            utm: data?.utm[utmText],
            key: utmText,
          },
          {
            Domain: window.location.hostname,
            expires: 7,
          }
        )
        setTitle(data?.utm[utmText])
      }
    }
  }, [data])

  const vimeoInit = () => {
    let player
    if (window.screen.width > 769) {
      player = new Player(`video_${data.vimeoId}`, {
        id: data.vimeoId,
        muted: true,
        loop: 0,
        width: 750,
        controls: false,
        quality: '1080p',
        autoplay: true,
        autopause: false,
      })
    } else {
      player = new Player(`mob_video_${data.mob_vimeoId}`, {
        id: data.mob_vimeoId,
        muted: true,
        loop: 0,
        width: 750,
        controls: false,
        quality: '540p',
        autoplay: true,
        autopause: false,
      })
    }

    //window.player_1780 = player

    // let timer = null
    // let gap = 50
    // player.on('play', function () {
    //   player.getDuration().then((dur) => {
    //     timer = setInterval(() => {
    //       player.getCurrentTime().then((s) => {
    //         if (s >= dur * 0.98) {
    //           player.pause()
    //         }
    //       })
    //     }, gap)
    //   })
    // })
    // player.on('pause', function () {
    //   clearInterval(timer)
    //   setIsPlayed(true)
    // })
    player.on('ended', function () {
      setIsPlayed(true)
    })
  }

  const videoInit = () => {
    if (window.screen.width > 769) {
      const video = document.getElementById('video_banner')
      video.addEventListener('ended', () => {
        setIsPlayed(true)
      })
    } else {
      const mob_video = document.getElementById('mob_video_banner')
      mob_video.addEventListener('ended', () => {
        setIsPlayed(true)
      })
    }
  }

  const cldVideoInit = () => {
    const cld = new Cloudinary({
      cloud: {
        cloudName: 'anker-dtc',
      },
      url: {
        source: true,
      },
    })
    const video =
      window.screen.width > 769
        ? cld.video(data.cldVideoId)
        : cld.video(data.mob_cldVideoId)
    video.resize(scale().width(window.screen.width))
    setCldVideoId(video)
  }

  useEffect(() => {
    data.videoUrl && videoInit()
    data.vimeoId && !data.videoUrl && vimeoInit()
    data.cldVideoId && cldVideoInit()
  }, [])

  const updateModal = ({ type, msg }) => {
    let timer
    if (type === 'success') {
      setModal({
        type,
        msg: copywriting?.earlyCouponSuccess || data?.early_coupon_success,
      })
    } else {
      setModal({ type, msg })
    }
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setModal(null)
    }, 12000)
  }

  const hideFormMode = new Date() >= new Date(data.hideFormDate)

  return (
    <>
      {data.vimeoId || data.videoUrl || data.cldVideoId ? (
        <div className={s.banner}>
          <Picture
            className={cn(s.bgImg)}
            alt={data.alt}
            source={`${data.bgUrl}, ${data.mobileBgUrl} 769`}
            loading="eager"
          />
          {cldVideoId && (
            <div className={s.video}>
              <AdvancedVideo
                muted
                autoPlay
                preload="true"
                playsInline
                cldVid={cldVideoId}
                onEnded={() => {
                  setIsPlayed(true)
                }}
              ></AdvancedVideo>
            </div>
          )}
          {data.videoUrl && (
            <div className={s.video}>
              <video
                className={'w-full relative md:hidden'}
                poster={data.posterUrl}
                src={data.videoUrl}
                autoPlay
                playsInline
                muted
                id={'video_banner'}
              ></video>
              <video
                className={'w-full relative hidden md:block'}
                poster={data.mob_posterUrl}
                src={data.mob_videoUrl}
                autoPlay
                playsInline
                muted
                id={'mob_video_banner'}
              ></video>
            </div>
          )}
          {data.vimeoId && (
            <div className={s.video}>
              <div
                id={'video_' + data.vimeoId}
                className={s.iframeContainer}
              ></div>
              <div
                id={'mob_video_' + data.mob_vimeoId}
                className={s.iframeContainer}
              ></div>
            </div>
          )}
          <Picture
            className={cn(s.endImg, `${isPlayed ? s.show : s.hide}`)}
            alt={data.alt}
            source={`${data.endUrl}, ${data.mobileEndUrl} 769`}
            loading="eager"
          />
        </div>
      ) : (
        data.bgUrl && (
          <Picture
            className={s.bgImg}
            alt={data.alt}
            source={`${data.bgUrl}, ${data.mobileBgUrl} 769`}
            loading="eager"
          />
        )
      )}
      {!hideFormMode && data.countDown && (
        <TextsLayout
          maxWidth={1500}
          align="right"
          gridStyle={{ rowStart: 10, colStart: 1, colEnd: 13 }}
          responsive={[
            {
              breakpoint: 679,
              settings: {
                align: 'center',
                justify: 'center',
                gridStyle: { rowStart: 12, colStart: 1, colEnd: 13 },
              },
            },
          ]}
          className={cn(
            `${
              (data.vimeoId || data.cldVideoId) && isPlayed
                ? s.txtAniEnd
                : s.txtAni
            }`
          )}
          putTextClassName={'mt-7 md:mt-0'}
        >
          <CountDown
            data={{ ...data, ...shopCommon?.countDown }}
            s={styles}
            endTime={data.endTime}
          />
        </TextsLayout>
      )}
      <TextsLayout
        justify={data?.stylesJustify ? data?.stylesJustify : 'center'}
        align={data?.stylesAlign ? data?.stylesAlign : 'center'}
        className={cn(s.textContainer)}
      >
        <div
          className={cn(
            `${
              (data.vimeoId || data.cldVideoId) && isPlayed
                ? s.txtAniEnd
                : s.txtAni
            }`
          )}
        >
          {data.preTitle && (
            <p
              className={s.preTitle}
              dangerouslySetInnerHTML={{ __html: data.preTitle }}
            />
          )}
          <h2
            className={s.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
          {data.subTitle && (
            <p
              className={s.subTitle}
              dangerouslySetInnerHTML={{ __html: data.subTitle }}
            />
          )}
          {data.priceTitle && (
            <div
              className={s.priceTitle}
              dangerouslySetInnerHTML={{ __html: data.priceTitle }}
            ></div>
          )}
          {/* 最后一天关闭订阅入口，展示倒计时 */}
          {hideFormMode ? (
            <CountDown
              hideFormMode={hideFormMode}
              data={{ ...data, ...shopCommon?.countDown }}
              s={styles}
              endTime={data.endTime}
            />
          ) : (
            <Form data={data} s={s} setModal={updateModal} trace={trace} />
          )}
        </div>
        {modal && !data?.popup && (
          <p className={s[modal.type]}>{modal.msg || 'welcome'}</p>
        )}
      </TextsLayout>

      {modal && data?.popup && (
        <div className={cn(styles.masks, s.masks)}>
          <div className={styles.popContent}>
            <button className={styles.closeBtn} onClick={() => setModal(null)}>
              <XMarkIcon className="w-4 h-4" />
            </button>
            <p
              className={s.popcontent}
              dangerouslySetInnerHTML={{ __html: modal?.msg || 'welcome' }}
            />
          </div>
        </div>
      )}
      {data.bottomTip && (
        <div
          className={s.bottomTip}
          dangerouslySetInnerHTML={{ __html: data.bottomTip }}
        />
      )}
    </>
  )
}

export default Subscription

const CountDown = ({ endTime, data, s, hideFormMode }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(endTime),
  })

  if (new Date() > new Date(endTime)) return null
  return (
    <div
      className={cn(s.countDown, {
        [s.hideFormMode]: hideFormMode,
      })}
    >
      <Text html={data.prefix} className={s.prefix} variant="paragraph" />
      <div className="flex">
        {days > 0 && (
          <Item
            time={days}
            copy={data.days}
            s={s}
            hideFormMode={hideFormMode}
          />
        )}
        <Item
          time={hours}
          copy={data.hours}
          s={s}
          hideFormMode={hideFormMode}
        />
        <Item
          time={minutes}
          copy={data.minutes}
          s={s}
          hideFormMode={hideFormMode}
        />
        <Item
          time={seconds}
          copy={data.seconds}
          last={true}
          s={s}
          hideFormMode={hideFormMode}
        />
      </div>
    </div>
  )
}

const n = (n) => (n > 9 ? '' + n : '0' + n)

const Item = ({ time, copy, s, last, hideFormMode }) => (
  <div className="flex">
    <div className="flex flex-col items-center">
      <div
        suppressHydrationWarning={true}
        className={cn(s.code, {
          [s.hideFormMode]: hideFormMode,
        })}
      >
        {n(time)}
      </div>
      <div className={s.copy}>{copy}</div>
    </div>
    {!last && <span className={cn(s.colon, 'mt-2 md:mt-[3px]')}>:</span>}
  </div>
)
