import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

const MyTabs = ({ tabs, onChange, activeTab }) => {
  return (
    <Tabs
      value={activeTab}
      sx={{
        margin: '0 auto 3rem auto',
        justifyContent: 'center',
        width: 'fit-content',
        minHeight: '0px',
        '& .MuiTabs-indicator': {
          backgroundColor: 'var(--brand-color)',
          height: '3px',
        },
        '@media (max-width:769px)': {
          margin: '0 auto 1.5rem auto',
        },
      }}
      onChange={onChange}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab}
          label={tab}
          sx={{
            color: '#94969C',
            borderBottom: '3px solid #C8C8C8',
            textTransform: 'none',
            fontFamily: 'inherit',
            fontSize: '1.5rem',
            letterSpacing: 'inherit',
            '&.Mui-selected': {
              color: 'var(--brand-color)',
              fontWeight: '700',
            },
            '&:hover': {
              color: 'var(--brand-color)',
            },
            '@media (max-width:769px)': {
              fontSize: '1rem',
              padding: '0.25rem 0.5rem',
              minWidth: '0px',
              lineHeight: '1.5',
              minHeight: 'initial',
            },
          }}
        />
      ))}
    </Tabs>
  )
}

export default MyTabs
