import { Picture } from "@components/ui"
import s from "./KlarnaBanner.module.css"

const KlarnaBanner = ({ data }) => {
  return <div className="w-full bg-black py-[70px] flex items-center md:py-[40px] md:px-[35px] px-2">
    <div className="margin-center max-w-base flex md:flex-col md:items-center">
      <div>
        <Picture source={data.img_url} className="w-[180px] h-[90px] md:w-[90px] md:h-[45px] mb-[10px]" />
      </div>
      <div className="flex justify-center flex-col pl-[46px] md:text-center md:pl-0">
        {data.texts && data.texts.map((text, index) => {
          return <div className={s.text} key={index} dangerouslySetInnerHTML={{ __html: text }}></div>
        })}
      </div>
    </div>
  </div>
}

export default KlarnaBanner