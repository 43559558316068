import s from './index.module.css'
import Modal from 'react-modal'
import Code from '@components/product/Code'
import { Picture, Text } from '@components/ui'
import { getQuery } from '@lib/utils/cookies'
import { useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const PopCoupon = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const query = getQuery()
    if (query?.ref && query.ref == 'booth_popup') {
      setIsOpen(true)
    }
  }, [])

  if (!data) return null

  const handleGTM = () => {
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'copy',
      eventAction: '',
      eventLabel: 'undefined',
      eventValue: 'undefined',
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      // ariaHideApp={isOpen}
      onRequestClose={() => setIsOpen(false)}
      className="enterInfo"
      overlayClassName="Overlay"
      contentLabel="enter-info"
    >
      <div className={s.popWrap}>
        <div className={s.popMain}>
          <button
            aria-label="close pop"
            onClick={() => setIsOpen(false)}
            className="absolute right-[20px] top-[18px] z-[5] l:right-[18px] l:top-[18px]"
          >
            <XMarkIcon className=" h-[20px] w-[20px] stroke-[#000]" />
          </button>
          <Picture source={data.img} className="relative" />

          <div className="absolute top-0 left-0 z-[1] flex h-full w-full flex-col items-center">
            <div className="pt-[56px] text-center md:pt-[36px]">
              {data?.claim && (
                <Text
                  html={data?.claim}
                  className="text-[28px] font-semibold md:text-[22px]"
                />
              )}
              {data?.offText && (
                <Text html={data?.offText} className={s.title} />
              )}
              {data?.discountText && (
                <Text
                  html={data?.discountText}
                  className="mt-[30px] text-[20px] font-semibold text-[#fff] md:text-[16px]"
                />
              )}
              {data?.popCode && (
                <Code
                  data={data}
                  code={data?.popCode}
                  className={s.code}
                  handleGTM={handleGTM}
                  s={{
                    codePrefix: 'text-white',
                    code: 'text-white ml-[2px]',
                    codeAndCopy: s.codeAndCopy,
                  }}
                />
              )}
            </div>

            {data?.tips && (
              <Text
                html={data?.tips}
                className={
                  'absolute bottom-[25px] z-[1] text-[14px] font-medium text-[#777] md:bottom-[15px] md:w-[90%] md:text-center md:text-[12px]'
                }
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PopCoupon
