/* eslint-disable react-hooks/rules-of-hooks */
import { Picture } from '@components/ui'
import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import s from './StaggeredTiles.module.css'
import debounce from 'lodash.debounce'
import NormalTexts from '@components/common/NormalTexts/NormalTexts'

const StaggeredTiles = ({ data }) => {
  if (!data) return null
  const { layout = 'horizontal', items } = data
  const itemsRef = useRef([])
  // const picturesRef = useRef([])
  const containerRef = useRef()
  const [animate, setAnimate] = useState(false)
  const [active, setActive] = useState()

  useEffect(() => {
    // 用于 focus
    itemsRef.current = itemsRef.current.slice(0, items.length)
    itemsRef.current.map((item, index) => (item.index = index))
    // 用于图片动效
    // picturesRef.current = picturesRef.current.slice(0, items.length)
  }, [items])

  const negativeToZero = (num) => Math.max(0, num)

  const getPercentOnView = (item, fixedTop = 65) => {
    const rect = item.getBoundingClientRect()
    // viewport 高度
    const viewportHeight = window.innerHeight - fixedTop
    // 盒子顶部到 viewport 顶部的距离
    const top = rect.top - fixedTop
    // 盒子底部到 viewport 底部的距离
    const bottom = viewportHeight - top - rect.height
    // 盒子在 viewport 内的高度(最终计算时负数都应该变成 0)
    const heightInViewport = negativeToZero(
      viewportHeight - negativeToZero(top) - negativeToZero(bottom)
    )
    // console.log("index", item.index, "top", top, "bottom", bottom, "heightInViewport", heightInViewport)
    return heightInViewport / viewportHeight
  }

  const scrollHandler = () => {
    itemsRef.current.map((item) => {
      item.percent = getPercentOnView(item)
    })
    // 排序优先级: 在 viewport 占比大优先 => 展示顺序前优先
    const sorted = itemsRef.current.sort(
      (a, b) => b.percent - a.percent || a.index - b.index
    )
    // console.log("sorted", sorted.map((item) => [item.index, item.percent]))
    setActive(sorted[0].index)
  }

  const refCbk = (el, targetRef, index) => {
    if (!el) return
    targetRef.current[index] = el
  }

  const handler = debounce(scrollHandler, 10)

  useEffect(() => {
    if (data?.hide_ani) return
    setActive(0)
    // TODO 用动画库
    // const observer = new IntersectionObserver((entries) => {
    //   picturesRef.current.forEach((element) => {
    //     let css = s.horizontalImg
    //     if (layout === 'vertical') s.verticalImg
    //     element.classList.toggle(css, entries[0].isIntersecting)
    //   })
    // })
    if (containerRef.current) {
      // 图片动画 observer
      // observer.observe(containerRef.current)
      // 滑动 focus 效果监听
      containerRef.current.addEventListener('wheel', handler)
    }
    return () => removeEventListener('wheel', handler)
  }, [])

  useEffect(() => {
    if (data?.hide_ani) return
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.create({
      trigger: containerRef.current,
      start: 'top bottom',
      end: 'bottom top',
      // markers: true,
      onEnter: () => setAnimate(true),
      onLeave: () => setAnimate(false),
      onEnterBack: () => setAnimate(true),
      onLeaveBack: () => setAnimate(false),
      // id: 'banner',
      // onUpdate: (self) => handler(self),
    })
  }, [])

  return (
    <div
      ref={containerRef}
      className={
        layout === 'vertical' ? s.verticalContainer : s.horizontalContainer
      }
    >
      {items?.map((item, index) => {
        let radio = item.left_to_right_radio?.split(':')
        if (radio?.length != 2) {
          radio = ['7', '5']
        }
        return (
          <div
            key={`tile-${index}`}
            ref={(el) => refCbk(el, itemsRef, index)}
            className={cn('relative', {
              [s.horizontalItem]: layout === 'horizontal',
              [s.horizontalItem_row_reverse]: data?.row_reverse,
              [s.verticalItem]: layout === 'vertical',
              ['mb-[2rem] md:mb-0']: index !== items.length - 1,
              [s.active]: index === active,
              ['pb-[4rem]']: data.items.length > 1,
              ['flex-row-reverse']: item.imgOnRight,
              ['!opacity-100']: data?.hide_ani,
            })}
          >
            <NormalTexts
              data={item}
              divide={data?.hide_divide ? false : true}
              align="center"
              className="only-in-mobile pb-6"
            />
            {item?.imgUrl && (
              <Picture
                className={cn(
                  'w-full',
                  layout === 'vertical' ? s.verticalImg : s.horizontalImg
                )}
                imgClassName={cn({ [s.animation]: animate })}
                style={{
                  flexBasis:
                    eval(`${radio[0]}*100/(${radio[0]}+${radio[1]})`) + '%',
                }}
                source={
                  item.mobileImgUrl
                    ? `${item.imgUrl}, ${item.mobileImgUrl} 769`
                    : item.imgUrl
                }
              />
            )}
            <div
              className={cn('flex flex-col justify-between', {
                [s.horizontalTextContainer]: layout === 'horizontal',
                ['pl-12']: !item.imgOnRight && layout === 'horizontal',
                ['pr-12']: item.imgOnRight && layout === 'horizontal',
              })}
            >
              <NormalTexts
                style={{
                  flexBasis:
                    eval(`${radio[1]}*100/(${radio[0]}+${radio[1]})`) + '%',
                }}
                className={cn('only-in-pc mx-0 flex-1')}
                data={item}
                divide={data?.hide_divide ? false : true}
                justify="even"
                align="left"
              />
              <p
                className="only-in-pc mb-2"
                dangerouslySetInnerHTML={{ __html: item.note }}
              ></p>
            </div>
            <p
              className="only-in-mobile pt-2 text-center"
              dangerouslySetInnerHTML={{ __html: item.note }}
            ></p>
          </div>
        )
      })}
    </div>
  )
}

export default StaggeredTiles
