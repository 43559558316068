/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import s from './Texts.module.css'

// 为多行文本服务，有数据结构依赖, 具体每行的文本样式写在 CMS
// 例如 texts: ['<span class='text-5xl-2xl'>hi</span>',<span class='text-3xl-base'>hi</span>]
// 5xl 为 pc 字体大小，2xl 为移动端字体大小
// 可添加常用样式在./Texts.module.css 中，不常用样式请使用行内样式写在 CMS
// 无障碍考虑，避免使用标签区分样式
const Texts = ({
  id = '',
  texts,
  align = 'center',
  color = null,
  className = '',
  responsive = [],
  ...props
}) => {
  if (!texts) return null
  // https://nextjs.org/docs/messages/react-hydration-error
  const cssId = id ? id : uuidv4().slice(0, 5)

  let myTexts = texts
  let myAlign = align
  let myColor = color
  let myResponsive = responsive
  // 支持 texts: {texts: [], align: left} 结构, 同时对此结构数据中设置的参数进行设置
  if (Array.isArray(myTexts.texts)) {
    myAlign = myTexts.align || align || 'center'
    myColor = myTexts.color || color
    myResponsive = myTexts.responsive || responsive
    myTexts = myTexts.texts || []
  }
  const textsHtml = myTexts.reduce((pre, cur) => {
    if (!cur?.startsWith('<')) {
      return pre + `<span>${cur}</span>`
    }
    return pre + cur
  }, '')

  const alignCss = {
    left: 'align-items: start;',
    center: `align-items: center;
             text-align: center;`,
    right: 'align-items: end;',
  }

  return (
    <>
      {/* https://reactjs.org/docs/react-dom.html#hydrate */}
      <style suppressHydrationWarning={true}>
        {`
        .texts-${cssId} {
          ${myColor ? `color: ${myColor};` : ''}
        }
        ${
          myResponsive
            ?.map((point) => {
              const { align, color } = point.settings || {}
              return `
                @media (max-width: ${point.breakpoint}px) {
                  .texts-${cssId} {
                    ${alignCss[align] || ''}
                    ${color ? `color: ${color};` : ''}
                  }
                `
            })
            .join(' ') || ''
        }`
          .replace(/\s+/g, ' ')
          .replace(/\n/g, ' ')}
      </style>
      <div
        suppressHydrationWarning={true}
        className={cn(className, s.text, `texts-${cssId}`, {
          ['items-start']: myAlign === 'left',
          ['items-center text-center']: myAlign === 'center',
          ['items-end']: myAlign === 'right',
        })}
        {...props}
        dangerouslySetInnerHTML={{ __html: textsHtml }}
      ></div>
    </>
  )
}

export default Texts
