import cn from 'classnames'
import { useState } from 'react'
import TextsLayout from '../ui/TextsLayout/TextsLayout'
import GanPrimePlay from '@components/icons/common/GanPrimePlay'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Picture from '@components/ui/Picture'
import NormalTexts from '@components/common/NormalTexts/NormalTexts'
import { Text } from '@components/ui'
import s from './Banner.module.css'
import jump from 'jump.js'

export const positionMap = {
  leftTop: {
    gridStyle: {
      rowStart: 2,
      colStart: 1,
      colEnd: 13,
    },
    align: 'left',
    justify: 'top',
  },
  centerTop: {
    gridStyle: {
      rowStart: 2,
      colStart: 1,
      colEnd: 13,
    },
    align: 'center',
    justify: 'top',
  },
  rightTop: {
    gridStyle: {
      rowStart: 2,
      colStart: 1,
      colEnd: 13,
    },
    align: 'right',
    justify: 'top',
  },
  leftCenter: {
    gridStyle: {
      rowStart: 1,
      rowEnd: 13,
      colStart: 1,
      colEnd: 13,
    },
    align: 'left',
    justify: 'center',
  },
  centerCenter: {
    gridStyle: {
      rowStart: 1,
      rowEnd: 13,
      colStart: 1,
      colEnd: 13,
    },
    align: 'center',
    justify: 'center',
  },
  rightCenter: {
    gridStyle: {
      rowStart: 1,
      rowEnd: 13,
      colStart: 1,
      colEnd: 13,
    },
    align: 'right',
    justify: 'center',
  },
  leftBottom: {
    gridStyle: {
      rowStart: 11,
      colStart: 1,
      colEnd: 13,
    },
    align: 'left',
    justify: 'bottom',
  },
  centerBottom: {
    gridStyle: {
      rowStart: 11,
      colStart: 1,
      colEnd: 13,
    },
    align: 'center',
    justify: 'bottom',
  },
  rightBottom: {
    gridStyle: {
      rowStart: 11,
      colStart: 1,
      colEnd: 13,
    },
    align: 'right',
    justify: 'bottom',
  },
}

// 需要依赖特定数据结构 data
const ListingBanner = ({
  data,
  className = null,
  trace = { jump: () => {} },
}) => {
  const [videoOpen, setVideoOpen] = useState(null)

  if (!data) return null
  const { align, justify, gridStyle } =
    positionMap[data.textPosition.trim() || 'centerTop'] ||
    positionMap.centerTop
  const onLinkClick = (e) => {
    trace.jump()
    if (data.to.startsWith('#')) {
      e.preventDefault()
      jump(data.to, { duration: 500 })
    }
  }
  return (
    <>
      <div
        className={cn('relative flex flex-col', className)}
        style={data?.textColor ? { color: data.textColor } : {}}
      >
        <Picture
          source={`${data.bgUrl}, ${data.mobileBgUrl} 769`}
          alt={data.alt || data.title}
          className="w-full"
        />
        <TextsLayout
          gridStyle={gridStyle}
          align={align}
          justify={justify}
          responsive={[
            {
              breakpoint: 769,
              settings: {
                gridStyle: {
                  rowStart: 2,
                },
              },
            },
          ]}
        >
          <NormalTexts
            data={data}
            align={align}
            justify={justify}
            onLinkClick={onLinkClick}
          ></NormalTexts>
        </TextsLayout>
        {/* 底部的 note */}
        {data.note && (
          <TextsLayout align={data.notePosition || align}>
            <Text
              className="flex h-full items-end justify-center pb-4 text-center text-[18px] md:text-sm"
              html={data.note}
              variant="paragraph"
            ></Text>
          </TextsLayout>
        )}
        {data?.video && (
          <button
            className={cn(s.videoPlay, '!z-[5]')}
            onClick={() => setVideoOpen(data.video)}
          >
            <GanPrimePlay className={s.playIcon}></GanPrimePlay>
          </button>
        )}
        {videoOpen && (
          <div className={cn(s.dialogVide)}>
            <button className={s.closeWrap} onClick={() => setVideoOpen(null)}>
              <XMarkIcon className={s.close} />
            </button>
            <div className={s.box}>
              <iframe
                width="100%"
                height="100%"
                src={videoOpen}
                title={data?.videoTitle || 'YouTube video player'}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ListingBanner
