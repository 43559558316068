import GreenerFeatures from './GreenerFeatures'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash.throttle'
import { Text } from '@components/ui'

function GreenerFuture({ data }) {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', throttle(resizeWindow, 50))
    return () => window.removeEventListener('resize', resizeWindow)
  }, [])

  const resizeWindow = () => {
    const offsetWidth = document.querySelector('body').offsetWidth
    if (offsetWidth < 768) {
      return setIsMobile(true)
    }
    setIsMobile(false)
  }
  const greenerFutureTitle = useRef(null)
  const greenerFutureRef = useRef(null)
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to(greenerFutureTitle.current, {
      scrollTrigger: {
        trigger: greenerFutureTitle.current,
        start: 'top bottom',
        end: 'bottom 80%',
        scrub: 0.6,
        // markers: true,
      },
      marginTop: 0,
      opacity: 1,
      duration: 3,
      ease: 'linear',
    })
    // gsap.registerPlugin(ScrollTrigger)
    // gsap.to(greenerFutureRef.current, {
    //   scrollTrigger: {
    //     trigger: greenerFutureRef.current,
    //     start: 'bottom bottom',
    //     end: '+=0',
    //     scrub: 0.6,
    //     // markers: true,
    //   },
    //   opacity: 0,
    //   ease: 'linear',
    // })
  }, [])

  return (
    <>
      <Text
        html={data.title || data.title_pc}
        className="text-6xl font-bold md:text-3xl text-center mb-[46px]  md:mb-[-80px] md:px-4"
        variant="sectionHeading"
      />
      <GreenerFeatures data={data} isMobile={isMobile} />
    </>
  )
}

export default GreenerFuture
