import { useEffect, useState, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Container, useUI, LoadingDots, Picture } from '@components/ui'
import { ProductCard } from '@components/product'
import getProductCouponsByShop from '@lib/apis/get-product-coupons-by-shop'

import s from './AutoDiscountProducts.module.css'

const AutoDiscountProducts = ({ data, metafields }) => {
  const { filter_type, sort_key, saleBg } = data
  const { discounts, autoDiscountProducts, shopCommon, preRender } = metafields
  const { locale } = useRouter()
  const { recaptchaReady } = useUI()
  const [products, setProducts] = useState()
  const [loading, setLoading] = useState(true)
  const initProductsDiscount = useCallback(async () => {
    const result = await getProductCouponsByShop({ locale, sort_key })
    setProducts(
      result?.filter((product) => !/[_|-]boa\d?$/.test(product?.handle))
    ) // 过滤掉 boa 类页面 接口暂时无法返回 metafields 的 noindex 所以暂时先用正则处理
    setLoading(false)
  }, [locale, sort_key])
  // console.log('result:', products)

  useEffect(() => {
    setLoading(true)
    initProductsDiscount()
  }, [initProductsDiscount, preRender?.hideCoupon])

  const confirmCoupons = (coupon) => {
    if (coupon && coupon.value_type === 'fixed_amount') {
      return `${discounts?.symbol || ''} ${coupon.value_style} ${
        discounts?.off
      }`
    }
    if (coupon && coupon.value_type === 'percentage') {
      return `${Math.abs(parseInt(coupon.value))}% ${discounts?.off}`
    }
    return false
  }

  return (
    <div className={s.container}>
      <div className={s.bannerBox}>
        {data?.pc_banner && (
          <Picture
            source={`${data?.pc_banner}, ${data?.mobile_banner} 769`}
            alt={data?.banner_alt || 'banner'}
            className={s.banner}
            loading={data?.loading || 'eager'}
          />
        )}
      </div>
      {loading ? (
        <div className="flex h-80 w-full items-center justify-center p-3 text-center">
          <LoadingDots />
        </div>
      ) : (
        <div className={s.productsBox}>
          {products?.map((product, i) => {
            product.price = {
              currencyCode: product.currency,
            }
            product.variants = [
              {
                handle: product.handle,
                name: product.product_title,
                price: product.variant_price,
                availableForSale: true,
                id: product.variant_shopify_id,
                sku: product.sku,
              },
            ]
            return (
              <ProductCard
                index={i}
                model="custom_api"
                key={product.sku + i}
                className="animated fadeIn"
                product={product}
                sales={confirmCoupons(product)}
                text={{
                  soldOut: shopCommon?.soldOut,
                  saleBg: saleBg || discounts?.Mark_img,
                }}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
export default AutoDiscountProducts
