import { Picture, Text } from '@components/ui'
import SectionTitle from './SectionTitle'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import { Arrow } from './Arrow'
import { useEffect, useState } from 'react'
import BannerLink from '@components/common/BannerLink'
import { useRouter } from 'next/router'
import s from './index.module.css'
import { appendQueryParams } from 'templates/Pages/HomePps'
import { useArticle } from 'lib/shopify/api/blog/hooks/use-article'
import { handleHref } from 'templates/Pages/Nano/utilities'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Card = ({ jItem, data, locale }) => {
  const {
    isLoading,
    data: article,
    isEmpty,
  } = useArticle(jItem?.blog, jItem?.slug)
  const { setRelativeLink } = useRelativeLink()

  const learnMoreHref =
    jItem?.blog && jItem?.slug
      ? handleHref(locale, `blogs/${jItem?.blog}/${jItem?.slug}`)
      : setRelativeLink({ link: jItem?.learnMoreHref })

  const [h, setH] = useState(0)

  // start_ai_generated
  function updateH() {
    try {
      setH(document.querySelector('.card-picture-content')?.offsetHeight)
    } catch (error) {}
  }

  // end_ai_generated
  useEffect(() => {
    setTimeout(() => {
      updateH()
    }, 200)
    window.addEventListener('resize', updateH)
    return () => {
      window.removeEventListener('resize', updateH)
    }
  }, [])

  return (
    <div
      className={cn(
        'aspect-h-[497] aspect-w-[482] relative w-full cursor-pointer overflow-hidden rounded-[10px]',
        s?.card_content
      )}
    >
      <div className="flex h-full w-full flex-col">
        <div
          className={cn(
            'card-picture-content relative h-[70%] flex-shrink-0 overflow-hidden',
            s?.card_picture_content
          )}
        >
          {(article?.image?.url || jItem?.img) && (
            <Picture
              style={{ height: `${h}px` }}
              source={`${article?.image?.url || jItem?.img}, ${
                article?.image?.url || jItem?.mbImg || jItem?.img
              } 768`}
              className={cn('w-full', s?.card_picture_2)}
              imgClassName="w-full h-full object-cover"
            ></Picture>
          )}

          <div
            className={cn(
              'absolute left-0 top-0 z-[2] hidden h-full w-full bg-black/20',
              s?.card_mask
            )}
          ></div>
        </div>
        <div className="flex-1 bg-[#FBFBFD]">
          <div className="box-border flex h-full flex-col px-[32px] py-[32px] l:px-[16px] l:py-[14px] l-xl:px-[24px] l-xl:py-[24px]">
            {(article?.seo?.title || article?.title || jItem?.title) && (
              <Text
                className="line-clamp-2 text-[24px] font-semibold	 leading-[28px] text-[#000] l:text-[16px] l:leading-[22px] l-xl:text-[20px] l-xl:leading-[24px]"
                html={article?.seo?.title || article?.title || jItem?.title}
              ></Text>
            )}
            {(article?.publishedAt || jItem?.subtitle) && (
              <Text
                className="mt-[20px] text-[16px] leading-[22px] text-[#6E6E73] md:mt-[10px] xl:text-[12px] md-xl:mt-[16px]"
                html={article?.publishedAt || jItem?.subtitle}
              ></Text>
            )}
            {(article?.seo?.description || article?.content || jItem?.desc) && (
              <Text
                className={cn(
                  'mt-[20px] line-clamp-4 text-[16px] leading-[22px] text-[#6E6E73] opacity-0 md:line-clamp-2 xl:mt-[16px] xl:text-[12px]',
                  s?.card_desc
                )}
                html={
                  article?.seo?.description || article?.content || jItem?.desc
                }
              ></Text>
            )}
          </div>
        </div>
      </div>
      <BannerLink
        to={appendQueryParams(learnMoreHref, 'ref=homePageBanner_pps')}
      ></BannerLink>
    </div>
  )
}

function Blogs({ data, pageHandle, id }) {
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  const { locale } = useRouter()
  const { setRelativeLink } = useRelativeLink()

  return (
    <>
      <div className="layer overflow-x-hidden bg-[#fff] pt-[120px] md:pr-0 md:pt-[72px]">
        <div className="content md:overflow-hidden" id={id}>
          <SectionTitle txt={data?.title}></SectionTitle>
          {data?.learnMoreHref && (
            <div className="mt-[12px] flex w-full items-center justify-center md:mt-[8px] md:justify-start">
              <a
                className="text-[20px] font-medium text-[#06A9E0] hover:underline l:text-[16px]"
                dangerouslySetInnerHTML={{
                  __html: data?.learnMoreTxt,
                }}
                href={setRelativeLink({ link: data?.learnMoreHref })}
              ></a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10.5 8L14.5 12L10.5 16"
                  stroke="#06A9E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          )}
          {data?.list && (
            <div className="mt-[48px] w-screen md:mt-[32px]">
              <div className={cn(s.custom_max_w, 'relative')}>
                <Swiper
                  className="!overflow-visible"
                  navigation={{
                    nextEl: '.blogs-custom-swiper-button-next',
                    prevEl: '.blogs-custom-swiper-button-prev',
                  }}
                  modules={[Navigation]}
                  onProgress={(swiper) => {
                    // start_ai_generated
                    setStart(swiper.isBeginning)
                    setEnd(swiper.isEnd)
                    // end_ai_generated
                  }}
                  breakpoints={{
                    0: {
                      spaceBetween: '10px',
                      slidesPerView: 1.1,
                    },
                    768: {
                      spaceBetween: '24px',
                      slidesPerView: 1.8,
                    },
                    1024: {
                      spaceBetween: '24px',
                      slidesPerView: 2,
                    },

                    1440: {
                      spaceBetween: '24px',
                      slidesPerView: 2.6,
                    },
                  }}
                >
                  {/* start_ai_generated */}
                  {data?.list?.map((jItem, jIndex) => {
                    return (
                      <SwiperSlide key={jIndex + jItem?.title}>
                        <Card
                          locale={locale}
                          jItem={jItem}
                          pageHandle={pageHandle}
                          data={data}
                        ></Card>
                      </SwiperSlide>
                    )
                  })}
                  {/* end_ai_generated */}
                </Swiper>
                {/* start_ai_generated */}
                <Arrow
                  isStart={isStart}
                  isEnd={isEnd}
                  prevClass="blogs-custom-swiper-button-prev"
                  nextClass="blogs-custom-swiper-button-next"
                ></Arrow>
                {/* end_ai_generated */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Blogs
