import s from '../../A17c1.module.css'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import { Picture, Text } from '@components/ui'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { gsap } from 'gsap/dist/gsap'
import { useCountUp } from 'react-countup'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { appendRefParameter } from '@lib/utils/tools'

const BaresAni = ({ data, dataKey, pageHandle }) => {
  const canvasRef = useRef()
  const barRef0 = useRef()
  const barRef1 = useRef()
  const { setRelativeLink } = useRelativeLink()

  const MotionDiv = ({ num, updateNum, delay, decimals, decimal, format }) => {
    const countUpRef = useRef(null)
    const { update } = useCountUp({
      ref: countUpRef,
      start: num,
      end: updateNum,
      delay: delay,
      duration: 0.8,
      decimals,
      decimal,
      useGrouping: false,
      separator: format,
      useEasing: true,
    })

    useEffect(() => {
      const width = document?.querySelector('body')?.offsetWidth
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.create({
        trigger: `#${dataKey} .BaresAni`,
        start: width > 768 ? 'top top+=20%' : 'top center',
        onEnter: () => {
          update(updateNum)
          if (barRef0?.current?.style) barRef0.current.style.width = '90%'
          if (barRef1?.current?.style) barRef1.current.style.width = '90%'
        },
      })
      ScrollTrigger.create({
        trigger: `#${dataKey} .BaresAni`,
        start: 'top bottom+=50%',
        onEnter: () => {
          update(num)
          if (barRef0?.current?.style) barRef0.current.style.width = '5%'
          if (barRef1?.current?.style) barRef1.current.style.width = '5%'
        },
      })
    }, [])

    return (
      <span
        className={s.num}
        ref={countUpRef}
        dangerouslySetInnerHTML={{
          __html: num,
        }}
        style={{
          color: 'transparent',
          backgroundImage: 'var(--solix-linear-color)',
          '-webkit-background-clip': 'text',
        }}
      />
    )
  }
  const AniInit = () => {
    const width = document?.querySelector('body')?.offsetWidth
    const height = window.screen.height
    const prodsCanvas = canvasRef.current
    const prodsContext = prodsCanvas.getContext('2d')
    prodsCanvas.width = width > 1024 ? 2560 : 780
    prodsCanvas.height = width > 1024 ? 1440 : 1440
    const currentProds = data?.imgAniList
    const mob_currentProds = data?.mob_imgAniList || currentProds
    const prodsCount =
      width > 1024 ? currentProds?.length : mob_currentProds?.length
    const prodsImages = []
    const prod = { count: 0 }
    for (let i = 0; i < prodsCount; i++) {
      const img = new Image()
      img.src = width > 1024 ? currentProds[i] : mob_currentProds[i]
      prodsImages.push(img)
    }
    function prodsRender() {
      prodsContext?.clearRect(0, 0, prodsCanvas.width, prodsCanvas.height)
      prodsContext?.drawImage(prodsImages[prod.count], 0, 0)
    }
    if (prodsContext && prodsImages[0]) prodsImages[0].onload = prodsRender

    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#${dataKey} .BaresAni`,
          start: width > 768 ? 'top top' : 'top top+=92px',
          end: `+=${3 * height}`,
          pin: true,
          scrub: 0,
          // markers: true,
        },
      })
      .to(`#${dataKey} .screen1`, {
        duration: '0.2',
      })
      .to(`#${dataKey} .screen1`, {
        transform: 'translateY(-100%)',
        duration: '1',
      })
      .to(prod, {
        count: prodsCount - 1,
        snap: 'count',
        onUpdate: prodsRender,
        duration: '2',
      })
      .to(
        `#${dataKey} .BaresAniTxt1`,
        {
          opacity: 0,
          duration: '0.1',
        },
        '<'
      )
      .to(
        `#${dataKey} .BaresAniTxt2`,
        {
          opacity: 1,
          duration: '0.1',
        },
        '<1.6'
      )
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    data?.imgAniList && AniInit()
  }, [])

  return (
    <div className="BaresAni relative text-center leading-[1.2]">
      <div
        className={cn(
          'screen1 layer z-[1] w-full gap-y-0 bg-[#fff]',
          data?.imgAniList
            ? 'absolute left-0 top-0 min-h-[100vh] md:min-h-[calc(100vh-92px)]'
            : 'relative',
          s.paddingTop,
          s.paddingBottom
        )}
      >
        <div className="content flex h-full flex-col justify-center">
          <Text
            html={data?.title}
            className="text-left text-[64px] font-[700] text-[#000] md:text-[30px]"
          />
          {data?.desc && (
            <Text
              html={data?.desc}
              className="mx-auto mt-[24px] text-[20px] font-[500] text-[#777] md:mt-[16px] md:max-w-[300px] md:text-[12px]"
            />
          )}
          <div className="mt-[100px] flex items-end justify-between gap-[10%] md:flex-wrap md:gap-[10px] xxl:mt-[50px]">
            {data?.textListing?.map((item, index) => (
              <div
                className={cn(
                  'relative',
                  index === 1 ? 'mx-auto md:mb-[10%] md:w-[70%]' : 'md:w-[45%]',
                  index === 0 && 'md:order-1',
                  index === 2 && 'md:order-2'
                )}
                key={index}
              >
                {item?.countUp ? (
                  <div className="relative z-[1] flex items-baseline justify-center gap-[10px] text-[140px] font-[700] md:gap-[3px] xl:text-[88px]">
                    {item?.countUp?.preUnit && (
                      <Text
                        html={item?.countUp?.preUnit}
                        className="whitespace-nowrap text-[140px] text-[#2C7ED0] xl:text-[64px]"
                      />
                    )}
                    <MotionDiv
                      num={item?.countUp?.startNum || 0}
                      updateNum={item?.countUp?.endNum || 100}
                      delay={4000}
                      decimals={item?.countUp?.decimals || 0}
                      decimal={item?.countUp?.decimal || '.'}
                      format={item?.countUp?.format || false}
                    />
                    {item?.countUp?.unit && (
                      <Text
                        html={item?.countUp?.unit}
                        className="text-[104px] text-[#00DB84] xl:text-[64px]"
                      />
                    )}
                  </div>
                ) : (
                  <div className="relative z-[1] flex items-baseline justify-center gap-[10px] font-[700] text-[#000]">
                    <Text
                      html={item?.num}
                      className="text-[80px] xl:text-[48px]"
                    />
                    <Text
                      html={item?.numUnit}
                      className="text-[56px] xl:text-[18px]"
                    />
                  </div>
                )}
                <Text
                  html={item?.desc}
                  className={cn(
                    'text-[20px] font-[500] text-[#878787] xl:text-[12px]',
                    item?.countUp && 'mx-auto md:w-[70%]'
                  )}
                />
                {item?.bg && (
                  <div className="absolute left-1/2 top-[35%] w-[120%] -translate-x-1/2 -translate-y-1/2 md:top-[25%]">
                    <Picture source={item?.bg} className="w-full" />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="mt-[100px] flex flex-col justify-center text-left md:gap-[20px] xxl:mt-[50px]">
            {data?.compareBar?.map((item, index) => (
              <div
                key={index}
                className="flex items-center gap-[20px] md:flex-col md:items-start md:gap-[5px]"
              >
                <Text
                  html={item?.txt}
                  className={cn(
                    'w-[15%] text-[20px] font-[500] md:w-full xl:text-[12px]',
                    index === 0
                      ? 'pt-[10px] text-[#000] md:p-0'
                      : 'text-[#A2A2A2]'
                  )}
                />
                <div className="flex w-full flex-1 items-center justify-start gap-[10px]">
                  <div
                    ref={index === 0 ? barRef0 : barRef1}
                    className={cn(
                      'w-[5%] border-l-[2px] border-[#A2A2A2] transition-all duration-[0.8s] md:py-[3px]',
                      index === 0
                        ? 'pb-[10px] pt-[20px]'
                        : 'pb-[20px] pt-[10px]'
                    )}
                  >
                    <div
                      className={cn(
                        'h-[42px] border-[#A2A2A2] md:h-[18px]',
                        index === 0 ? 'border-0' : 'border-[2px]'
                      )}
                    >
                      <div
                        className="h-full w-full"
                        style={{
                          background:
                            index === 0
                              ? 'var(--solix-linear-color)'
                              : '#A2A2A2',
                          width: `${item?.bar}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <Text
                    html={item?.price}
                    className={cn(
                      'text-[36px] font-[600] xl:text-[18px]',
                      index === 0
                        ? 'pt-[10px] text-[#00DB84] md:pt-[5px]'
                        : 'text-[#A2A2A2]'
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
          {data?.tips?.text && (
            <a
              href={appendRefParameter({
                url: setRelativeLink({
                  link: data?.tips?.href,
                }),
                refValue:
                  data?.resourcesRef || data?.urlRef || pageHandle || '',
              })}
              className="text-center mt-5 md:mt-3 text-[#878787] text-[16px] font-[500] leading-[1.1] l:text-[12px] l:leading-[1.1] hover:text-[var(--brand-color)] underline"
              dangerouslySetInnerHTML={{
                __html: data?.tips?.text,
              }}
              target={data?.target || '_blank'}
            ></a>
          )}
        </div>
        {data?.extraInfo && (
          <div className="content">
            <div className="mt-[calc(96/1920*100vw)] l:mt-[98px] min-xxl:mt-[96px]">
              {data?.extraInfo?.title && (
                <Text
                  html={data?.extraInfo?.title}
                  className="text-center text-[32px] font-[700] leading-[1.12] text-[#000] md:text-left l:text-[24px] l:leading-[1.16]"
                />
              )}
              {data?.extraInfo?.desc && (
                <div className="mt-[calc(40/1920*100vw)] l:mt-6 min-xxl:mt-10">
                  {data?.extraInfo?.desc && (
                    <Text
                      html={data?.extraInfo?.desc}
                      className="text-left text-[20px] font-[500] leading-[1.2] text-[#6E6E73] l:text-[14px] l:leading-[1.28] "
                    />
                  )}
                  {data?.extraInfo?.descList && (
                    <ul className="list-decimal pl-[29px] l:pl-4">
                      {data?.extraInfo?.descList?.map((item) => (
                        <Text
                          variant="listItem"
                          key={item}
                          html={item}
                          className="text-left text-[20px] font-[500] leading-[1.2] text-[#6E6E73] l:text-[14px] l:leading-[1.28] "
                        />
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {data?.imgAniList && (
        <div className="relative h-[100vh] w-[100vw] bg-[#000] text-center leading-[1.2] md:h-[calc(100vh-92px)]">
          <canvas
            className="h-full w-full object-cover"
            ref={canvasRef}
          ></canvas>
          <Text
            html={data?.txt1}
            className="BaresAniTxt1 absolute left-1/2 top-1/2 -translate-x-1/2 text-[64px] font-[700] text-[#fff] md:text-[30px]"
          />
          <div className="BaresAniTxt2 layer absolute left-1/2 top-[8%] -translate-x-1/2 opacity-0">
            <div className="content xxl:!max-w-[800px]">
              <Text
                html={data?.txt2_tit}
                className="text-[198px] font-[800] leading-[1] md:text-[88px] xl:!text-[88px] xxl:text-[130px]"
                style={{
                  background:
                    'linear-gradient(180deg, #314761 12.85%, #202C3F 37.2%, #0C111A 70.83%)',
                  backgroundClip: 'text',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }}
              />
              <Text
                html={data?.txt2_num}
                className="mt-[-100px] inline-block text-[128px] font-[800] leading-[1] xl:!text-[64px] xxl:text-[100px]"
                style={{
                  color: 'transparent',
                  backgroundImage: 'var(--solix-linear-color)',
                  '-webkit-background-clip': 'text',
                }}
              />
              <Text
                html={data?.txt2_desc}
                className="text-[24px] font-[500] text-[#777] xl:!text-[12px] xxl:text-[18px]"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BaresAni
