import { Picture } from '@components/ui'
import s from './BrandInfo.module.css'
const BrandInfo = ({ data }) => {
  if (!data) return null
  return (
    <section className={s.chargingWrap}>
      <div dangerouslySetInnerHTML={{ __html: data.title }} />
      {data?.list && (
        <ul className={s.col}>
          {data?.list.map((item, index) => {
            return (
              <li key={index}>
                {/* <Picture
                  quality={2}
                  className="h-[100px]"
                  source={`${item.img}, ${item.mobImg} 769`}
                /> */}
                <img src={item.img} className="m-auto h-[100px] md:hidden" />
                <img
                  src={item.mobImg}
                  className="m-auto hidden h-[100px] md:block"
                />
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                <div dangerouslySetInnerHTML={{ __html: item.subtitle }} />
              </li>
            )
          })}
        </ul>
      )}

      {data?.introduce && (
        <ul className="text-center text-[#323232]">
          {data?.introduce.map((item, index) => {
            return (
              <li
                key={index}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            )
          })}
        </ul>
      )}
    </section>
  )
}

export default BrandInfo
