import { pageGTMEvent } from '@lib/utils/thirdparty'

export const pictureClick = ({ sku, name, price, index, listingSku }) => {
  pageGTMEvent({ event_parameters: null })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'select_item',
    event_parameters: {
      page_group: 'listing_bottom_' + listingSku, //取listing SKU,注意不是被推荐产品的SKU
      action: 'img',
      item_list_name: 'listing_bottom_Bundle',
      items: [
        {
          item_id: sku, //读取点击跳转的SKU,即被推荐产品SKU
          item_name: name, //产品名
          item_variant: '',
          price: price, //读取price,无价格则空
          index: index, //读取被推荐的位置，如1,2,3,4,5
        },
      ],
    },
  })
}

export const btnClick = ({ sku, name, price, index, listingSku }) => {
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'bundle',
    event_parameters: {
      page_group: 'listing_bottom_' + listingSku, //取listingSKU
      position: 'listing_bottom_Bundle_title',
      SKU: sku, //读取点击跳转的SKU
      SKU_name: name, //产品名
      SUM: price, //读取price
      SKU_variant: '',
      SKU_index: index, //bundle顺序，如1、2、……
    },
  })
}

export const titleClick = ({ sku, name, price, index, listingSku }) => {
  pageGTMEvent({ event_parameters: null })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'select_item',
    event_parameters: {
      page_group: 'listing_bottom_' + listingSku, //取listingSKU
      action: 'title',
      item_list_name: 'listing_bottom_Bundle',
      items: [
        {
          item_id: sku, //读取点击跳转的SKU,即被推荐产品SKU
          item_name: name, //产品名
          item_variant: '',
          price: price, //读取price,无价格则空
          index: index, //读取被推荐的位置，如1,2,3,4,5
        },
      ],
    },
  })
}

export const almostReach = () => {
  pageGTMEvent({ event: 'detail_rec' })
}

export const viewItems = ({ items, listingSku }) => {
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'view_item_list',
    event_parameters: {
      page_group: 'listing_bottom_' + listingSku, //取listingSKU
      item_list_name: 'listing_bottom_Bundle',
      items,
    },
  })
}

export const linkRef = (handle) => {
  return handle ? handle + '-listing_bottom_Bundle' : 'listing_bottom_Bundle'
}
