import cn from 'classnames'
import { useState, useEffect, useCallback, useRef } from 'react'
import { Picture, Text, Button, useUI } from '@components/ui'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { gsap } from 'gsap/dist/gsap'

import s from './index.module.css'

const Index = ({ id, data, locale }) => {
  const canvasStaytunedRef = useRef()

  const VideoInit = useCallback(() => {
    const width = window.screen.width
    const height = window.screen.height
    const prodsCanvas = canvasStaytunedRef.current
    const prodsContext = prodsCanvas.getContext('2d')
    prodsCanvas.width = width > 769 ? 1920 : 1920
    prodsCanvas.height = width > 769 ? 1200 : 1200
    const currentProds = data?.imgAniList
    const mob_currentProds = data?.mob_imgAniList || currentProds
    const prodsCount =
      width > 769 ? currentProds?.length : mob_currentProds?.length
    const prodsImages = []
    const prod = { count: 0 }
    for (let i = 0; i < prodsCount; i++) {
      const img = new Image()
      img.src = width > 769 ? currentProds[i] : mob_currentProds[i]
      prodsImages.push(img)
    }
    return { prodsContext, prodsCount, prodsImages, prodsCanvas, prod }
  }, [data])

  const AniInit = useCallback(() => {
    const width = window.screen.width
    const height = window.screen.height
    const { prodsContext, prodsCount, prodsImages, prodsCanvas, prod } =
      VideoInit()

    function prodsRender() {
      prodsContext?.clearRect(0, 0, prodsCanvas.width, prodsCanvas.height)
      prodsContext?.drawImage(prodsImages[prod.count], 0, 0)
    }

    if (prodsContext && prodsImages[0]) prodsImages[0].onload = prodsRender

    let ani = gsap.timeline({
      scrollTrigger: {
        trigger: '.StaytunedCanvasBox',
        start: 'top center',
        end: `bottom bottom`,
        // pin: true,
        // markers: true,
        scrub: 1,
      },
    })
    ani.to(`.staytunedTitle`, {
      opacity: 1,
      duration: '0.8',
    })
    ani.to(
      prod,
      {
        count: prodsCount - 1,
        snap: 'count',
        onUpdate: prodsRender,
        duration: '2',
      },
      '<'
    )
  }, [])

  useEffect(() => {
    // gsap.registerPlugin(ScrollTrigger)
    // AniInit()
    // const link = 'https://cdn.shopify.com/s/files/1/0493/7636/2660/files/PC-a'
    // const arr = []
    // for (let index = 0; index < 32; index++) {
    //   arr.push(`${link}${index > 9 ? index : `0${index}`}.jpg`)
    // }
    // console.log('arr', arr)
  }, [])

  return (
    <div className="relative py-10 md:px-6 md:py-8">
      <div className="flex justify-between gap-[15%] md:block">
        {data?.disclaimerTitle?.map((item, index) => (
          <Text
            key={item + index}
            className={cn(
              'mb-2 flex-1 text-left text-base font-medium md:text-sm',
              { pc: index === 1 }
            )}
            html={item}
          />
        ))}
      </div>
      <div className="flex justify-between gap-[15%] md:block">
        {data?.disclaimer?.map((item, index) => (
          <>
            {index === 1 && (
              <Text
                key={item + index + 'title'}
                className={cn(
                  'mobile mb-2 mt-6 flex-1 text-left text-base font-medium md:text-sm'
                )}
                html={data?.disclaimerTitle?.[index]}
              />
            )}
            <Text
              key={item + index}
              className="flex-1 text-left text-sm md:text-xs"
              html={item}
            />
          </>
        ))}
      </div>
    </div>
  )
}

export default Index
