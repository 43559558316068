/* eslint-disable react-hooks/rules-of-hooks */
import { Picture, Text } from '@components/ui'
import s from './Recommends.module.css'
import { useRouter } from 'next/router'
import {
  btoaID,
  getGaClientId,
  refreshStarRating,
  getRandomValueFromArray,
  addQueryParam,
} from '@lib/utils/tools'
import { useUI } from '@components/ui/context'
import { useCallback, useState, useEffect, useRef } from 'react'
import getProductPersonalizeBySkus from '@lib/apis/get-product-personalize-by-skus'
import Slider from 'react-slick'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import BannerLink from '@components/common/BannerLink'
import {
  btnClick,
  titleClick,
  linkRef,
  pictureClick,
  almostReach,
  viewItems,
} from './trace'
import { useInView } from 'react-intersection-observer'
import isMobile from 'ismobilejs'
import { StarRating } from '../Reviews'
import { parse } from 'query-string'

const Recommends = ({ data, product, metafields, pagehandle }) => {
  const { judgemeSettings } = metafields
  const productSku = product?.variants?.[0]?.sku
  const { locale } = useRouter()
  const [products, setProducts] = useState()
  const [current, setCurrent] = useState(0)
  const [mobile, setMobile] = useState(false)
  const { gtmReady } = useUI()

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '120px',
    triggerOnce: true,
  })
  const trace = useRef(false)

  // console.log('current:', current)

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setMobile(true)
    }
  }, [])

  const getInViewItems = () => {
    let items = []
    document.querySelectorAll('.slick-active')?.forEach((item) => {
      const index = parseInt(item.getAttribute('data-index') || '1')
      const product = products?.[index]
      items.push({
        item_id: product?.sku, //读取被推荐产品的SKU
        item_name: product?.product_title,
        item_variant: '',
        price: product?.variant_price, //读取被推荐产品展示的折扣价
        index: index % (mobile ? 3 : 6), //读取被推荐的位置，如1,2,3
      })
    })
    return items
  }

  // console.log('trace:', trace)
  // console.log('inView:', inView)

  if (inView && !trace.current) {
    almostReach()
    viewItems({ items: getInViewItems(), listingSku: productSku })
    trace.current = true
  }

  // console.log('current:', current)

  const fetchPersonalizeProducts = useCallback(async () => {
    let queries = parse(window.location.search)

    let plan
    if (data?.ABTest) {
      plan = getRandomValueFromArray(data?.ABTest || [])
    }

    const clientId = await getGaClientId()
    const result = await getProductPersonalizeBySkus({
      locale,
      skus: [productSku],
      clientId,
      limit: 12,
      plan: queries?.listingPlan?.toLowerCase() || '',
    })

    const productsResult = result?.data?.[productSku]
    // console.log('result:', productsResult)

    if (productsResult) {
      setProducts(productsResult)
      refreshStarRating()

      // AB 测试埋点
      if (plan) {
        // const url = addQueryParam({
        //   url: document.location.href,
        //   key: 'listingPlan',
        //   value: plan,
        //   replaceHistory: true,
        // })
        // const urlObj = new URL(url)
        // const searchParams = new URLSearchParams(urlObj.search)
        // if (searchParams.has('listingPlan') && searchParams.has('cartPlan')) {
        //   pageGTMEvent({
        //     event: 'pageview',
        //     page: url,
        //   })
        // }
      }
    }
  }, [locale, gtmReady])

  useEffect(() => {
    if (gtmReady) {
      fetchPersonalizeProducts()
    }
  }, [fetchPersonalizeProducts, gtmReady])

  const NextArrow = (props) => {
    return (
      <button
        aria-label="next Arrow"
        onClick={props.onClick}
        className={cn(
          'absolute right-[-40px] top-[50%] z-10 block w-[40px] cursor-pointer md:right-[-6vw] md:top-[35%] md:w-[30px]',
          { ['hidden']: current === (mobile ? 9 : 6) }
        )}
      >
        <ChevronRightIcon className="stroke-1" />
      </button>
    )
  }

  const PrevArrow = (props) => {
    return (
      <button
        onClick={props.onClick}
        aria-label="prev Arrow"
        className={cn(
          'absolute left-[-40px] top-[50%] z-10 block w-[40px] cursor-pointer md:left-[-6vw] md:top-[35%] md:w-[30px]',
          { ['hidden']: current === 0 }
        )}
      >
        <ChevronLeftIcon className="stroke-1" />
      </button>
    )
  }

  const settings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
    beforeChange: (p, next) => {
      setCurrent(next)
    },
    afterChange: () => {
      viewItems({ items: getInViewItems(), listingSku: productSku })
    },
  }

  if (!data || !products) return null
  return (
    <>
      <style>{`
        @media (max-width: 768px) {
          .yotpo .standalone-bottomline .star-clickable {
            flex-direction: column;
          }
        }
    `}</style>
      <Text
        variant="sectionHeading"
        className="pb-8 text-left text-4xl md:px-4 md:pb-4 md:text-center md:text-3xl md:font-semibold"
        html={data.title}
      />
      <div className="md:mx-5" ref={ref}>
        <Slider {...settings}>
          {products?.map((product, index) => {
            const link = `${locale === 'us' ? '' : `/${locale}`}/products/${
              product?.handle
            }?ref=${linkRef(pagehandle)}`
            const traceParams = {
              sku: product?.sku,
              listingSku: productSku,
              name: product?.product_title,
              price: product?.variant_price,
              index: index % (mobile ? 3 : 6),
            }
            return (
              <div key={product?.sku} className={s.product}>
                <div className="relative">
                  <Picture
                    source={product?.variant_image}
                    className="aspect-1 w-[120px] md:w-auto"
                    imgClassName="h-full object-scale-down"
                  />
                  <BannerLink
                    to={link}
                    onClick={() => pictureClick(traceParams)}
                  />
                </div>
                <div className=" mr-2 mt-4 flex flex-1 flex-col justify-between md:px-2">
                  <a
                    href={link}
                    onClick={() => titleClick(traceParams)}
                    className="line-clamp-2 min-h-[42px] md:line-clamp-3 md:min-h-[48px] md:text-xs"
                    dangerouslySetInnerHTML={{ __html: product?.product_title }}
                  />
                  <StarRating
                    productId={btoaID(product.product_shopify_id)}
                    type={judgemeSettings?.showJugeme ? 'judgeme' : 'yotop'}
                    judgemeBadge={product.metafields?.judgemeBadge}
                    to={link}
                  />
                  {/* <a
                    href={link}
                    className="py-1 px-4 bg-[#00BEFA] text-white rounded-3xl mt-2 md:px-2 md:text-xs"
                    dangerouslySetInnerHTML={{ __html: data.btnText }}
                    onClick={() => btnClick(traceParams)}
                  ></a> */}
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </>
  )
}

export default Recommends
