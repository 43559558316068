import originalStyles from './BasicTiles.module.css'
import cn from 'classnames'
import { Picture, Text } from '@components/ui'
import { noBlankStr } from '@components/helper'
import TextsLayout from '@components/ui/TextsLayout/TextsLayout'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const BasicTiles = ({
  id = '',
  data,
  gap = 5,
  styles = {},
  trace = {},
  textsAbove = null,
  textsBelow = null,
  textsHover = null,
  textPosition = 'above',
  hoverParams = {},
}) => {
  const { setRelativeLink } = useRelativeLink()
  if (!data.images || !Array.isArray(data.images)) return null
  const s = { ...originalStyles, ...styles }
  // console.log('styles', styles)
  return (
    <>
      {textsAbove}
      {textPosition === 'above' && (
        <div className={s.aboveTextContainer}>
          <Content data={data} s={s} trace={trace} />
        </div>
      )}
      <div className={cn(s.container)} style={{ gap }}>
        {data.images.map((image, index) => {
          const size = sizeMapping[image.size]
          const mobileSize = sizeMapping[image.mobileSize]
          return (
            <div
              key={`pic-${index}`}
              id={`picture-${index}`}
              className={cn(s.picItem, 'overflow-hidden')}
            >
              <style>
                {noBlankStr(`
                  ${
                    size
                      ? `#${id} #picture-${index} {
                          grid-column: span ${size.width} / span ${size.width};
                          grid-row: span ${size.height} / span ${size.height};
                        }`
                      : ''
                  }
                  ${
                    mobileSize
                      ? `@media (max-width: 769px) {
                          #${id} #picture-${index} {
                            grid-column: span ${mobileSize.width} / span ${mobileSize.width};
                            grid-row: span ${mobileSize.height} / span ${mobileSize.height};
                          }
                        }`
                      : ''
                  }
                `)}
              </style>
              {image.to ? (
                <a
                  href={image?.to && setRelativeLink({ link: image.to })}
                  onClick={() => trace.jump(image.to)}
                  target={data.target}
                  rel={data.target === '_blank' ? 'noreferrer' : ''}
                >
                  <Pic image={image} s={s} />
                </a>
              ) : (
                <Pic image={image} s={s} />
              )}
            </div>
          )
        })}
      </div>
      {textsBelow}
      {textsHover}
      {textPosition === 'hover' && (
        <TextsLayout
          align={hoverParams.align}
          justify={hoverParams.justify}
          gridStyle={hoverParams.gridStyle}
          responsive={hoverParams.responsive}
        >
          <Content data={data} s={s} trace={trace} />
        </TextsLayout>
      )}
    </>
  )
}

export default BasicTiles

const Pic = ({ image, s }) => {
  if (!image.url) return null
  let source = image.url
  if (image.mobileUrl) {
    source = `${image.url}, ${image.mobileUrl} 769`
  }
  return (
    <Picture
      className={cn(s.picture, {
        ['md:hidden']: image.mobileSize === 0,
      })}
      imgClassName={s.img}
      source={source}
    />
  )
}

const Content = ({ data, s, trace }) => (
  <>
    {data.title && (
      <Text
        html={data.title}
        variant="sectionHeading"
        className={cn(s.title, { [s.sub]: data.link || data.subTitle })}
      />
    )}
    {data.subTitle && (
      <Text html={data.subTitle} className={s.subTitle} variant="paragraph" />
    )}
    {data.link && (
      <a href={data.link.to} className={s.link} onClick={trace.buttonClick}>
        {data.link.text}
      </a>
    )}
  </>
)

const sizeMapping = {
  'full-width': {
    width: 12,
    height: 2,
  },
  large: {
    width: 6,
    height: 2,
  },
  medium_6_1: {
    width: 6,
    height: 1,
  },
  medium_4_2: {
    width: 4,
    height: 2,
  },
  medium_3_2: {
    width: 3,
    height: 2,
  },
  small_3_1: {
    width: 3,
    height: 1,
  },
  small_4_1: {
    width: 4,
    height: 1,
  },
}
