import Picture from '@components/ui/Picture'
import defaultStyles from './NormalTexts.module.css'
import cn from 'classnames'
import { Text } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const NormalTexts = ({
  data,
  align = 'center',
  justify = 'top',
  className = '',
  divide = false,
  onLinkClick = () => {},
  s: gs = {},
  mobile = { align: 'center', justify: 'top' },
  icons = true,
  ...rest
}) => {
  const s = { ...defaultStyles, ...gs }
  const { setRelativeLink } = useRelativeLink()
  if (!data) return null
  return (
    <div
      className={cn(s.container, className, {
        ['items-start text-left']: align === 'left',
        ['md:items-start md:text-left']: mobile.align === 'left',
        ['items-center text-center']: align === 'center',
        ['md:items-center md:text-center']: mobile.align === 'center',
        ['items-end']: align === 'right',
        ['md:items-end']: mobile.align === 'right',
        ['justify-start']: justify === 'top',
        ['md:justify-start']: mobile.justify === 'top',
        ['justify-evenly']: justify === 'even',
        ['md:justify-evenly']: mobile.justify === 'even',
        ['justify-center']: justify === 'center',
        ['md:justify-center']: mobile.justify === 'center',
        ['justify-end']: justify === 'bottom',
        ['md:justify-end']: mobile.justify === 'bottom',
      })}
      {...rest}
    >
      {/* 文本需要在上盒子内居中，因此需要一个盒子隔离 */}
      <div
        className={cn(
          `w-[${data?.txtWidth}]`,
          data?.txtWidth && 'mx-auto md:w-full'
        )}
      >
        {data.title && (
          <Text
            variant="sectionHeading"
            className={cn(s.title, {
              ['only-in-pc']: data.mobileTitle,
              ['mb-[26px]']: !data.subTitle,
            })}
            html={data.title}
          />
        )}
        {data.mobileTitle && (
          <Text
            variant="sectionHeading"
            className={cn(s.mobileTitle, 'only-in-mobile')}
            html={data.mobileTitle}
          />
        )}
        {data.subTitle && (
          <Text
            variant="paragraph"
            className={s.subTitle}
            html={data.subTitle}
          />
        )}
      </div>
      {divide && <hr className="mt-6 w-full" />}
      {icons && data.icons && (
        <div
          className={cn(s.icons, {
            ['!pt-0 md:!pt-6']: divide,
          })}
        >
          {data.icons.map((icon, index) => (
            <div className={s.icon} key={`icon-${index}`}>
              {icon.url?.trim() && (
                <Picture
                  source={`${icon.url}, ${icon.mobileUrl || icon.url} 769`}
                  alt={icon.alt || icon.text}
                  imgClassName={s.iconImg}
                />
              )}
              {icon.text?.trim() && (
                <Text
                  variant="paragraph"
                  className={s.iconText}
                  html={icon.text}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {(data.link || data.to) && (
        <a
          href={setRelativeLink({ link: data.link?.to || data.to })}
          className={s.link}
          onClick={onLinkClick}
        >
          {data.link?.text || data.linkText}
        </a>
      )}
      {data?.corporate_BtnText && (
        <span
          className={cn(s.link, 'cursor-pointer')}
          onClick={onLinkClick}
          dangerouslySetInnerHTML={{ __html: data.corporate_BtnText || '' }}
        ></span>
      )}
    </div>
  )
}

export default NormalTexts
