import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import Slider from 'react-slick'
import isMobile from 'ismobilejs'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Picture } from '@components/ui'

import s from './Slider3p.module.css'
import { divide } from 'lodash'

const PrevArrow = ({ onClick, active, c = '' }) => (
  <button onClick={onClick} className={cn(c, s.arrow, s.arrowLeft)}>
    <ChevronLeftIcon />
  </button>
)

const NextArrow = ({ onClick, active, c = '' }) => (
  <button onClick={onClick} className={cn(c, s.arrow, s.arrowRight)}>
    <ChevronRightIcon />
  </button>
)

const Slider3p = ({ data }) => {
  const [slidesToShow, setSlidesToShow] = useState(3)

  const settings = {
    speed: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setSlidesToShow(2)
    } else {
      setSlidesToShow(3)
    }
  }, [])

  return (
    <div className={s.root}>
      {data?.title && (
        <h2
          className={s.title}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />
      )}
      <style jsx global>
        {`
          .${s?.container} .slick-slide {
            overflow: hidden;
            transform: scale(0.93);
          }
          .${s?.container} .slick-slide.slick-center {
            transform: scale(1);
            transition: 1s;
          }
        `}
      </style>
      <Slider className={s.slider} {...settings}>
        {data?.blocks?.map((item, index) => {
          return (
            <div className={s.sliderCart} key={`Slider3p${index}`}>
              <Picture
                source={`${item.img}`}
                alt={item?.alt || ''}
                className={s.pictureClass}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default Slider3p
