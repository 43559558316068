import s from './HtmlContainer.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const HtmlContainer = ({ html }) => {
  const { setHtmlRelativeLink } = useRelativeLink()
  if (!html) return null
  const oHtml = html.replace(
    'src="https://www.youtube.com',
    'width="100%" style="aspect-ratio:16/9" src="https://www.youtube.com'
  )
  return (
    <div
      className={s.container}
      dangerouslySetInnerHTML={{
        __html: setHtmlRelativeLink({ html: oHtml }),
      }}
    ></div>
  )
}

export default HtmlContainer
