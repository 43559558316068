import { formatPrice } from '@commerce/product/use-price'
import { cn } from '@components/helper'
import { Picture, Text } from '@components/ui'
import { StarIcon } from '@heroicons/react/24/outline'
import Decimal from 'decimal.js'
import { useRouter } from 'next/router'
import { useState } from 'react'

const Calculator = ({ data, product }) => {
  const [price, setPrice] = useState(null)
  const [error, setError] = useState(false)
  const [giftCards, setGiftCards] = useState(
    data.discounts?.map((discount) => ({
      price: new Decimal(discount.value).minus(discount.discount).toNumber(),
      basePrice: discount.value,
      quantity: '?',
    }))
  )

  const { locale } = useRouter()

  const handleClick = (e) => {
    e.preventDefault()
    if (price < 100 || !price) {
      setError(true)
      return
    }

    /** 拼凑礼品卡得到用户可用的礼品卡 */
    const discounts = [...(data?.discounts || [])]?.sort(
      (a, b) => b.value - a.value
    )

    const getCards = ([price, cards]) => {
      let discount = discounts.find((discount) => price >= discount.value)
      // 不满最小金额的部分用最小额度的礼品卡，没有用完的钱可以留给下一次用
      if (!discount) {
        discount = discounts[discounts?.length - 1]
      }

      if (price <= 0) {
        return [price, cards]
      } else {
        const card = cards?.find((card) => card.basePrice === discount.value)
        if (card) {
          card.quantity = card.quantity + 1
        } else {
          cards.push({
            price: new Decimal(discount.value)
              .minus(discount.discount)
              .toNumber(),
            basePrice: discount.value,
            quantity: 1,
          })
        }
        return getCards([price - discount.value, cards])
      }
    }
    const cards = getCards([price, []])[1]

    setGiftCards(cards)
  }

  return (
    <section className="layer bg-gradient-to-r from-[#00A9E0] to-[#00DB84] py-[80px] leading-[1.2] md:py-[64px]">
      <div className="content">
        <Text
          html={data.title}
          variant="sectionHeading"
          className="text-center text-[48px] font-bold text-white md:text-[28px]"
        />
        <div className="mx-auto mt-[60px] w-[810px] max-w-[95%] rounded-[10px] bg-white shadow md:mt-[30px]">
          <div className="rounded-t-[10px] px-[80px] py-[60px] md:px-[16px] md:py-[30px]">
            <div className="h-[83px] rounded-[6px] bg-gradient-to-r from-[#00A9E0] to-[#00DB84] p-[2px] md:h-[40px]">
              <form
                className="flex h-full w-full overflow-hidden rounded-[4px]"
                onSubmit={handleClick}
              >
                <input
                  value={price}
                  onChange={(e) => {
                    setError(false)
                    if (!e.target.validity.valid) {
                      e.target.value = null
                    } else {
                      setPrice(e.target.value)
                    }
                  }}
                  placeholder={data.placeholder}
                  className={cn(
                    'h-full w-[50%] pl-[18px] text-[16px] placeholder:text-[16px] placeholder:text-[rgba(98,98,98,0.6)] md:w-[65%] md:pl-[10px] md:text-[14px] md:placeholder:text-[14px] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
                    {
                      ['border-2 border-red outline-2 outline-red']: error,
                    }
                  )}
                  step="0.01"
                  type="number"
                  min={0}
                />
                <button
                  type="submit"
                  className="h-full w-[50%] rounded-[2px] bg-gradient-to-r from-[#00A9E0] to-[#00DB84] text-[20px] font-semibold text-white md:w-[35%] md:text-[14px]"
                >
                  {data.cta}
                </button>
              </form>
            </div>
          </div>
          {giftCards && (
            <div className="rounded-b-[10px] bg-[rgba(171,250,226,0.31)] py-[40px] px-[80px] md:px-[16px] md:py-[30px]">
              <Text
                html={data.result}
                variant="paragraph"
                className="text-center text-[20px] font-semibold md:text-[16px]"
              />
              <div className="mt-[50px] flex justify-center gap-[20px] md:mt-[30px] md:flex-col md:items-center md:gap-[40px]">
                {giftCards?.map((card) => (
                  <div
                    key={card.basePrice}
                    className={cn(
                      'mr-[36px] w-[50%] rounded-[10px] bg-gradient-to-tl from-[#00A9E0] to-[#00DB84] p-[5px] md:mr-0 md:w-[60%]',
                      {
                        ['mr-0']: giftCards?.length === 1,
                      }
                    )}
                  >
                    <div className="relative h-full w-full">
                      <Picture source={data.cardBgUrl} />
                      <div className="absolute inset-0 flex flex-col items-center justify-between rounded-[14px] py-[40px] px-[20px] md:py-[20px] md:px-[10px]">
                        <div className="flex items-center justify-center">
                          <StarIcon className="h-[18px] w-[18px] fill-[rgba(1,216,140,0.3)] stroke-[rgba(1,216,140,0.3)] md:h-[8px] md:w-[8px]" />
                          <Text
                            html={data.member}
                            className="mx-[10px] text-center text-[14px] font-bold text-[#8F8F8F] md:text-[6px]"
                            variant="paragraph"
                          />
                          <StarIcon className="h-[18px] w-[18px] fill-[rgba(1,216,140,0.3)] stroke-[rgba(1,216,140,0.3)] md:h-[8px] md:w-[8px]" />
                        </div>
                        <div className="text-[40px] font-bold md:text-[28px]">
                          {formatPrice({
                            amount: card.basePrice,
                            currencyCode: product.price.currencyCode,
                            locale,
                          })}
                        </div>
                        <div className="text-center text-[18px] font-bold xs:mb-[8px] md:text-[12px]">
                          {data.get?.replace(
                            '$value',
                            formatPrice({
                              amount: card.price,
                              currencyCode: product.price.currencyCode,
                              locale,
                            })
                          )}
                        </div>
                      </div>
                      <div className="absolute right-[-40px] top-[-40px] flex h-[88px] w-[88px] items-center justify-center rounded-full bg-gradient-to-tr from-[#00A9E0] to-[#00DB84] md:top-[-24px] md:right-[-24px] md:h-[56px] md:w-[56px]">
                        <span className="text-[36px] font-bold text-white md:text-[22px]">
                          ×{card.quantity}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Calculator
