import Slider from 'react-slick'
import cn from 'classnames'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import s from './Slider.module.css'

import { useState } from 'react'

const PrevArrow = ({ onClick, arrowClassName }) => (
  <button className="" onClick={onClick}>
    <ChevronLeftIcon className={cn(s.arrow, s.arrowLeft, arrowClassName)} />
  </button>
)

const NextArrow = ({ onClick, arrowClassName }) => (
  <button className="" onClick={onClick}>
    <ChevronRightIcon className={cn(s.arrow, s.arrowRight, arrowClassName)} />
  </button>
)

const MySlider = ({
  data,
  textAbove = null,
  items = null,
  dots = true,
  arrowClassName = '',
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data.items.length < 3 ? data.items.length : 3,
    dots,
    slidesToScroll: 1,
    autoplay: data.items.length <= 3 ? false : true,
    autoplaySpeed: 5000,
    prevArrow: <PrevArrow arrowClassName={arrowClassName} />,
    nextArrow: <NextArrow arrowClassName={arrowClassName} />,
    beforeChange: (prev, next) => {
      setCurrentSlide(next)
    },
    customPaging: function (i) {
      return (
        <span>
          <div
            className={cn(
              'mt-5 w-2 mr-[-1] h-2 rounded-full',
              currentSlide === i ? 'bg-black' : 'bg-gray-300'
            )}
          ></div>
        </span>
      )
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  return (
    <>
      <div className="flex flex-col md:px-3 text-center">{textAbove}</div>
      <Slider {...settings}>{items}</Slider>
    </>
  )
}

export default MySlider
