import { Picture, Text } from '@components/ui'
import s from './SwipeableViews.module.css'
import cn from 'classnames'
import SwipeableViews from 'react-swipeable-views'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'

const PrevArrow = ({ onClick, c = '' }) => (
  <button onClick={onClick} className={cn(c, s.arrow, s.arrowLeft)}>
    <ChevronLeftIcon />
  </button>
)

const NextArrow = ({ onClick, c = '' }) => (
  <button onClick={onClick} className={cn(c, s.arrow, s.arrowRight)}>
    <ChevronRightIcon />
  </button>
)

const OutDor = ({ data }) => {
  const theme = useTheme()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <div className="flex justify-between mb-10 md:justify-center md:mb-4 ">
        {data.tabs.map(({ tab }, i) => (
          <button key={tab} onClick={() => setActiveTab(i)}>
            <Text
              html={tab}
              className={cn(
                'text-xl border border-black rounded-3xl px-12 py-1 md:font-bold md:px-20 min-w-[280px]',
                {
                  ['font-bold']: activeTab === i,
                  ['md:hidden']: activeTab != i,
                }
              )}
              variant="paragraph"
            />
          </button>
        ))}
      </div>
      <div className={cn(s.contentContainer)}>
        <PrevArrow
          c="only-in-pc"
          onClick={() => {
            setActiveTab((prev) => {
              if (prev === 0) {
                return data.tabs.length - 1
              }
              return prev - 1
            })
          }}
        />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeTab}
          onChangeIndex={(i) => setActiveTab(i)}
        >
          {data.tabs.map((tab, i) => (
            <div key={`pc-${i}`}>
              <Picture
                className="w-full aspect-w-2 aspect-h-1 md:aspect-w-[315] md:aspect-h-[450]"
                source={`${tab.imgUrl}, ${tab.mobileImgUrl} 769`}
                imgClassName="h-full"
              />
            </div>
          ))}
        </SwipeableViews>
        <NextArrow
          c="only-in-pc"
          onClick={() => {
            setActiveTab((prev) => {
              if (prev === data.tabs.length - 1) {
                return 0
              }
              return prev + 1
            })
          }}
        />
        <div className="flex items-center justify-center gap-x-4 pt-1.5 only-in-mobile">
          {data.tabs.map(({ tab }, i) => (
            <button key={tab} onClick={() => setActiveTab(i)}>
              <div
                className={cn(
                  'w-1.5 h-1.5 rounded-full border-[0.1px] border-[#333333]',
                  {
                    ['bg-[#333333]']: activeTab === i,
                  }
                )}
              ></div>
            </button>
          ))}
        </div>
      </div>
    </>
  )
}

export default OutDor
