import s from './FullWidthSlider.module.css'
import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import Slider from 'react-slick'
import cn from 'classnames'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import TextsLayout from '@components/ui/TextsLayout/TextsLayout'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const FullWidthSlider = ({ data }) => {
  const [current, setCurrent] = useState(0)

  function SampleNextArrow(props) {
    const { onClick } = props
    return (
      <button
        className={
          'absolute top-[50%] right-[14%] z-10 md:top-[42%] md:right-0'
        }
        onClick={onClick}
      >
        <ChevronRightIcon className={`w-10`} />
      </button>
    )
  }
  function SamplePrevArrow(props) {
    const { onClick } = props
    return (
      <button
        className={'absolute top-[50%] left-[13%] z-10 md:top-[42%] md:left-0'}
        onClick={onClick}
      >
        <ChevronLeftIcon className={`w-10`} />
      </button>
    )
  }
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1.5,
    speed: 1000,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => {
      setCurrent(next)
    },
    responsive: [
      {
        breakpoint: 768,
        centerMode: false,
        settings: {
          slidesToShow: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  }
  return (
    <div className={s.container}>
      <style jsx global>
        {`
          .${s.container} .slick-slide {
            transform: translateX(-25%);
          }
          .${s.container} .slick-dots {
            display: flex !important;
            justify-content: center;
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
          }
          .${s.container} .slick-dots li {
            width: 118px;
            margin-left: 20px;
          }
          .${s.container} .slick-dots li:nth-child(1) {
            margin: 0;
          }
          .${s.container} .slick-track {
            display: flex;
            align-items: stretch;
          }

          .${s.container} .slick-slide {
            position: relative;
            height: auto;
          }
          .${s.container} .slick-slide > div,
          .${s.container} .slick-slide > div > div {
            height: 100%;
          }
          .${s.container} .slick-slide > div > div {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
          }
          .${s.container} .slick-slide img {
            padding-right: 10px;
          }
          .${s.container} .slick-arrow:before {
            display: none !important;
          }
          @media (max-width: 768px) {
            .${s.container} .slick-slide {
              padding: 0;
              transform: translateX(0%);
              margin-bottom: -7px;
            }
            .${s.container} .slick-slide img {
              padding-right: 0px;
            }
          }
        `}
      </style>
      {data.items.map((item, i) => {
        return (
          <div
            key={i}
            className={cn(
              'text-center transition-all flex flex-col items-center mx-auto only-in-pc',
              (current + 1 === data.items.length ? i === 0 : current + 1 === i)
                ? 'visible'
                : 'hidden'
            )}
          >
            {item.title && (
              <Text
                variant="sectionHeading"
                className={
                  'text-[calc(68/1920*100vw)] font-bold leading-[1.2]  md:text-[calc(32/375*100vw)] pb-5'
                }
                html={item.title}
              />
            )}
            <Text
              variant="paragraph"
              className={'text-2xl  min-h-[64px] mb-[30px] '}
              html={item.subTitle}
            />
          </div>
        )
      })}
      <Slider {...settings}>
        {data.items.map((item, i) => {
          return (
            <div key={i} className="relative h-full">
              <Picture
                imgClassName="rounded-[20px] w-full md:rounded-none"
                source={`${item.imgUrl}, ${item.mobileImgUrl} 769`}
              />
              <TextsLayout
                gridStyle={{ rowStart: 2 }}
                putTextClassName="pt-[67px]"
                className="only-in-mobile "
              >
                <Text
                  variant="sectionHeading"
                  className={
                    'text-[calc(68/1920*100vw)] leading-[1.2] font-bold  md:text-[calc(32/375*100vw)] pb-5'
                  }
                  html={item.title}
                />
                <Text
                  variant="paragraph"
                  className={'text-base pb-[30px] '}
                  html={item.subTitle}
                />
              </TextsLayout>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default FullWidthSlider
