import { Picture } from '@components/ui'
import cn from 'classnames'
import { useCallback, useState } from 'react'
import styles from './GreenerFeatures.module.css'
import { useRouter } from 'next/router'

const MT = {
  2: 'mt-[71px] pl-[66px]',
  4: 'mt-[51px] pl-[45px]',
  6: 'mt-[92px] pl-[60px]',
}

function GreenerFeatures({ data, isMobile }) {
  const router = useRouter()
  const { locale } = router
  const featuresArr = isMobile
    ? [...data?.features].sort((a, b) => a.sortId - b.sortId)
    : [...data?.features]

  const [activeIndex, setActiveIndex] = useState()

  const handlePlusClick = useCallback((index) => {
    setActiveIndex(index)
  }, [])

  const handleCloseClick = useCallback(() => {
    setActiveIndex()
  }, [])

  return (
    <section className="max-w-[1200px] mx-auto bg-white">
      <div className="grid grid-cols-2 gap-y-[30px] md:flex flex-col md:gap-y-[0] md:first:mt-0">
        {featuresArr &&
          featuresArr.map((item, index) => (
            <div
              key={index}
              className={cn(
                styles.featuresBox,
                'odd:rounded-l-[calc(20/1920*100vw)]',
                'even:rounded-r-[calc(20/1920*100vw)]',
                'odd:md:rounded-none odd:md:mt-[140px]',
                'even:md:rounded-none'
              )}
            >
              {item?.img_pc && item?.img_mob && (
                <div className="relative">
                  {/* <Picture
                    quality={2}
                    source={`${item?.img_pc},${item?.img_mob} 769`}
                    className="w-full aspect-w-[600] aspect-h-[500] md:aspect-w-[375] md:aspect-h-[375]"
                  /> */}
                  <img src={item?.img_pc} className="md:hidden" />
                  <img src={item?.img_mob} className="md:block hidden" />

                  {/* {item?.sign_img_pc && (
                    <div className="absolute w-[6vw] left-[calc(62/1920*100vw)] bottom-[calc(26/1920*100vw)] md:bottom-[calc(36/375*100vw)] md:left-[calc(252/375*100vw)] md:w-[24vw]">
                      <Picture
                        quality={2}
                        source={`${item?.sign_img_pc},${item?.sign_img_mob}`}
                        className={`w-full ${
                          item?.isFSC
                            ? 'aspect-w-[100] aspect-h-[151]'
                            : 'aspect-w-[116] aspect-h-[127]'
                        }`}
                      />
                    </div>
                  )} */}
                  {isMobile && (
                    <>
                      {item?.img_text && (
                        <p
                          className="text-white text-[calc(20/375*100vw)] font-bold absolute left-[calc(24/375*100vw)] bottom-[calc(29/375*100vw)] leading-[1.2]"
                          dangerouslySetInnerHTML={{
                            __html: item?.img_text,
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
              {item?.des && (
                <div
                  className={cn(
                    styles.cardBox,
                    activeIndex === index && styles.transform180
                  )}
                >
                  <div
                    className={cn(
                      styles.backHidden,
                      'absolute left-0 top-0 w-full h-full z-10 md:w-screen'
                    )}
                  >
                    <div
                      className={cn(
                        `overflow-hidden w-full bg-[#D5ECE3] relative h-full md:pl-[calc(29/375*100vw)] md:pt-[calc(50/375*100vw)] md:pb-[calc(42/375*100vw)]`,
                        !isMobile && MT[item.sortId]
                      )}
                    >
                      <>
                        {item?.isHorizontal && !isMobile ? (
                          <>
                            {item?.title1 && (
                              <div
                                className="text-[36px] font-bold leading-[1.2]"
                                dangerouslySetInnerHTML={{
                                  __html: item?.title1,
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {(item?.title1 || item?.title1_mob) && (
                              <div
                                className="text-[36px] font-bold leading-[1.2] md:text-[calc(22/375*100vw)]"
                                dangerouslySetInnerHTML={{
                                  __html: !isMobile
                                    ? item?.title1
                                    : item?.title1_mob,
                                }}
                              />
                            )}
                            {(item?.title2 || item?.title2_mob) && (
                              <div
                                className={cn(
                                  'text-[48px] font-bold leading-[1.2] md:text-black',
                                  locale === 'eu-de'
                                    ? 'md:text-[calc(30/375*100vw)]'
                                    : ' md:text-[calc(32/375*100vw)]'
                                )}
                                dangerouslySetInnerHTML={{
                                  __html: !isMobile
                                    ? item?.title2
                                    : item?.title2_mob,
                                }}
                              />
                            )}
                            {(item?.title3 || item?.title3_mob) && (
                              <div
                                className="text-[36px] font-bold leading-[1.2] md:text-[calc(22/375*100vw)]"
                                dangerouslySetInnerHTML={{
                                  __html: !isMobile
                                    ? item?.title3
                                    : item?.title3_mob,
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                      <p
                        className="text-[24px] text-[#666666] leading-[1.2] mt-[calc(25/1920*100vw)] md:mt-[15px] md:text-[16px]"
                        dangerouslySetInnerHTML={{
                          __html: isMobile ? item?.des_mob : item?.des,
                        }}
                      />
                      {/* <div className="w-full absolute left-[66px) bottom-[calc(52/1920*100vw)] md:bottom-[calc(42/375*100vw)] md:inline-block md:w-[35px] md:mt-[calc(26/375*100vw)]">
                        <Picture
                          onClick={() => handlePlusClick(index)}
                          quality={2}
                          source={`${item?.plus_img_pc},${item?.plus_img_mob} 768`}
                          className="w-[32px] h-[32px] md:w-[35px] md:h-[35px] cursor-pointer"
                        />
                      </div> */}
                      <p
                        className="md:text-[14px] text-[16px] mt-[40px] md:mt-[24px]"
                        dangerouslySetInnerHTML={{
                          __html: isMobile ? item?.note_mob : item?.note_pc,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={cn(
                      styles.transform180,
                      'absolute left-0 top-0 w-full h-full'
                    )}
                  >
                    <div
                      className={cn(
                        `flex flex-col justify-center bg-[#D5ECE3] pl-[calc(64/1920*100vw)] h-full md:pl-[calc(30/375*100vw)]`
                      )}
                    >
                      <p
                        className="text-[24px] md:text-[16px]"
                        dangerouslySetInnerHTML={{ __html: item?.note }}
                      />
                      {/* <div className="w-full absolute left-[calc(66/1920*100vw)] bottom-[calc(52/1920*100vw)] md:left-[calc(30/375*100vw)] md:bottom-[calc(42/375*100vw)]">
                        <Picture
                          quality={2}
                          onClick={() => handleCloseClick(index)}
                          source={`${item?.close_img_pc},${item?.close_img_mob} 768`}
                          className="w-[32px] h-[32px] md:w-[35px] md:h-[35px] cursor-pointer"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </section>
  )
}

export default GreenerFeatures
