import { cn } from '@components/helper'
import s from './VoltaicCard.module.css'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import { Cta, Price } from '@components/product'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import {
  atobID,
  getParameUrlCombineoptions,
  getCombineVariant,
  getCombineName,
  decimalAdjust,
  getCombineImg,
  getProductTag,
} from '@lib/utils/tools'
import Decimal from 'decimal.js'
import { useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { formatPrice } from '@commerce/product/use-price'
import Discount from '@components/product/Discount/Discount'
import useProduct from '@shopify/product/use-product'
import { normalizeStorefrontProduct } from '@components/normalize'
import { useUI } from '@components/ui'

const VoltaicCard = ({
  item,
  data,
  shopCommon,
  variant_url,
  discountVal,
  price,
  basePrice,
  sectionData,
  pageHandle,
  relatedProducts,
  relatedCoupons,
  combineoptions,
  curProduct,
  buyNowGiftFunc = () => [],
}) => {
  const products = useMemo(() => {
    const arr = [curProduct]
    relatedProducts?.forEach((item) => {
      if (item?.result) {
        arr.push(item?.result)
      }
    })
    return arr
  }, [curProduct, relatedProducts])
  // return null
  // 优化：移除了不必要的解构赋值，直接在使用的地方访问属性
  // 优化：移除了不必要的classnames库的使用，因为没有动态类名
  // 优化：移除了不必要的Picture组件注释掉的代码
  // 优化：使用了更简洁的条件渲染方式
  // 优化：移除了dangerouslySetInnerHTML，使用Text组件来显示文本，以避免潜在的XSS攻击
  // 优化：使用了React.Fragment来避免额外的div标签，减少DOM层级
  // 优化：使用了map函数的第二个参数作为key，避免了不必要的对象属性访问
  // const { combinationImg, combineOption, setCombineOption } = useUI()
  const [combineOption, setCombineOption] = useState({})
  const setPresets = (presets) => {
    return Object.values(presets)
      ?.map((item) => {
        if (item?.part_handlesku) {
          return item?.value !== '0' ? item?.part_handlesku : ''
        }
        return item
      })
      ?.join('|')
  }
  const { locale } = useRouter()
  const { setRelativeLink } = useRelativeLink()
  const link = setRelativeLink({
    link: data?.customerLink
      ? `${data?.customerLink}&ref=${sectionData?.urlRef || pageHandle}`
      : `/products/${data?.handle}?variant=${atobID(data?.variantId)}&ref=${
          sectionData?.urlRef || pageHandle
        }`,
  })
  const [combine_variant, setcombinevariant] = useState([])
  const [combineName, setCombineName] = useState('')
  const [customPrice, setCustomePrice] = useState(null)
  const [customOriginalPrice, setCustomeOriginalPrice] = useState(null)
  const [customDiscount, setCustomeDiscount] = useState(null)
  const [tradeList, setTradeList] = useState([])

  const gallery = data?.metafields?.gallery || {}
  const combineoptions_order = data?.metafields?.combineoptions_order
  const combineoptions_commoninfo = data?.metafields?.combineoptions_commoninfo
  const curGallery = useMemo(() => {
    const sku_to_bundle = combineoptions_commoninfo?.sku_to_bundle || []
    const has_bundle = Object.entries(sku_to_bundle)?.find(
      ([bundlekey, bundle]) =>
        bundle?.every((handlesku) =>
          Object.entries(combineOption)?.some(([key, option]) => {
            if (option === handlesku) {
              return true
            }
          })
        )
    )
    if (has_bundle?.length > 0) {
      const [handle, sku] = has_bundle?.[0]?.split(',')
      const b_product = products?.find((v) => v?.handle === handle)
      const b_variant = b_product?.variants?.find((v) => v?.sku === sku)
      if (b_variant?.metafields?.gallery) return b_variant?.metafields?.gallery
    }

    return data?.metafields?.gallery || gallery
  }, [data?.variantId, combineOption])

  const combineImg = useMemo(() => {
    // if (combinationImg && combinationImg?.length > 1) {
    //   return combinationImg?.map((item) => ({ url: item }))
    // }
    const { main, sub_main, part } =
      combineoptions_commoninfo?.gallery_order || {}
    const sku_to_gallery = combineoptions_commoninfo?.sku_to_gallery

    const customized = sku_to_gallery?.find((item) => {
      return item?.handlesku?.every((handlesku) => {
        return Object.entries(combineOption)?.some(([key, option]) => {
          if (option === handlesku) {
            return true
          }
        })
      })
    })
    if (customized?.image) {
      return {
        combineBox: true,
        mainImg: customized?.image,
      }
    }
    const mainImg = combineOption?.topRecommended
      ? getCombineImg({
          key: 'topRecommended',
          handlesku: combineOption?.topRecommended,
          combineoptions,
          combineOption,
          products,
        })
      : getCombineImg({
          key: main,
          handlesku: combineOption?.[main],
          combineoptions,
          combineOption,
          products,
        })
    const submainImg = getCombineImg({
      key: sub_main,
      handlesku: combineOption?.[sub_main],
      combineoptions,
      combineOption,
      products,
    })
    const partImg = part?.map((key) => {
      return getCombineImg({
        key,
        handlesku: combineOption?.[key],
        combineoptions,
        combineOption,
        products,
      })
    })
    return {
      combineBox: true,
      mainImg,
      submainImg,
      partImg,
      size: combineoptions_commoninfo?.gallery_order?.size,
    }
  }, [combineOption, data?.variantId, curGallery])

  useEffect(() => {
    const url_prest = getParameUrlCombineoptions({
      url: link,
      combineoptions_order,
      combineoptions,
    })

    if (
      Object.keys(url_prest)?.length > 0 &&
      Object.values(url_prest)?.some((v) => !!v)
    ) {
      setCombineOption({
        ...url_prest,
        preset: setPresets(url_prest),
      })
    } else if (!!combineoptions?.preset) {
      const first = combineoptions?.preset?.options?.[0]?.set
      setCombineOption({
        ...first,
        preset: setPresets(first),
      })
    } else {
      const firstOp =
        combineoptions_commoninfo?.main_option || combineoptions_order?.[0]
      const first = combineoptions?.[firstOp]?.options?.[0]
      if (first?.variantSelect) {
        setCombineOption({
          [firstOp]: `${first?.related_handle},${first?.variantSelect?.options?.[0]}`,
        })
      } else {
        setCombineOption({
          [firstOp]: first?.related_handlesku || '',
        })
      }
    }
  }, [link])

  const [
    {
      discountPriceNumber,
      totalDiscountPrice,
      totalOriginalPrice,
      totalDiscount,
      totalOriginal,
    },
    setPrice,
  ] = useState({})

  useEffect(() => {
    const products = relatedProducts?.map((item) => item?.result)
    const combineOption = getParameUrlCombineoptions({
      url: link,
      combineoptions_order,
      combineoptions,
    })
    const combine_variant = getCombineVariant({
      locale,
      products,
      relatedCoupons,
      combineOption,
      combineoptions,
      combineoptions_order,
      combineoptions_commoninfo,
    })

    if (combine_variant?.length > 0) {
      setcombinevariant(combine_variant)

      const combine_name = getCombineName({
        products,
        combineOption,
        combineoptions,
        combineoptions_commoninfo,
      })
      setCombineName(combine_name)

      const totalOriginal = combine_variant.reduce((prev, cur) => {
        return new Decimal(prev).plus(
          new Decimal(cur?.variant?.price || 0).times(
            new Decimal(cur?.quantity || 1)
          )
        )
      }, 0)

      const totalDiscount = combine_variant.reduce((prev, cur) => {
        return new Decimal(prev).plus(
          new Decimal(
            cur?.coupon?.variant_price4wscode || cur?.variant?.price || 0
          ).times(new Decimal(cur?.quantity || 1))
        )
      }, 0)

      const discountPriceNumber = decimalAdjust(
        'round',
        new Decimal(totalOriginal).minus(totalDiscount),
        -2
      )

      const discountPrice = formatPrice({
        amount: decimalAdjust(
          'round',
          new Decimal(totalOriginal)
            .minus(totalDiscount)
            .times(new Decimal(-1)),
          -2
        ),
        currencyCode: data?.currencyCode,
        locale,
        maximumFractionDigits: 2,
      })

      const totalDiscountPrice = formatPrice({
        amount: decimalAdjust('round', totalDiscount, -2),
        currencyCode: data?.currencyCode,
        locale,
        maximumFractionDigits: 2,
      })

      const totalOriginalPrice = formatPrice({
        amount: decimalAdjust('round', totalOriginal, -2),
        currencyCode: data?.currencyCode,
        locale,
        maximumFractionDigits: 2,
      })

      setPrice({
        discountPriceNumber,
        totalDiscountPrice,
        totalOriginalPrice,
        totalDiscount,
        totalOriginal,
      })
    }
  }, [link])

  const getBundle_option = (combine_variant) =>
    combine_variant?.find((item) => {
      return item?.option_key === 'bundle_option'
    })

  const sellingPoints = useMemo(() => {
    let sellingPointData = []
    const bundle_option = getBundle_option(combine_variant)
    if (bundle_option) {
      sellingPointData =
        bundle_option?.variant?.metafields?.infos?.sellingPointList || []
    } else {
      sellingPointData = data?.sellingPointList || []
    }
    return sellingPointData?.map((info, index) => (
      <li
        key={index}
        className="[&:nth-child(2)]:min-w-[39%] border-r border-[#D2D2D7] px-4 first:pl-0 last:border-none l:px-2 l:text-left"
      >
        <Text
          html={info?.title}
          className={cn(
            'mb-2 text-[18px] font-[600] leading-[1.25] text-[#1D1D1F] md:mb-1.5 md:text-[16px] md:leading-[1.26]'
          )}
        />
        <div className={s.toolTipsBox}>
          <Text html={info?.desc} className={cn(s.desc)} title={info?.desc} />
          <span className={s.toolTips}>{info?.desc}</span>
        </div>
      </li>
    ))
  }, [combine_variant, data])
  const sellingPointsNew = useMemo(() => {
    let sellingPointData = []
    const bundle_option = getBundle_option(combine_variant)
    if (bundle_option) {
      sellingPointData =
        bundle_option?.variant?.metafields?.infos?.sellingPointListNew || []
    } else {
      sellingPointData = data?.sellingPointListNew || []
    }
    return sellingPointData?.map((info, index) => (
      <li key={index} className="gap-x-4 flex items-center justify-start">
        <Picture
          className="w-[30px] h-[30px] flex-shrink-0"
          source={`${info?.img}, ${info?.img_mob || info?.img} 769`}
        />
        <div className={s.toolTipsBox}>
          <Text
            html={info?.title}
            className="text-left text-[14px] font-[700] leading-[1.01] text-[#1D1D1F] l:text-[px] l:leading-[1] [&_span]:font-[500] line-clamp-2"
          />
        </div>
      </li>
    ))
  }, [combine_variant, data])
  const buynowGiftLineItems = buyNowGiftFunc({ product: data })
  const combine_variantFreeGift =
    getBundle_option(combine_variant)?.variant?.metafields?.freeGift?.list?.map(
      (i) => {
        if (
          combine_variant?.[0]?.variant?.metafields?.freeGift?.property !== ''
        )
          return {}
        return i
      }
    ) || []

  const skuSelfFreeGift =
    data?.freeGift?.list?.map((i) => {
      if (data?.freeGift?.property !== '') return {}
      return i
    }) || []

  const allFreeGift = [...combine_variantFreeGift, ...skuSelfFreeGift]?.filter(
    (i) => i?.sku
  )

  const { data: freeGiftResult } = useProduct({
    handles: allFreeGift?.map((item) => item?.handle)?.join(','),
  })

  const _freeGiftList =
    data?.freeGift?.property !== ''
      ? []
      : data?.freeGift?.list
          ?.map((i) => {
            if (data?.freeGift?.property !== '') return
            return {
              tradeDiscountValue: i?.discountValue,
              ...normalizeStorefrontProduct(i, freeGiftResult),
            }
          })
          ?.filter((i) => i?.availableForSale)

  const freeGiftList = useMemo(() => {
    if (data?.customerLink || combine_variantFreeGift?.[0]) {
      return combine_variantFreeGift?.map((i) => {
        return {
          tradeDiscountValue: i?.discountValue,
          ...normalizeStorefrontProduct(i, freeGiftResult),
        }
      })
    } else {
      return skuSelfFreeGift?.map((i) => {
        return {
          tradeDiscountValue: i?.discountValue,
          ...normalizeStorefrontProduct(i, freeGiftResult),
        }
      })
    }
  }, [combine_variantFreeGift, skuSelfFreeGift, freeGiftResult, link])

  // const finallyGiftImg = freeGiftList?.[0]

  const [tradeGift, setTradeGift] = useState([])

  const uniqueProducts = data?.tradeGift
    ? data?.tradeGift?.list.filter(
        (product, index, self) =>
          index ===
          self.findIndex(
            (t) => t.sku === product.sku && t.handle === product.handle
          )
      )
    : []

  const { data: tradeGiftResult } = useProduct({
    handles:
      data?.tradeGift == ''
        ? ''
        : uniqueProducts?.map((item) => item?.handle)?.join(','),
  })

  // 赠品数量大于1 并且配置了多选一的时候（freeGiftContact: true）  才用选购的方式展示
  useEffect(() => {
    if (
      data?.tradeGift ||
      (freeGiftList?.length > 1 && data?.freeGiftContact)
    ) {
      let tradeGiftList = uniqueProducts?.map((i) => {
        return {
          tradeDiscountValue: i?.discountValue,
          ...normalizeStorefrontProduct(i, tradeGiftResult),
        }
      })

      let tradeGiftListFinal = []

      let tradeContactProducts = []

      tradeGiftList?.forEach((trade) => {
        if (!trade?.availableForSale) return
        if (
          data?.tradeContactSkus &&
          data?.tradeContactSkus?.length > 0 &&
          data?.tradeContactSkus?.indexOf(trade?.sku) > -1
        ) {
          tradeContactProducts.push({
            selected: tradeContactProducts?.length > 0 ? false : true,
            ...trade,
          })
        } else {
          tradeGiftListFinal.push({
            curNum: 0,
            ...trade,
          })
        }
      })

      if (tradeContactProducts?.length > 0) {
        tradeGiftListFinal.push({
          tradeContactDefaultNum: data?.tradeContactDefaultNum,
          curNum: product?.tradeContactDefaultNum || 0,
          tradeContactProducts,
        })
      }

      if (data?.freeGiftContact && freeGiftList?.length > 0) {
        tradeGiftListFinal.push({
          max: 1,
          curNum: 1,
          tradeContactDefaultNum: 1,
          tradeContactProducts: freeGiftList?.map((freeGift, freeIndex) => {
            return {
              property: 'freeGift',
              selected: freeIndex === 0 ? true : false,
              ...freeGift,
            }
          }),
        })
      }

      setTradeGift(tradeGiftListFinal)
    }
  }, [tradeGiftResult, data, setTradeGift])

  useEffect(() => {
    if (!tradeGift || tradeGift.length <= 0) return
    let totalTradePrice = 0
    let totalTradeOriginPrice = 0
    let totalTradeDiscount = 0
    let totalTradeList = []
    const productBasePrice = totalOriginal || product?.basePrice || 0
    const productPrice = totalDiscount || product?.price || 0
    tradeGift?.forEach((i) => {
      if (i?.tradeContactProducts && i?.tradeContactProducts?.length > 0) {
        const curSelectedInfo =
          i?.tradeContactProducts?.filter((item) => item?.selected)?.[0] || {}
        totalTradePrice = new Decimal(totalTradePrice || 0).plus(
          new Decimal(curSelectedInfo?.tradeDiscountValue || 0).times(
            i?.curNum || 0
          )
        )
        totalTradeOriginPrice = new Decimal(totalTradeOriginPrice || 0).plus(
          new Decimal(curSelectedInfo?.price || 0).times(i?.curNum || 0)
        )

        totalTradeDiscount = new Decimal(totalTradeDiscount).plus(
          curSelectedInfo?.property == 'freeGift'
            ? 0
            : new Decimal(totalTradeOriginPrice || 0).minus(
                totalTradePrice || 0
              )
        )
        if (i?.curNum > 0) {
          totalTradeList.push({
            ...curSelectedInfo,
            quantity: i?.curNum || 1,
          })
        }
      } else {
        totalTradePrice = new Decimal(totalTradePrice || 0).plus(
          new Decimal(i?.tradeDiscountValue || 0).times(i?.curNum || 0)
        )
        totalTradeOriginPrice = new Decimal(totalTradeOriginPrice || 0).plus(
          new Decimal(i?.price || 0).times(i?.curNum || 0)
        )

        totalTradeDiscount = new Decimal(totalTradeDiscount).plus(
          i?.property == 'freeGift'
            ? 0
            : new Decimal(totalTradeOriginPrice || 0).minus(
                totalTradePrice || 0
              )
        )

        if (i?.curNum > 0) {
          totalTradeList.push({
            ...i,
            quantity: i?.curNum || 1,
          })
        }
      }
    })

    setCustomePrice(new Decimal(totalTradePrice || 0).plus(productPrice))

    setCustomeOriginalPrice(
      new Decimal(totalTradeOriginPrice || 0).plus(productBasePrice)
    )

    setCustomeDiscount(
      new Decimal(totalTradeDiscount || 0).plus(
        new Decimal(productBasePrice).minus(productPrice)
      )
    )

    setTradeList(totalTradeList)
  }, [tradeGift, data, totalDiscount, totalOriginal])

  const finalProductList = useMemo(() => {
    if (data?.freeGiftContact && freeGiftList?.length > 1) {
      return [...tradeList]
    } else {
      return [...freeGiftList, ...tradeList]
    }
  }, [data, tradeList, freeGiftList])

  const hoverProSence = useMemo(() => {
    if (sectionData?.showCombineHoverImg) {
      let url = combine_variant[0]?.variant?.metafields?.gallery?.list.find(
        (i) => i?.type == 'scene'
      )?.images?.[0]?.url
      return url
    }
    if (sectionData?.showCombineHoverImg && url) {
      return url
    }
    if (data?.variantMetafields?.infos?.hoverProSence)
      return data?.variantMetafields?.infos?.hoverProSence
    if (Array.isArray(data?.variantMetafields?.gallery?.list)) {
      return data?.variantMetafields?.gallery?.list?.find(
        (i) => i?.type == 'scene'
      )?.images?.[0]?.url
    }
  }, [data?.variantMetafields, combine_variant])

  const renderPriceSection = () => (
    <div className="mt-4 md:text-center">
      <Price
        s={{
          priceWrap: s.cardPriceWrap,
          price: s.cardPrice,
          basePrice: s.cardBasePrice,
        }}
        copy={shopCommon}
        soldOutTxt={sectionData?.soldOutTxt || ''}
        product={data}
        totalDiscountPrice={totalDiscountPrice}
        totalOriginalPrice={totalOriginalPrice}
        totalDiscount={totalDiscount}
        totalOriginal={totalOriginal}
      />
    </div>
  )
  let buttonsList = item?.newButtons || sectionData?.newButtons || []

  const tagImg =
    data?.variantMetafields?.infos?.tagImg ||
    sectionData?.tagImg ||
    data?.tagImg

  const tagImgMobile =
    data?.variantMetafields?.infos?.tagImgMobile ||
    sectionData?.tagImgMobile ||
    data?.tagImgMobile

  return (
    <div className={cn(s.cldCard, 'hover-pro-div min-l:!pb-[30px]')}>
      <a
        href={variant_url}
        target={item?.target}
        className="cursor-pointer text-[17px] font-[500] leading-[1.5] text-[#00A9E0]"
      >
        <div
          className={cn(
            'h-[217px] w-full l:h-[185px]',
            `${hoverProSence ? 'relative z-[4]' : ''}`
          )}
        >
          <div
            className={cn(
              'relative h-full w-full',
              `${hoverProSence ? 'md:hidden' : ''}`
            )}
          >
            <div className="box-border h-full w-full relative pt-3">
              {!combineImg?.mainImg ? (
                <Picture
                  source={`${data?.image}, ${data?.mobileImage} 769`}
                  className="mx-auto h-full !w-auto"
                  imgClassName="h-full !w-auto mx-auto"
                />
              ) : (
                <div className="w-[260px] h-full mx-auto relative md:w-auto md:h-full">
                  {combineImg?.mainImg && (
                    <Picture
                      source={combineImg?.mainImg}
                      className="mainImg absolute w-[200px] md:w-[160px] top-1/2  -translate-y-1/2 right-[4%] md:right-[11%]"
                    ></Picture>
                  )}
                  {combineImg?.submainImg && (
                    <Picture
                      source={combineImg?.submainImg}
                      className="submainImg z-[1] absolute bottom-[11%] left-[2%] w-[75px] md:w-[60px] md:left-[26%] md:bottom-[16%]"
                    ></Picture>
                  )}
                  <div
                    className={cn(
                      'partImgBox absolute left-0 top-0 z-[1] flex w-[45%] flex-wrap gap-3 md:left-[29%] md:gap-1'
                      // {
                      //   ['bottom-[6%] top-auto md:!left-0 md:bottom-[10%]']:
                      //     !!combineImg?.size?.sideByside,
                      // }
                    )}
                  >
                    {combineImg?.partImg?.map((img, index) => {
                      return (
                        img && (
                          <Picture
                            key={'partImg' + index}
                            alt=""
                            loading={'lazy'}
                            className={cn(
                              'h-[100px] w-[100px] md:h-[60px] md:w-[60px]',
                              {
                                ['!h-full !w-full']:
                                  !!combineImg?.size?.sideByside,
                              }
                            )}
                            source={img}
                          />
                        )
                      )
                    })}
                  </div>
                </div>
              )}
              {/* {data?.giftImage && (
                <Picture
                  source={data?.giftImage}
                  className="absolute bottom-[8%] right-[12%] w-[75px] md:w-[60px]"
                ></Picture>
              )} */}
              {freeGiftList?.[0]?.image && (
                <Picture
                  // source={freeGiftList?.[0]?.image}
                  source={`${freeGiftList?.[0]?.image}, ${
                    freeGiftList?.[0]?.mobileImage || freeGiftList?.[0]?.image
                  } 769`}
                  className="absolute bottom-[8%] right-[12%] w-[75px] md:w-[60px]"
                ></Picture>
              )}
            </div>
            {hoverProSence && (
              <div className="hover-pro-scence absolute left-0 top-0 z-[2] hidden h-full w-full">
                <Picture
                  className="h-full w-full object-cover"
                  imgClassName="w-full h-full object-cover"
                  source={`${hoverProSence}`}
                ></Picture>
              </div>
            )}
          </div>
          {hoverProSence && (
            <div className="h-full w-full min-md:hidden">
              <Swiper
                className="h-full w-full"
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                loop={true}
                modules={[Autoplay]}
                speed={300}
              >
                <SwiperSlide key="hoverProSence0" className="h-full w-full">
                  <div className="box-border h-full w-full pt-5 l:pt-4">
                    <Picture
                      source={`${data?.img}, ${data?.img_mob} 769`}
                      className="mx-auto h-full !w-auto"
                      imgClassName="h-full !w-auto mx-auto"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide key="hoverProSence1" className="h-full w-full">
                  <Picture
                    className="h-full w-full object-cover"
                    imgClassName="w-full h-full object-cover"
                    source={`${hoverProSence}`}
                  ></Picture>
                </SwiperSlide>
              </Swiper>
            </div>
          )}
        </div>

        {/* {discountVal && (
          <div className={s.cardTag}>
            <Text
              className={s.tagText}
              html={`${discountVal} ${shopCommon?.off}`}
            />
          </div>
        )} */}
        <Discount
          customDiscount={customDiscount}
          style={{
            color: data?.styles?.discountTxtColor || '',
          }}
          discountPriceNumber={discountPriceNumber}
          data={shopCommon}
          product={data}
          cns={{
            discountWrap: s.cardTagNew,
            discountImg: 'hidden',
            discount: s.cardTagInner,
            discountValue: s.tagText,
            discountText: s.tagText,
          }}
          hidebgImg={true}
        />
        {tagImg && (
          <Picture
            source={`${tagImg}, ${tagImgMobile || tagImg} 768`}
            className="absolute right-0 top-0 z-[2] w-[120px] md:w-[60px]"
          />
        )}
      </a>

      <div className="px-7 l:px-[18px]">
        <Text
          style={{
            color: sectionData?.styles?.tagColor || '',
          }}
          html={getProductTag({
            variant: { metafields: data?.variantMetafields },
            custom: data?.tag,
          })}
          className="h-5 text-[16px] font-[600] leading-[1.25] text-[#F00]"
        />
        <Text
          html={data?.custom_title || combineName || data?.title}
          className="mb-2.5 mt-2 line-clamp-2 h-[45px] text-[18px] font-[600] leading-[1.25] text-[#1D1D1F] md:mb-2 md:leading-[1.26] l:mb-2 l:h-[40px] l:px-0 l:text-[16px]"
        />

        {data?.progressInfo?.saveNum && (
          <div className={cn('mb-6 h-[30px] leading-[30px]', s.progressBox)}>
            <Text
              variant="inline"
              html={`${data?.progressInfo?.text} ${formatPrice({
                amount: decimalAdjust('round', data?.progressInfo?.saveNum, -2),
                currencyCode: data?.currencyCode,
                locale,
                maximumFractionDigits: 0,
              })}`}
              className="relative z-[2] px-2 text-center text-[12px] font-[600] leading-[1.33] text-[#000] l:text-[12px] l:leading-[1.16]"
            />
            <span
              className={s.progressCtx}
              style={{
                width:
                  (data?.progressInfo?.saveNum / data?.progressInfo?.allNum) *
                    100 +
                  '%',
              }}
            ></span>
          </div>
        )}

        {!!sellingPoints?.length &&
          !sectionData?.styles?.showSellingPointsNew && (
            <ul className="mb-6 flex items-start justify-start min-l:w-[86%]">
              {sellingPoints}
            </ul>
          )}
        {!!sellingPointsNew?.length &&
          sectionData?.styles?.showSellingPointsNew && (
            <ul className="mb-6 md:mb-2 flex flex-col items-start justify-start gap-y-4">
              {sellingPointsNew}
            </ul>
          )}

        <div
          className={cn({
            ['flex items-end justify-between gap-x-1.5 l:mb-0']:
              !sectionData?.hiddenPrice,
          })}
        >
          {!sectionData?.hiddenPrice && renderPriceSection()}

          {buttonsList.length ? (
            <div className="flex gap-[12px] w-full justify-between">
              {buttonsList?.map((item, index) => {
                // 确定 href 的值，优先使用 item.url，否则使用 variant_url
                const href = setRelativeLink({
                  link: item.url || variant_url,
                  params: { ref: pageHandle },
                })

                const className = cn(
                  s.learnMoreBtn,
                  !item.url && 'flex-1 text-center'
                )

                return (
                  <a
                    key={index}
                    href={href}
                    target={item?.target}
                    className={className}
                    dangerouslySetInnerHTML={{ __html: item?.text }}
                  ></a>
                )
              })}
            </div>
          ) : sectionData?.ctaButtons ? (
            <div className="ml-[2%] flex flex-1 justify-end">
              <Cta
                className="w-full max-w-[200px] md:max-w-[166px]"
                copy={{
                  ...shopCommon,
                  ref: sectionData?.urlRef || pageHandle,
                }}
                custom_link={link}
                combine_variant={
                  combine_variant?.length > 0
                    ? [...combine_variant, ...finalProductList]
                    : combine_variant
                }
                buttons={
                  (sectionData?.ctaButtons?.length > 0 && [
                    { ...sectionData?.ctaButtons?.[0], link },
                  ]) || [
                    {
                      type: 'buyAddBundle',
                      theme: 'buyAddBundle',
                      addCartIcon:
                        'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/1_378cc0d2-0a7e-4c02-ac9c-cb4ff5fed067.png?v=1719373978',
                    },
                  ]
                }
                product={data}
                products={
                  finalProductList?.length > 0
                    ? [data, ...finalProductList]
                    : [data]
                }
                pageHandle={pageHandle}
                buynowGiftLineItems={buynowGiftLineItems}
                gtmParam={{
                  itemListName: sectionData?.title,
                }}
                s={{
                  btnWrapper: '!h-[36px]',
                  buttons: sectionData?.styles?.btnIsCol
                    ? `ProductCard-buttons flex flex-col ${
                        sectionData?.styles?.btnIsCol ? 'items-center' : ''
                      } justify-start md:justify-center md:items-center gap-[10px]`
                    : 'ProductCard-buttons flex md:flex-col justify-center items-center gap-[10px]',
                  btnWrapperSolidout: 'btnWrapperSolidout',
                }}
                squareBorder={true}
              />
            </div>
          ) : (
            !item?.showBtn && (
              <a
                dangerouslySetInnerHTML={{
                  __html: shopCommon?.learnMoreWithArrow,
                }}
                href={variant_url}
                target={item?.target}
                className={s.learnMoreBtn}
              ></a>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default VoltaicCard
