import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Button, Picture } from '@components/ui'
import fetcher from '@lib/fetcher'
import { pageGTMEvent, appendAMZPixel } from '@lib/utils/thirdparty'
import { I18N_STORE_DOMAIN } from '@framework/const'
import { getAdCookie } from '@lib/utils/cookies'
import { useUI } from '@components/ui'
import { parseMessage } from '@components/helper'
import cn from 'classnames'

/**
 *
 * @param data: {
    dealsType: '区分活动入口标志',
    subBrandType: '咨询 PM',
    policy: '隐私政策文案', 
    placeholder: '邮箱 placeholder',
    btnText: '订阅按钮文案',
    inputIcon: 'input 前的 Icon', //可选
    nameNeed: '2022 9月新增的 name 字段' // 可选
    track: {AMZPixelView, formSubmit, sku} // 数据层控制的埋点
  }
 * @param s: 需要覆盖的样式
 * @param setModal: {
 *  type: 'success'|'error',
 *  msg: "信息" }
 * @param trace: 组件控制的埋点，会把 dealsType 作为参数传给方法
 * @returns
 */

const Form = ({
  data,
  s,
  setModal,
  trace = () => {},
  mailIcon = null,
  policyUnderInput = false,
  nameNeed = false,
  setEmailFocus = () => {},
  showPolicy = true,
}) => {
  const { locale, query } = useRouter()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const { recaptchaReady } = useUI()

  useEffect(() => {
    if (recaptchaReady) {
      setDisabled(false)
    }
  }, [recaptchaReady])

  useEffect(() => {
    data?.track?.AMZPixelView && appendAMZPixel(data?.track?.AMZPixelView)
  }, [data?.track?.AMZPixelView])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (nameNeed && name.trim() === '') {
      setName(name.trim())
      setModal({
        type: 'error',
        msg: data.nameEmpty,
      })
      return
    }
    const { register_source } = getAdCookie()
    setLoading(true)
    const { errors, message } = await fetcher({
      locale,
      url: '/api/multipass/rainbowbridge/activities',
      method: 'POST',
      action: 'activity',
      needRecaptcha: true,
      body: {
        email,
        name,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: data.dealsType,
        sub_brand_type: data.subBrandType,
      },
    })
    setLoading(false)

    // 组件传入埋点方式
    trace({ dealsType: data.dealsType })

    // 数据层埋点方式
    GtmEvent?.formSubmit(data?.track, data.dealsType, query?.slug)
    data?.track?.AMZPixelSign && appendAMZPixel(data?.track?.AMZPixelSign)
    if (!errors) {
      setModal({ type: 'success' })
      setEmail('')
      setIsChecked(false)
    } else {
      setModal({
        type: 'error',
        msg: data?.errorTxt || parseMessage(message) || errors,
      })
    }
  }
  const Policy = () => {
    if (!showPolicy) return null
    return (
      <div className={cn(s.policyBox, { [s.centerPolicy]: !policyUnderInput })}>
        {/* 此 input 盒子用于兼容 checkbox 在黑暗模式下的颜色 */}
        <div className={cn('inline', s.policyInputWrap)}>
          <input
            required
            aria-required
            id="policy"
            type="checkbox"
            checked={isChecked}
            onChange={() => {
              isChecked ? setIsChecked(false) : setIsChecked(true)
            }}
          />
        </div>
        <label
          htmlFor="policy"
          dangerouslySetInnerHTML={{
            __html: data.policy,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <form className={s.formWrap} onSubmit={handleSubmit}>
        {nameNeed && (
          <input
            value={name}
            required
            aria-required
            onChange={(e) => setName(e.target.value)}
            placeholder={data.namePlaceholder}
            className={s.nameInput}
          />
        )}
        <div className={s.emailBox}>
          {data.inputIcon && <Picture source={data.inputIcon} />}
          <input
            value={email}
            type="email" //type 为 email 的时候输入空格键不监听
            required
            aria-required
            onChange={(e) => setEmail(e.target.value?.trim())}
            placeholder={data.placeholder}
            onFocus={() => setEmailFocus(true)}
          />
          {mailIcon}
          {policyUnderInput && <Policy />}
          <Button
            type="submit"
            variant="plain"
            disabled={disabled}
            loading={loading}
            className="disabled:!cursor-wait"
          >
            {data.btnText}
          </Button>
        </div>
        {!policyUnderInput && <Policy className={s.policy} />}
      </form>
    </>
  )
}

export default Form

const GtmEvent = {
  formSubmit: (track, type, sku) => {
    if (!track?.formSubmit) return false
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'subscribe',
      eventAction: type,
      eventLabel: 'Product Detail Page_' + sku,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'subscribe',
      event_parameters: {
        page_group: 'Product Detail Page_' + sku,
        position: track?.position || '',
      },
    })
    window.fbq && window.fbq('track', 'CompleteRegistration')
  },
}
