import React, { FC, useMemo, useEffect } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import { Text } from '@components/ui'
import { ORIGIN_URL } from '@shopify/const'
import s from './index.module.css'

const yotpoInstanceId = (locale = 'us') =>
  ({
    us: '637042',
    uk: '638344',
    ca: '638345',
  }[locale] || '')

export type ReviewsProps = {
  product: any
  pathUrl: string
  data: any
  shopData: any
}

/**
 * 添加评论信息
 * props: product
 * 需要对product.id进行base64处理
 */
const Reviews: FC<ReviewsProps> = ({ product, data, shopData }) => {
  const { locale = 'us' } = useRouter()
  const dataProductId = useMemo(() => atobID(product?.id), [product?.id])
  const priceObj = product?.price
  if (data?.preRender?.hideReviews) return null
  const title = shopData?.judgemeSettings?.showJugeme
    ? shopData?.judgemeSettings?.title
    : shopData?.reviewsSettings?.title

  const reviews = shopData?.judgemeSettings?.showJugeme ? (
    <div className={s.starRatingWrap} id="reviews-jdgm">
      <div
        className="jdgm-widget jdgm-review-widget jdgm-outside-widget"
        data-id={dataProductId}
        data-product-title={product.name}
      ></div>
    </div>
  ) : (
    <div
      id="reviews-yotpo"
      data-yotpo-instance-id={yotpoInstanceId(locale)}
      className={cn(s.mainReviews, 'yotpo-widget-instance')}
      data-yotpo-product-id={dataProductId}
      data-yotpo-price={priceObj?.value}
      data-yotpo-currency={priceObj?.currencyCode}
      data-yotpo-name={product?.name}
      data-yotpo-url={`${ORIGIN_URL}${
        locale === 'us' ? '' : '/' + locale
      }/products/${product?.slug}`}
      data-yotpo-image-url={product?.images?.[0]?.url}
      data-yotpo-description={product?.description}
    />
  )
  return (
    <>
      {title && (
        <Text className={s.reviewTitle} html={title} variant="paragraph"></Text>
      )}
      {reviews}
    </>
  )
}

export default Reviews
