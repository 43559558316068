import s from './index.module.css'
import { Picture, Text } from '@components/ui'
import cn from 'classnames'
import { useState, useEffect, useMemo, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import useCoupons from '@lib/hooks/useCoupon'
import useProduct from '@shopify/product/use-product'
import Decimal from 'decimal.js'
import { normalizeStorefrontProduct } from '@components/normalize'
import usePrice, { formatPrice } from '@commerce/product/use-price'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import VoltaicCard from '@components/common/VoltaicProduct/components/VoltaicCard'
import normalizeSkuData from '@components/common/VoltaicProduct/components/normalizeSkuData'
import { useTimer } from 'react-timer-hook'
import { useBuyNowGift } from 'deals/utils'

import {
  EffectFade,
  Controller,
  Thumbs,
  Navigation,
  FreeMode,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/scrollbar'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const CountDown = ({ countDown }) => {
  const { isDark, unit, boundaryDayNums, onlyNumber, showCountdown } =
    countDown || {}
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(countDown?.endTime),
  })
  if (new Date() > new Date(countDown?.endTime) || !showCountdown) return null

  const n = (n) => (n > 9 ? '' + n : '0' + n)

  const Item = ({ time, last, isDark, unit }) => (
    <div className="flex items-center">
      <div className="flex flex-col items-center">
        <div
          className="relative flex w-[44px] flex-col items-center justify-center overflow-hidden md:w-10"
          style={{
            // background: `${isDark ? '#fff' : '#1D1D1F'}`,
            color: `${isDark ? 'rgba(0,169,224,1)' : '#1D1D1F'}`,
          }}
        >
          <span className="text-[36px] font-[600] leading-none md:text-[32px]">
            {n(time)}
          </span>
          <Text
            html={unit}
            className="text-[10px] font-[500] text-[rgba(0,169,224,1)]"
            variant="inline"
          />
        </div>
      </div>
      {!last && (
        <span
          className={cn('mx-4 text-[32px] font-[600] md:text-[28px]')}
          style={{
            color: `${isDark ? '#C3C3C3' : '#1D1D1F'}`,
          }}
        >
          :
        </span>
      )}
    </div>
  )

  return (
    <div className={cn('CountDown', s.countDownBox)}>
      <Text
        html={countDown?.prefix}
        className={s.prefix}
        variant="paragraph"
        style={{
          color: `${isDark ? 'rgba(0,169,224,1)' : '#1D1D1F'}`,
        }}
      />
      {onlyNumber && days < boundaryDayNums ? (
        <>
          <div className="CountDown-box flex items-center justify-center">
            <Item
              time={String(24 * days + hours).padStart(2, '0')}
              isDark={isDark}
              unit={unit?.h || 'Stunden'}
            />
            <Item time={minutes} isDark={isDark} unit={unit?.m || 'Minuten'} />
            <Item
              time={seconds}
              last={true}
              isDark={isDark}
              unit={unit?.s || 'Sekunden'}
            />
          </div>
        </>
      ) : (
        <div className="CountDown-box flex items-center justify-center">
          {days > 0 && (
            <Item time={days} isDark={isDark} unit={unit?.d || 'Tagen'} />
          )}
          <Item time={hours} isDark={isDark} unit={unit?.h || 'Stunden'} />
          <Item time={minutes} isDark={isDark} unit={unit?.m || 'Minuten'} />
          <Item
            time={seconds}
            last={true}
            isDark={isDark}
            unit={unit?.s || 'Sekunden'}
          />
        </div>
      )}
    </div>
  )
}

const SwiperWithCldSwiper = ({
  id,
  data,
  pageHandle,
  shopCommon,
  metafields,
  relatedProducts,
}) => {
  const combineoptions = metafields?.combineoptions
  const relatedCoupons = metafields?.relatedCoupons
  const combineoptions_commoninfo = metafields?.combineoptions_commoninfo
  const { summerCampaignDiscount } = metafields
  const motionConf = {
    initial: 'hidden',
    whileInView: 'show',
    variants: {
      show: {
        transform: 'translateY(0)',
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      },
      hidden: {
        transform: 'translateY(20vh)',
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      },
    },
    viewport: { once: true },
  }
  const { locale } = useRouter()
  const [idx, setIdx] = useState(0)
  const swiperRef = useRef(null)
  const [swiper, setSwiper] = useState(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  // const [textSwiper, setTextSwiper] = useState(null)
  const { setRelativeLink } = useRelativeLink()

  let products = useMemo(() => {
    return (
      data?.list
        ?.map((item) => {
          return [...(item?.list || []), ...(item?.list_2nd || [])]
        })
        ?.flat() || []
    )

    return [].concat(
      data?.list?.[idx]?.list || [],
      data?.list?.[idx]?.list_2nd || []
    )
  }, [data, idx])

  const { data: result, isLoading } = useProduct({
    handles: products?.map((item) => item.handle)?.join(','),
  })
  const skus = products?.map((item) => item.sku)
  const { coupons } = useCoupons(skus)
  const buyNowGiftFunc = useBuyNowGift({
    summerCampaignDiscount: summerCampaignDiscount,
  })
  return (
    <motion.div className="relative md:mx-6" {...motionConf}>
      <div className="content overflow-hidden text-[#1D1D1F]">
        {data?.title && (
          <Text
            html={data?.title}
            variant="sectionHeading"
            className={cn(
              'mb-10 text-[56px] font-bold leading-[1.15] text-[#1D1D1F] md:mb-6 md:text-[30px] md:leading-[1.26]'
            )}
          />
        )}
        <CountDown {...data} />
        {/* controller */}
        <div className={cn('w-full', { hidden: data?.hiddenTab })}>
          {data.list?.length > 1 && (
            <Swiper
              loop={false}
              modules={[EffectFade, Thumbs]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              wrapperClass={cn(s.thumbsSwiperWrapper, {
                '!translate-x-0': idx === 0,
              })}
              slideToClickedSlide={false}
              centeredSlides={true}
              centeredSlidesBounds={true}
              breakpoints={{
                0: {
                  slidesPerView: 'auto',
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 'auto',
                  spaceBetween: 72,
                },
                1023: {
                  slidesPerView: 'auto',
                  spaceBetween: 72,
                },
              }}
            >
              {data.list.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="!w-auto"
                  onClick={() => {
                    setIdx(index)
                    thumbsSwiper?.slideTo(index)
                    swiper?.slideTo(index)
                  }}
                >
                  <div
                    className={cn(s.textSlideContent, {
                      [s.activeSlide]: index === idx,
                    })}
                  >
                    <Text className={s.slideTitle} html={item.title}></Text>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        {/* Swiper */}
        <Swiper
          ref={swiperRef}
          loop={true}
          slidesPerView={'auto'}
          modules={[EffectFade, Controller, Thumbs]}
          // thumbs={{ swiper: thumbsSwiper }}
          // controller={{ control: textSwiper }}
          effect="fade"
          onSwiper={(swiper) => {
            setSwiper(swiper)
          }}
          wrapperClass={s.centerSwiperWrapper}
          allowTouchMove={false}
          className="!overflow-visible md:rounded-md"
        >
          {data.list?.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className={cn(
                  'topBox relative mb-6 grid grid-cols-2 justify-items-end bg-[#fff] l:mb-3 l:grid-cols-1 l:bg-white',
                  {
                    hidden: item?.hiddeBanner,
                  }
                )}
              >
                <div
                  className={
                    'box-border flex h-full w-full flex-col items-start justify-center overflow-hidden pl-[calc(80/1920*100vw)] pr-[calc(210/1920*100vw)] md:w-full md:flex-row md:items-center md:justify-between md:px-6 md:py-[60px] md:pr-[calc(210/1920*100vw)] l:p-[27px] l:text-center min-xxl:pl-20 min-xxl:pr-[67px]'
                  }
                >
                  <div className={s.bannerText}>
                    <Text
                      html={item?.title}
                      variant="sectionSubHeading"
                      className={cn(
                        'mb-6 text-left text-[40px] font-bold leading-[1.2] text-[#1D1D1F] md:mb-4 md:text-center l:mb-2.5 l:text-[20px]'
                      )}
                    ></Text>
                    <Text
                      html={item?.desc}
                      className={cn(
                        'text-left text-[17px] font-[500] leading-[1.2] text-[#6E6E73] md:text-center md:text-[12px] md:font-bold '
                      )}
                    ></Text>
                    {item?.btn?.text && (
                      <a
                        dangerouslySetInnerHTML={{ __html: item?.btn?.text }}
                        href={setRelativeLink({
                          link: item?.href,
                        })}
                        target={item?.target}
                        className="mt-8 inline-block cursor-pointer text-[17px] font-[500] leading-[1.5] text-[#00A9E0] hover:underline l:mt-4"
                      ></a>
                    )}
                  </div>
                </div>
                <div className="h-full w-full">
                  {data?.video ? (
                    <>
                      <video
                        poster={data?.img_mob}
                        preload="auto"
                        playsInline
                        muted
                        autoPlay
                        src={data?.video_mob}
                        className="hidden md:block"
                        loop
                      ></video>
                      <video
                        poster={data?.img}
                        preload="auto"
                        playsInline
                        muted
                        autoPlay
                        src={data?.video}
                        className="md:hidden"
                        loop
                      ></video>
                    </>
                  ) : (
                    <Picture
                      source={`
                        ${item?.img}, 
                        ${item?.img_mob || item?.img} 768,
                      `}
                      className="aspect-h-[320] aspect-w-[640] md:aspect-h-[171] md:aspect-w-[342]"
                      alt={item?.alt || item?.title || ''}
                      loading="eager"
                    />
                  )}
                </div>
              </div>

              <CardSwiperComp
                {...data}
                result={result}
                id={id}
                index={index}
                coupons={coupons}
                locale={locale}
                name="list"
                data={item?.list || []}
                pageHandle={pageHandle}
                shopCommon={shopCommon}
                urlRef={data?.urlRef}
                sectionData={data}
                relatedProducts={relatedProducts}
                relatedCoupons={relatedCoupons}
                combineoptions={combineoptions}
                buyNowGiftFunc={buyNowGiftFunc}
              />
              <CardSwiperComp
                {...data}
                result={result}
                id={id}
                index={index}
                coupons={coupons}
                locale={locale}
                name="list_2nd"
                data={item?.list_2nd || []}
                pageHandle={item?.urlRef || pageHandle}
                shopCommon={shopCommon}
                urlRef={data?.urlRef}
                sectionData={data}
                relatedProducts={relatedProducts}
                relatedCoupons={relatedCoupons}
                combineoptions={combineoptions}
                buyNowGiftFunc={buyNowGiftFunc}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <style>
        {`
          .swiper-scrollbar-drag{
            background: #00a9e0 !important;
          }
          .${s.thumbsSwiperWrapper} .swiper-slide:last-child {
            margin-left: -1px;
          }
          .${s.centerSwiperWrapper} .swiper-slide {
            opacity: 0!important;
          }
          .${s.centerSwiperWrapper} .swiper-slide.swiper-slide-visible {
            opacity: 1!important;
          }
          .${s.centerSwiperWrapper} .swiper-slide-active {
            z-index: 10
          }
          .${s.cardSwiperWrapper} .swiper-slide {
            pointer-events: auto!important;
          }
          .${s.bottomSwiperWrapper} .swiper-slide {
            opacity: 1!important;
          }
        `}
      </style>
    </motion.div>
  )
}

export default SwiperWithCldSwiper

function NextArrow({ gs = {} }) {
  return (
    <div
      className={cn(
        'flex h-full w-full items-center justify-center rounded-[100%]',
        gs.className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={gs?.next}
      >
        <path
          d="M9 5L16 12L9 19"
          stroke="#1D1D1F"
          strokeWidth="2.5"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

const CardSwiperComp = ({
  id,
  name,
  data,
  index,
  result,
  coupons,
  locale,
  pageHandle,
  shopCommon,
  sectionData,
  relatedProducts,
  relatedCoupons,
  combineoptions,
  buyNowGiftFunc,
}) => {
  if (!data?.length) return
  const settings = {
    loop: false,
    speed: 400,
    spaceBetween: '1.5%',
    modules: [Navigation, Pagination, Autoplay, Scrollbar],
    breakpoints: {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  }
  return (
    <div className={s.cardSwiperWrapper}>
      <Swiper
        {...settings}
        key="cardCldSwiper"
        navigation={{
          nextEl: `#${id}_${name}_cus_nav_next_${index}`,
          prevEl: `#${id}_${name}_cus_nav_prev_${index}`,
          disabledClass: s.swiper_btn_disabled,
        }}
        pagination={{
          el: `#${id}_${name}_cusSwiperPag_${index}`,
          type: 'bullets',
          bulletElement: 'li',
          clickable: true,
        }}
        scrollbar={{
          el: `#${id}_${name}_cusSwiperScroller_${index}`,
          draggable: true,
          dragSize: 30,
          // dragSize: 'auto',
        }}
        wrapperClass={s.bottomSwiperWrapper}
        className="l:!overflow-visible"
      >
        {data?.map((item, index) => {
          const _data = normalizeSkuData(item, result, coupons, data)
          const curProduct =
            result?.products?.filter(
              (jItem) => jItem?.handle === item?.handle
            )?.[0] || {}

          const _price = formatPrice({
            amount: Number(_data?.price || 0),
            currencyCode: _data?.currencyCode || 'USD',
            locale,
            maximumFractionDigits: 0,
          })
          const _basePrice = _data?.basePrice
            ? formatPrice({
                amount: Number(_data?.basePrice || 0),
                currencyCode: _data?.currencyCode || 'USD',
                locale,
                maximumFractionDigits: 0,
              })
            : null

          const _discountVal = _data?.discountValue
            ? ['fixed_amount', 'fix_discount_amount', 'fix_amount'].includes(
                _data?.discountType
              )
              ? (locale == 'fr' ? '- ' : '') +
                formatPrice({
                  amount: parseInt(_data?.discountValue),
                  locale,
                  currencyCode: _data?.currencyCode,
                  maximumFractionDigits: 0,
                })
              : (locale == 'fr' ? '- ' : '') +
                parseInt(_data?.discountValue) +
                '%'
            : null

          const _variant_url =
            item?.customerLink ||
            `${locale === 'us' ? '' : '/' + locale}/products/${
              _data?.handle
            }?variant=${atobID(_data?.variantId)}&ref=${
              _data?.ref || pageHandle
            }`

          return (
            <SwiperSlide key={item?.title} className={cn('!h-auto text-left')}>
              <VoltaicCard
                item={item}
                variant_url={_variant_url}
                data={_data}
                discountVal={_discountVal}
                shopCommon={shopCommon}
                price={_price}
                basePrice={_basePrice}
                sectionData={sectionData}
                pageHandle={pageHandle}
                relatedProducts={relatedProducts}
                relatedCoupons={relatedCoupons}
                combineoptions={combineoptions}
                buyNowGiftFunc={buyNowGiftFunc}
                curProduct={curProduct}
              />
            </SwiperSlide>
          )
        })}
        {/* Navigation */}
        <div
          id={`${id}_${name}_cus_nav_prev_${index}`}
          className={cn(
            'swiper-button-prev swiper-button-prev_reviews !left-0 md:!hidden',
            s.navigationBtn,
            {
              '!hidden': data?.list?.length < 2,
            }
          )}
        >
          <NextArrow gs={{ className: 'rotate-[180deg]' }} />
        </div>
        <div
          id={`${id}_${name}_cus_nav_next_${index}`}
          className={cn(
            'swiper-button-next swiper-button-next_reviews !right-0 md:!hidden',
            s.navigationBtn,
            {
              '!hidden': data?.list?.length < 2,
            }
          )}
        >
          <NextArrow />
        </div>
      </Swiper>
      {/* Pagination */}
      <div className={cn(s.cusPagination, 'hidden')}>
        <div
          id={`${id}_${name}_cusSwiperPag_${index}`}
          className={s.customerSwiperPagination}
        ></div>
      </div>

      {/* Scrollbar */}
      <div
        className={cn(
          'mx-auto mb-0 mt-6 h-0.5 !w-[120px] bg-[#D9D9D9] text-center md:!w-[72px]',
          `${id}_${name}_cusSwiperScroller_${index}`
        )}
        id={`${id}_${name}_cusSwiperScroller_${index}`}
      ></div>
    </div>
  )
}
