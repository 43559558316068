/* eslint-disable react-hooks/rules-of-hooks */
import { Picture, Text } from '@components/ui'
import { useRef, useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import s from './CollectionsSwiper.module.css'
import cn from 'classnames'
import Tabs from './Tabs'
import MyStyles from './SlickStyles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
// import { useInView } from 'react-intersection-observer'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { atobID } from '@shopify/utils'
import { formatPrice } from '@commerce/product/use-price'
import getProductCouponsBySkus from '@lib/apis/get-product-coupons-by-skus'
// import { useUI } from '@components/ui'

const Collections = ({ data, relatedProducts, metafields, customTrace }) => {
  const trace = customTrace || defaultTrace
  const { discounts } = metafields
  // const times = useRef(0)
  // const { ref, inView } = useInView({ threshold: 0 })
  // const inViewItems = useRef({})
  // console.log('result data', relatedProducts, data)
  if (!relatedProducts || !data) return null

  const [activeTab, setActiveTab] = useState(0)
  const [swipers, setSwipers] = useState([])
  const [bullets, setBullets] = useState()
  const [coupons, setCoupons] = useState({})
  // const { recaptchaReady } = useUI()
  const { locale } = useRouter()

  const couponCallback = useCallback(async () => {
    const skus = data.tabs
      .reduce(
        (prev, cur) => [
          ...prev,
          ...cur.products.reduce((prev, cur) => [...prev, cur.sku], []),
        ],
        []
      )
      .filter((sku) => sku)
      .map((sku) => sku.toUpperCase())

    const result = await getProductCouponsBySkus(locale, skus)

    setCoupons(result)
  }, [])

  useEffect(() => {
    couponCallback()
  }, [couponCallback])

  // console.log('activeTab', activeTab)
  const getResult = (data) => {
    const product = relatedProducts.find(
      (p) => data?.handle?.toLowerCase() === p?.result?.handle?.toLowerCase()
    )?.result
    // console.log('variants', product?.variants)

    const variant = product?.variants?.find(
      (v) => v?.sku?.toLowerCase() === data?.sku?.toLowerCase()
    )
    return { product, variant }
  }

  // const viewTrace = () => {
  //   setTimeout(() => {
  //     // product view 的埋点运算
  //     // 所有的 slide
  //     const slides = document
  //       .querySelector(`.${s.active}`)
  //       ?.querySelectorAll('.swiper-slide')
  //     // 在视线内的第一个为 active
  //     const activeSlider = document
  //       .querySelector(`.${s.active}`)
  //       ?.querySelector('.swiper-slide-active')
  //     // console.log('slides', slides)
  //     slides?.forEach((slide, activeIndex) => {
  //       // console.log('class', slide.getAttribute('class'))
  //       if (slide === activeSlider) {
  //         // console.log('once')
  //         // 找到 active 的后四个
  //         Array(window.innerWidth > 768 ? 4 : 3)
  //           .fill('')
  //           .map((_, i) => {
  //             const index = slides[i + activeIndex]?.getAttribute(
  //               'data-swiper-slide-index'
  //             )
  //             const { tab, products } = data.tabs?.[activeTab] || {}
  //             // console.log('swiper', swiper.el?.getAttribute('id'))
  //             const { product, variant } = getResult(products[index])
  //             if (!inViewItems.current?.[variant?.sku]) {
  //               trace?.productView({
  //                 product,
  //                 tab,
  //                 variant,
  //                 index: index,
  //               })
  //             }
  //             // console.log('index product variant', index, product, variant)
  //             inViewItems.current = {
  //               ...inViewItems.current,
  //               [variant?.sku]: inView,
  //             }
  //           })
  //         return
  //       }
  //     }, 500)
  //   })
  // }

  // // console.log('inView', inView)
  // useEffect(() => {
  //   viewTrace()
  // })

  const settings = (tab, i) => ({
    id: i,
    slidesPerView: 'auto',
    spaceBetween: 10,
    loopFillGroupWithBlank: false,
    loop: true,
    speed: 25000,
    onInit: function (swiper) {
      // console.log('onInit', swiper)
      setSwipers((prev) => [...prev, swiper])
    },
    onTouchStart: function () {
      // console.log('onTouchStart')
      // 用户滑动时两个加速并停止
      swipers?.map((s) => {
        s?.autoplay?.stop()
        s.params.speed = 100
      })
    },
    // onSlideChangeTransitionEnd: () => {
    //   // 首次渲染也会触发
    //   times.current = times.current + 1
    //   if (times.current > 1 && inView) {
    //     if (window.screen.width < 769) {
    //       // 移动端的自动轮播
    //       if (times.current % 2 === 0) {
    //         // console.log('onSlideChange')
    //         viewTrace()
    //       }
    //     } else {
    //       viewTrace()
    //     }
    //   }
    // },
    freeMode: true,
    autoplay: {
      enabled: true,
      delay: 1,
      disableOnInteraction: true,
    },
    waitForTransition: false,
    pauseOnMouseEnter: true,
    navigation: tab.products.length > 4,
    pagination: tab.products.length > 3 ? {} : false,
    onPaginationUpdate: (swiper, paginationEl) => {
      setBullets(paginationEl.innerHTML)
      // console.log(paginationEl.innerHTML)
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        autoplay: false,
        speed: 50,
        pagination: tab.products.length > 4 ? {} : false,
      },
      0: {
        slidesPerView: 2.2,
        slidesPerGroup: 2,
      },
    },
    modules: [Autoplay, Pagination, Navigation],
  })
  return (
    <div className={s.root}>
      <div className={s.collections}>
        <Text variant="sectionHeading" className={s.title} html={data.title} />
        <Tabs
          activeTab={activeTab}
          onChange={(e, value) => {
            setActiveTab(value)
            trace?.tabClick(value)
            // console.log('tabClick')
            // inView 会监听
            // viewTrace()
          }}
          tabs={data.tabs.map(({ tab }) => tab)}
        />
        <div className={s.container}>
          <MyStyles
            s={s}
            slidesToScroll={3}
            length={data.tabs[activeTab]?.products?.length}
          />
          {data.tabs.map((tab, i) => {
            const setting = settings(tab, i)
            return (
              <div
                key={tab.tab}
                className={cn(s.sliderContainer, {
                  [s.active]: i === activeTab,
                })}
              >
                <Swiper {...setting}>
                  {tab.products.map((product, index) => {
                    return (
                      <SwiperSlide key={product.handle + index}>
                        <Card
                          discounts={discounts}
                          origin={product}
                          data={data}
                          getResult={getResult}
                          trace={trace}
                          tabValue={data.tabs[activeTab]?.tab}
                          index={index}
                          coupons={coupons}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                {/* safari 移动端整体圆角需要，重新放置 pagination 的位置，填充了原先的 pagination 内容，并隐藏原先 pagination*/}
                {tab.products.length > 3 && (
                  <div
                    className="my-pagination only-in-mobile"
                    dangerouslySetInnerHTML={{ __html: bullets }}
                  ></div>
                )}
                {tab.products.length > 4 && (
                  <div
                    className="my-pagination only-in-pc"
                    dangerouslySetInnerHTML={{ __html: bullets }}
                  ></div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <style jsx global>{`
        .${s.root} .swiper-button-prev {
          color: #fff;
        }
        .${s.root} .swiper-button-next {
          color: #fff;
        }
      `}</style>
    </div>
  )
}

export default Collections

const Card = ({
  origin,
  data,
  trace,
  tabValue,
  index,
  getResult,
  discounts,
  coupons,
}) => {
  const { product, variant } = getResult(origin)
  const { locale } = useRouter()
  const handleTrace = (e) => {
    // e.preventDefault()
    // console.log('e', e.currentTarget)
    // 无奈之举... loop function 只会复制 dom, js 的变量并不会随之复制，导致变量错乱的问题
    // https://stackoverflow.com/questions/50455910/idangero-us-swiper-loop-bug-with-previous-navigation
    const d = e.currentTarget?.getAttribute('id')?.split('-')
    // console.log('d', d)
    const handle = d?.[0]
    const sku = d?.[1]
    const i = d?.[2]
    const { product, variant } = getResult({ handle, sku })
    trace?.productClick({
      product,
      variant,
      tab: tabValue,
      index: i,
    })
  }
  // console.log('product, variant:', product, variant)
  // console.log(
  //   'handle sku, img',
  //   product?.handle,
  //   variant?.sku,
  //   origin.productImg || variant?.image?.url
  // )
  const firstToUpper = (str) => {
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
  }
  if (!product && !variant) return null
  let link = `${locale === 'us' ? '' : '/' + locale}/products/${
    product.handle
  }?variant=${atobID(variant?.id)}&ref=${trace?.ref}`
  // console.log('handle', origin.handle)
  // console.log('btn to', origin.btnText, origin.to)
  if (origin.to !== undefined) {
    link = origin.to
  }
  // 原价
  const originPrice = formatPrice({
    locale,
    amount: variant?.price,
    currencyCode: product.price.currencyCode,
  })
  const couponKey = Object.keys(coupons || {}).find(
    (sku) => sku === variant?.sku?.toUpperCase()
  )
  // 折后价
  let price = null
  if (coupons[couponKey]?.[0]?.variant_price4wscode) {
    price = formatPrice({
      locale,
      amount: coupons[couponKey]?.[0]?.variant_price4wscode,
      currencyCode: product.price.currencyCode,
    })
  }
  if (!product.availableForSale) {
    price = ''
  } else {
    price = price !== null ? price : originPrice
  }

  // console.log('discount:', couponKey)
  // console.log('variant?.sku?.toUpperCase():', variant?.sku?.toUpperCase())
  return (
    <a
      className="relative flex flex-1 flex-col justify-between overflow-hidden rounded-lg bg-white p-4 pb-8 md:mx-0 md:rounded-xl md:px-2 md:pb-4"
      href={link}
      onClick={handleTrace}
      id={origin.handle + '-' + origin.sku + '-' + index}
    >
      {coupons[couponKey] && (
        <div
          className="absolute top-[0] left-[-0.2rem] z-[1] w-[60px] text-center md:flex md:w-[46px] md:flex-col md:items-end"
          key={index}
        >
          <Picture
            quality={2}
            className="w-full"
            source={`${'https://cdn.shopify.com/s/files/1/0493/9834/9974/files/promotion_badge_anker.svg?v=1628218118%E2%81%A3_2160x'}`}
          />
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
            <p className="text-[1rem] font-bold leading-[1.5rem] text-[#fff] md:text-[14px] md:leading-[1rem]">
              {coupons[couponKey]?.[0]?.value_type === 'percentage'
                ? Math.abs(parseInt(coupons[couponKey]?.[0]?.value)) + '%'
                : coupons[couponKey]?.[0]?.value_style}
              <br />
              {firstToUpper(discounts?.off)}
            </p>
          </div>
        </div>
      )}
      <Picture
        className="h-[200px] flex-shrink-0 md:h-[70px]"
        source={origin.productImg || variant?.image?.url}
        imgClassName="object-scale-down h-full"
      />
      <div className="flex flex-1 flex-col items-center justify-between pt-2 text-center">
        <Text
          variant="paragraph"
          html={origin.productName || product.name}
          className="text-[20px] line-clamp-2 md:text-sm md:line-clamp-2"
        />
        <Text
          variant="paragraph"
          html={origin.installment}
          className="pt-1 text-[#686868] md:text-xs"
        />
        <div className="flex items-center pb-1">
          <Text
            variant="paragraph"
            html={price}
            className="mr-1 min-h-[28px] text-xl text-anker-color md:min-h-[20px] md:text-sm"
          />
          {price !== originPrice && product.availableForSale && (
            <Text
              variant="paragraph"
              html={originPrice}
              className=" text-[#686868] line-through md:text-xs"
            />
          )}
        </div>
        <Text
          variant="paragraph"
          html={origin.btnText || data.btnText}
          className="rounded-3xl border border-black px-10 py-1 hover:border-none hover:bg-anker-color hover:text-white md:px-6"
        />
        {/* <Text
          variant="paragraph"
          html={origin.btnText || data.btnText}
          className="only-in-mobile text-anker-color font-semibold"
        /> */}
      </div>
    </a>
  )
}

const defaultTrace = {
  tabClick: (tab) => {
    pageGTMEvent({
      event: 'uaEvent', // Trigger
      eventCategory: 'a1780',
      eventAction: 'switch',
      eventLabel: 'explore' + tab,
      nonInteraction: true,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'switch',
      event_parameters: {
        page_group: 'a1780',
        position: 'collection_explore',
        navigation: tab, //取导航栏文字
      },
    })
  },
  productClick: ({ product, variant, tab, index }) => {
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'a1780',
      eventAction: 'collection_explore_img',
      eventLabel: variant?.sku, //取跳转产品SKU
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item', //点击产品图片时触发该事件
      event_parameters: {
        page_group: 'a1780',
        action: 'Img_' + product?.name, //产品名
        position: 'collection_explore',
        currency: product?.price?.currencyCode,
        items: [
          getItem({
            product,
            variant,
            title: 'explore powerhouse_' + tab,
            index,
          }),
        ],
      },
    })
  },
  productView: ({ tab, product, variant, index }) => {
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'view_item_list', //产品露出时触发该事件
      event_parameters: {
        page_group: 'a1780',
        position: 'collection_explore',
        currency: product?.price?.currencyCode,
        items: [
          getItem({
            product,
            variant,
            title: 'explore powerhouse_' + tab,
            index,
          }),
        ],
      },
    })
  },
  ref: 'a1780_landing',
}

const getItem = ({ product, variant, title, index }) => ({
  item_id: variant?.sku, //sku
  item_name: product?.name, //产品名
  item_brand: product?.vendor,
  item_category: product?.productType,
  item_variant: variant?.name,
  price: variant?.price / 100,
  index: index,
  item_list_id: '',
  item_list_name: title,
})
