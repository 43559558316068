// start_ai_generated
import useProduct from '@shopify/product/use-product'
import { normalizeStorefrontProduct } from '@components/normalize'
import { useCallback } from 'react'
import Decimal from 'decimal.js'
import * as dayjs from 'dayjs'

// 使用购买礼品的钩子
const useBuyNowGift = ({ summerCampaignDiscount }) => {
  if (!summerCampaignDiscount) {
    return () => []
  }

  const giftHandles =
    summerCampaignDiscount.breakpoints?.map(
      (item) => item.giveawayProducts?.[0]?.handle
    ) || []
  const { data: giftResult, isLoading } = useProduct({
    handles: giftHandles.join(','),
  })

  return useCallback(
    ({ product }) => {
      if (
        !product?.tags?.some((tag) =>
          summerCampaignDiscount?.includeTags?.includes(tag)
        )
      ) {
        return []
      }

      const involvedSubTotal = new Decimal(product?.price || 0)
      if (summerCampaignDiscount.useTotalAmount) {
        involvedSubTotal.plus(
          new Decimal(product?.originPrice || product?.basePrice || 0)
        )
      }

      const sortedLevel = summerCampaignDiscount.breakpoints.sort(
        (a, b) => b.breakpoint - a.breakpoint
      )
      const levelIndex = sortedLevel.findIndex((discount) =>
        involvedSubTotal.greaterThanOrEqualTo(discount.breakpoint)
      )

      if (levelIndex === -1) {
        return []
      }

      const currentLevel = sortedLevel[levelIndex]
      const giftProduct = normalizeStorefrontProduct(
        currentLevel.giveawayProducts[0],
        giftResult
      )

      return giftProduct
        ? [
            {
              variantId: String(giftProduct.variantId),
              quantity: giftProduct.quantity || 1,
              customAttributes: [{ key: '_giveaway', value: '_giveaway' }],
            },
          ]
        : []
    },
    [giftResult, summerCampaignDiscount]
  )
}

const downloadICSFile = ({ list, fileName }) => {
  try {
    let newDate = dayjs(new Date()).valueOf()
    let content = ''
    list.forEach((item) => {
      if (dayjs(item.endTime).valueOf() > newDate) {
        content += `
BEGIN:VEVENT
UID:${Math.random()}@mycalendar.com
DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${new Date(item.startTime).toISOString().replace(/-|:|\.\d+/g, '')}
DTEND:${new Date(item.endTime).toISOString().replace(/-|:|\.\d+/g, '')}
SUMMARY:${item.calendarTitle}
DESCRIPTION:${item.calendarDescribe}
LOCATION:${item.location}
END:VEVENT`
      }
    })
    if (!content) return null
    let icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//My Calendar//EN
CALSCALE:GREGORIAN
METHOD:PUBLISH
X-WR-CALNAME:My Calendar
${content}
END:VCALENDAR`
    const blob = new Blob([icsContent], { type: 'text/calendar' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `${fileName || 'Tune in to Anker‘s Live Stream'}.ics`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (error) {}
}

/**
 * 平滑滚动到指定元素的目标位置
 * @param {HTMLElement} element - 需要进行滚动的元素
 * @param {number} target - 目标滚动位置
 * @param {number} duration - 滚动动画持续时间
 */

function smoothScrollTo(element, target, duration) {
  const start = element.scrollTop
  const change = target - start
  const startTime = performance.now()

  function animateScroll(currentTime) {
    const timeElapsed = currentTime - startTime
    const progress = Math.min(timeElapsed / duration, 1)
    const easeInOutQuad =
      progress < 0.5
        ? 2 * progress * progress
        : -1 + (4 - 2 * progress) * progress

    element.scrollTop = start + change * easeInOutQuad

    if (timeElapsed < duration) {
      requestAnimationFrame(animateScroll)
    }
  }

  requestAnimationFrame(animateScroll)
}

export { useBuyNowGift, downloadICSFile, smoothScrollTo }
// end_ai_generated
