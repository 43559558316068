/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import s from './TextsLayout.module.css'
import { v4 as uuidv4 } from 'uuid'

const defaultGridStyle = { rowStart: 1, rowEnd: 13, colStart: 1, colEnd: 13 }

// pure component, 只为文本位置服务
const TextsLayout = ({
  id = '',
  className = '',
  position: originPosition = 'hover',
  gridStyle = defaultGridStyle,
  children = null,
  responsive = [],
  style = null,
  align = 'center',
  justify = 'top',
  maxWidth = 1200,
  putTextClassName,
  ...props
}) => {
  const { rowStart, rowEnd, colStart, colEnd } = gridStyle || {}
  const cssId = id ? id : uuidv4().slice(0, 5)
  // console.log('align justify', align, justify)

  const css = {
    hover: `
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    `,
    below: `
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; 
    `,
    above: `
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;  
    `,
  }

  return (
    <>
      <style suppressHydrationWarning={true}>
        {`
        .texts-layout-${cssId} .put-text {
          grid-row: ${rowStart || 1} / ${rowEnd || 13};
          grid-column: ${colStart || 1} / ${colEnd || 13};
        }
        .texts-layout-${cssId}.hover {
          ${css.hover}
        }
        .texts-layout-${cssId}.below,
        .texts-layout-${cssId}.above
         {
          ${css.below}
        }
        ${responsive
          ?.map((point) => {
            const { gridStyle, position, justify, align } = point.settings || {}
            const { rowStart, colStart, colEnd } = gridStyle || {}
            return `
                @media (max-width: ${point.breakpoint}px) {
                  .texts-layout-${cssId} .put-text {
                    grid-row: ${rowStart || 1} / ${rowEnd || 13};
                    grid-column: ${colStart || 1} / ${colEnd || 13};
                    justify-content: ${mapping[justify || 'top']};
                    align-items: ${mapping[align || 'left']};
                  }
                  ${
                    position
                      ? `.texts-layout-${cssId}.${originPosition} {
                            ${css[position]}
                          }`
                      : ''
                  }
                  ${
                    position
                      ? `.texts-layout-${cssId}.${originPosition} .${s.textPosition} {
                            display: flex;
                          }`
                      : ''
                  }
                }
                `
          })
          .join(' ')}
      `
          .replace(/\s+/g, ' ')
          .replace(/\n/g, ' ')}
      </style>
      <div
        suppressHydrationWarning={true}
        id={id}
        className={cn(
          `texts-layout-${cssId}`,
          {
            hover: originPosition === 'hover',
            below: originPosition === 'below',
            above: originPosition === 'above',
          },
          className
        )}
        style={style}
        {...props}
      >
        <div
          className={cn(s.textPosition)}
          style={{ maxWidth: maxWidth + 20 + 'px' }}
        >
          {/* grid 所需的布局 */}
          <div
            className={cn('put-text flex flex-col', putTextClassName, {
              ['items-start text-start md:items-center md:text-center']:
                align === 'left',
              ['items-center text-center']: align === 'center',
              ['items-end']: align === 'right',
              ['justify-start']: justify === 'top',
              ['justify-center']: justify === 'center',
              ['justify-end']: justify === 'bottom',
            })}
            // todo 写成 mapping 关系
          >
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default TextsLayout

const mapping = {
  top: 'start',
  bottom: 'end',
  center: 'center',
  left: 'start',
  right: 'end',
}
