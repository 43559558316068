/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { useState } from 'react'
import Image from 'next/image'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Picture, Text } from '@components/ui'

import s from './Multihouse.module.css'

const Multihouse = ({ data, copywriting, trace = () => {} }) => {
  if (!data) return null

  return (
    <div className={s.root}>
      <p className={s.title} dangerouslySetInnerHTML={{ __html: data.title }} />
      <div className={cn(s.multihouse, s.pc)}>
        <ul className={s.line1}>
          <li className={s.block1}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block1?.bgImg}
              alt={data.block1?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block1?.text}></Text>
          </li>
          <li className={s.block2}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              objectPosition="right top"
              src={data.block2?.bgImg}
              alt={data.block2?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block2?.text}></Text>
          </li>
        </ul>
        <ul className={s.line2}>
          <li className={s.block3}>
            <div className={s.subBlock}>
              <Picture className={s.icon} source={data.block3?.icon} />
              <Text className={s.txt} html={data.block3?.text}></Text>
            </div>
            <div className={s.subBlock}>
              <Picture className={s.icon} source={data.block4?.icon} />
              <Text className={s.txt} html={data.block4?.text}></Text>
            </div>
          </li>
          <li className={s.block5}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block5?.bgImg}
              alt={data.block5?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block5?.text}></Text>
          </li>
          <li className={s.block6}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block6?.bgImg}
              alt={data.block6?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block6?.text}></Text>
          </li>
        </ul>
      </div>
      <div className={cn(s.multihouse, s.mobile)}>
        <ul className={s.line1}>
          <li className={s.block1}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block1?.mobileBgUrl}
              alt={data.block1?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block1?.text}></Text>
          </li>
        </ul>
        <ul className={s.line2}>
          <li className={s.block3}>
            <div className={s.subBlock}>
              <Picture className={s.icon} source={data.block3?.icon} />
              <Text className={s.txt} html={data.block3?.text}></Text>
            </div>
            <div className={s.subBlock}>
              <Picture className={s.icon} source={data.block4?.icon} />
              <Text className={s.txt} html={data.block4?.text}></Text>
            </div>
          </li>
          <li className={s.block2}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block2?.mobileBgUrl}
              alt={data.block2?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block2?.text}></Text>
          </li>
        </ul>
        <ul className={s.line3}>
          <li className={s.block5}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block5?.mobileBgUrl}
              alt={data.block5?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block5?.text}></Text>
          </li>
          <li className={s.block6}>
            <Image
              className={s.productImage}
              layout="fill"
              objectFit="cover"
              src={data.block6?.mobileBgUrl}
              alt={data.block6?.altText || 'Product Image'}
            />
            <Text className={s.txt} html={data.block6?.text}></Text>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Multihouse
