import { useMemo } from 'react'
import { cn, rmEmptyKeys } from 'components/helper'
import dynamic from 'next/dynamic'
import { Picture } from '@components/ui'
import { useVariant } from '@shopify/product/use-variant'
import { useInitializedOptionsState } from '@shopify/product/use-options'

const Aplushotspots1 = dynamic(() => import('./Aplushotspots1'))
const Aplusnavcarousel = dynamic(() => import('./Aplusnavcarousel'))
const AplusImageCarousel = dynamic(() => import('./AplusImageCarousel'))
const AplusBgImage = dynamic(() => import('./AplusBgImage'))
const AplusFullVideo = dynamic(() => import('./AplusFullVideo'))
const AplusVideoCarousel = dynamic(() => import('./AplusVideoCarousel'))

const ProductAPlus = ({ product, data, type, dataKey }) => {
  const [selectedOptions, setSelectedOptions] =
    useInitializedOptionsState(product)
  const variant = useVariant(product, selectedOptions)

  const aplusSection = useMemo(() => {
    const all = [
      {
        type: 'aplusHotspots1',
        dom: <Aplushotspots1 data={data} />,
      },
      {
        type: 'aplusNavCarousel',
        dom: <Aplusnavcarousel data={data} dataKey={dataKey} />,
      },
      {
        type: 'aplusImageCarousel',
        dom: <AplusImageCarousel data={data} dataKey={dataKey} />,
      },
      {
        type: 'aplusFullImage',
        dom: (
          <div className="w-full">
            <img
              className="only-in-pc"
              src={data.imageUrl}
              alt={data?.alt || ''}
            ></img>
            <img
              className="only-in-mobile"
              src={data.mobileImageUrl}
              alt={data?.alt || ''}
            ></img>
          </div>
        ),
      },
      {
        type: 'aplusBgImage',
        dom: <AplusBgImage data={data} />,
      },
      {
        type: 'aplusFullVideo',
        dom: <AplusFullVideo data={data} variant={variant} />,
      },
      {
        type: 'aplusVideoCarousel',
        dom: <AplusVideoCarousel data={data} variant={variant} />,
      },
    ]
    const target = all.filter((item) => item.type === type)?.pop()
    return target ? target.dom : all[0]?.dom
  }, [data, type])

  return (
    <div
      className={cn('relative mx-auto w-full max-w-[1460px]')}
      style={rmEmptyKeys({
        'max-width': data?.styles?.maxWidth,
        paddingTop: data?.styles?.paddingTop,
        paddingBottom: data?.styles?.paddingBottom,
      })}
    >
      {aplusSection}
    </div>
  )
}

export default ProductAPlus
