import { useRouter } from 'next/router'
import Script from 'next/script'
import { languageCode } from '@commerce/product/use-price'

const TrustpilotList = ({ data, id }) => {
  const { locale } = useRouter()
  return (
    <div className="layer">
      <div className="content">
        {/* TrustBox widget - Carousel */}
        <div
          class="trustpilot-widget"
          data-locale={data?.locale || locale}
          data-template-id={data?.templateId || ''}
          data-businessunit-id={data?.businessunitId || ''}
          data-style-height="140px"
          data-style-width="100%"
          data-tags={data?.tag || ''}
          data-stars="1,2,3,4,5"
          data-review-languages={
            data?.languages || languageCode(locale) || 'en'
          }
        >
          <a href={data?.link} target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  )
}
export default TrustpilotList
