import cn from 'classnames'
import s from './index.module.css'
import { motion } from 'framer-motion'
import { Picture, Text, Button, useUI } from '@components/ui'

const Index = ({ id, data, pageHandle }) => {
  const motionConf = {
    initial: 'hidden',
    whileInView: 'show',
    variants: {
      show: {
        transform: 'translateY(0)',
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      },
      hidden: {
        transform: 'translateY(50px)',
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      },
    },
    viewport: { once: true },
  }

  return (
    <motion.section id={id} className="layer relative" {...motionConf}>
      <div className="content bg-white">
        {data?.title && (
          <Text html={data?.title} className={cn(s.title, s.speicherTitleB)} />
        )}
        <div className={cn('md:!hidden', s.mediaModule)}>
          {data?.list?.map((item, index) => {
            const _c = s[`mediaRow_${index + 1}`]
            return (
              <div
                key={index}
                className={cn(s.mediaRow, 'overflow-hidden', _c)}
              >
                {item?.map((cItem, index) => {
                  const _c = `overflow-hidden aspect-w-[${Number(
                    cItem.w
                  )}] aspect-h-[${Number(cItem.h)}]`
                  return (
                    <div
                      key={index}
                      className={cn(
                        s.mediaCol
                        // `w-[${Number(cItem.w) / 12.8}%]`
                      )}
                    >
                      <TextComp data={cItem} />
                      {cItem?.video ? (
                        <video
                          className="pc"
                          poster={cItem.img}
                          src={cItem.video}
                          autoPlay
                          playsInline
                          muted
                          loop
                          preload="auto"
                        ></video>
                      ) : (
                        <Picture
                          source={`${cItem?.img},${
                            cItem?.img_mob || cItem?.img
                          } 768`}
                          loading="eager"
                          className={_c}
                          imgClassName="object-cover w-full h-full object-cover"
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className={cn('min-md:!hidden', s.mediaModule_mob)}>
          {data?.list_mob?.map((item, index) => {
            const _c = s[`mob_mediaRow_${index + 1}`]
            const _s = `overflow-hidden aspect-w-[${Number(
              item.w
            )}] aspect-h-[${Number(item.h)}]`
            return (
              <div
                key={index}
                className={cn(s.mob_mediaRow, 'overflow-hidden', _c)}
              >
                <TextComp data={item} />
                {item?.type !== 'spectial' ? (
                  <>
                    {item?.video ? (
                      <video
                        className={'mobile'}
                        poster={item.img}
                        src={item.video}
                        autoPlay
                        playsInline
                        muted
                        loop
                        preload="auto"
                      ></video>
                    ) : (
                      <Picture
                        source={`${item?.img},${
                          item?.img_mob || item?.img
                        } 768`}
                        loading="eager"
                        className={_s}
                        imgClassName="object-cover w-full h-full object-cover"
                      />
                    )}
                  </>
                ) : (
                  <div className={s.mob_mediaRowSpectial}>
                    {item?.children?.map((cItem, index) => {
                      const _c = `overflow-hidden aspect-w-[${Number(
                        cItem.w
                      )}] aspect-h-[${Number(cItem.h)}]`
                      return (
                        <>
                          <div key={index} className={cn(s.mediaCol)}>
                            <TextComp data={cItem} />
                            {cItem?.video ? (
                              <video
                                className={'mobile'}
                                poster={cItem.img}
                                src={cItem.video}
                                autoPlay
                                playsInline
                                muted
                                loop
                                preload="auto"
                              ></video>
                            ) : (
                              <Picture
                                source={`${cItem?.img},${
                                  cItem?.img_mob || cItem?.img
                                } 768`}
                                loading="eager"
                                className={_c}
                                imgClassName="object-cover w-full h-full object-cover"
                              />
                            )}
                          </div>
                        </>
                      )
                    })}
                  </div>
                )}
              </div>
            )
            // }
          })}
        </div>
      </div>
      <style jsx global>
        {`
          .${s.speicherTitleB} .bgText {
            background: linear-gradient(
              90deg,
              #2c7ed0 7.8%,
              #00a9e0 32.14%,
              #00db84 90.04%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          @media (max-width: 768px) {
            .${s.speicherTitleB} .bgText {
              font-size: 32px;
            }
          }
        `}
      </style>
    </motion.section>
  )
}

export default Index

const TextComp = ({ data }) => {
  if (!data?.title) return null
  return (
    <div className={s.introBox}>
      {data.title && (
        <Text
          variant="paragraph"
          html={data.title}
          className={s.introBoxTitle}
        />
      )}
      {data.subTitle && (
        <Text
          variant="paragraph"
          html={data.subTitle}
          className={s.introBoxSubTitle}
        />
      )}
      <div className={s.introBoxInfo}>
        {data?.info?.map((info, index) => {
          return (
            <div key={index} className={s.introItem}>
              <Text
                variant="inline"
                html={info.nums}
                className={s.introBoxInfoNums}
              />
              <Text
                variant="inline"
                html={info.text}
                className={s.introBoxInfoText}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
