import cn from 'classnames'
import Script from 'next/script'
import { useState, useEffect } from 'react'
import { Picture, Text } from '@components/ui'
import { PlayIcon } from '@heroicons/react/24/outline'
import SolixButton from '@components/control/SolixButton'

const Index = ({ data }) => {
  const [showYoutube, setShowYoutube] = useState(false)
  return (
    <>
      <Script
        id="youtube-iframe-api"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `var tag = document.createElement('script'); tag.src
                ='https://www.youtube.com/iframe_api'; var firstScriptTag =
                document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);`,
        }}
      />
      <section className="md:pl-[14px] md:pr-[14px]">
        {data?.title && (
          <Text
            className="text-center text-[36px] font-[600] leading-[1.2] md:text-[24px]"
            html={data.title}
            variant="sectionHeading"
          ></Text>
        )}
        {data?.desc && (
          <Text
            className="text-center text-[24px] font-[500] leading-[1.2] md:text-[14px]"
            html={data.desc}
            variant="paragraph"
          ></Text>
        )}
        <div className="relative mt-[40px] overflow-hidden rounded-[20px] bg-[#000] md:rounded-[10px]">
          {data?.videoIdDialog && showYoutube && (
            <div className="absolute left-0 top-0 h-full w-full">
              <YouTubeVideo data={data} />
            </div>
          )}
          <div
            className={cn(
              'duration-400 relative transition-all',
              showYoutube && 'pointer-events-none opacity-0'
            )}
          >
            <Picture
              className="aspect-h-[1080] aspect-w-[1920] relative"
              source={`${data?.posterImg}, ${
                data?.posterImg_mob || data?.posterImg
              } 769`}
            />
            {data?.button_wtv && (
              <div className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 cursor-pointer justify-center">
                <SolixButton
                  onClick={() => {
                    setShowYoutube(true)
                  }}
                >
                  <div className="flex justify-center">
                    <span>{data.button_wtv}</span>
                    <PlayIcon className="ml-[10px] w-[11px] fill-white md:ml-[5px]" />
                  </div>
                </SolixButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const YouTubeVideo = ({ data }) => {
  const onPlayerReady = (event) => {
    event.target.playVideo()
  }
  const onYouTubeIframeAPIReady = () => {
    if (YT && YT.Player) {
      let players = new YT.Player('player', {
        height: '100%',
        width: '100%',
        videoId: data.videoIdDialog,
        events: {
          onReady: onPlayerReady,
        },
        playerVars: {
          loop: 1,
          playlist: data.videoIdDialog,
        },
      })
    }
  }
  useEffect(() => {
    try {
      onYouTubeIframeAPIReady()
    } catch (error) {
      //
    }
  }, [])
  return <div id="player"></div>
}

export default Index
