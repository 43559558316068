/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import s from './TabInRight.module.css'
import { gsap } from 'gsap/dist/gsap'
import { Picture } from '@components/ui'

const Tabs = ({ data }) => {
  if (!data?.tabs) return null

  const border = useRef()
  const [activeTab, setActiveTab] = useState(0)
  const [stop, setStop] = useState(0)
  const [timer, setTimer] = useState()

  useEffect(() => {
    setTimer(
      setInterval(() => {
        setActiveTab((prev) => {
          if (prev === data.tabs.length - 1) {
            return 0
          }
          return prev + 1
        })
      }, [7000])
    )
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handClick = (key) => {
    clearInterval(timer)
    setStop(true)
    setActiveTab(key)
  }

  useEffect(() => {
    !stop &&
      gsap.to(border.current, {
        width: '100%',
        duration: 7,
      })
  }, [activeTab, stop])

  return (
    <div className={cn(s.container, 'margin-center')}>
      <div className={s.contentContainer}>
        <div className={s.imgContainer}>
          {data.tabs.map((tab, index) => {
            return (
              <div key={`tab-${index}`}>
                {tab.content?.imgUrl && (
                  <Picture
                    className={cn(
                      s.img,
                      activeTab === index ? 'opacity-100' : 'opacity-0'
                    )}
                    alt={tab.content?.alt || tab.content.title}
                    source={
                      tab.content?.mobile_imgUrl
                        ? `${tab.content?.imgUrl}, ${tab.content?.mobile_imgUrl} 769`
                        : tab.content?.imgUrl
                    }
                  />
                )}
              </div>
            )
          })}
        </div>
        <div className={s.textContainer}>
          <h2
            className={cn('text-5xl md:text-3xl font-bold')}
            dangerouslySetInnerHTML={{
              __html: data.tabs[activeTab]?.content?.title,
            }}
          ></h2>
          <p
            className="text-xl md:text-base pt-2 md:pt-3"
            dangerouslySetInnerHTML={{
              __html: data.tabs[activeTab]?.content?.subTitle,
            }}
          ></p>
        </div>
      </div>
      <div className={s.tabContainer}>
        {data.tabs.map((tab, index) => {
          return (
            <div key={index} className={cn(s.tabItem)}>
              <button
                className={index === activeTab ? 'font-bold' : ''}
                onClick={() => handClick(index)}
              >
                {tab.tab}
              </button>
              {index === activeTab ? (
                <div
                  className={cn(`${s.border}`, stop ? 'w-full' : 'w-0')}
                  id={`border-${index}`}
                  ref={border}
                />
              ) : (
                <div className={'border-white h-1'} />
              )}
              <div className={cn(`h-[1px] bg-black`)} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
