/* eslint-disable react-hooks/rules-of-hooks */
import { gsap } from 'gsap/dist/gsap'
import isMobile from 'ismobilejs'
import cn from 'classnames'
import TextsLayout from '../ui/TextsLayout/TextsLayout'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useEffect, useRef } from 'react'
import Picture from '@components/ui/Picture'
import { v4 as uuidv4 } from 'uuid'
import NormalTexts from '@components/common/NormalTexts/NormalTexts'
import { positionMap } from './ListingBanner'
import { Text } from '@components/ui'

const Banner = ({ data, id = '' }) => {
  if (!data?.banners) return null

  const containerRef = useRef()
  const active = useRef(0)
  const cssId = id ? id : uuidv4().slice(0, 5)

  const showFromTop = (identifier) =>
    gsap.fromTo(identifier, { opacity: 0, y: 300 }, { opacity: 1, y: 0 })

  const showFromBottom = (identifier) =>
    gsap.fromTo(identifier, { opacity: 0, y: -300 }, { opacity: 1, y: 0 })

  const hiddenToBottom = (identifier) =>
    gsap.fromTo(identifier, { opacity: 1, y: 0 }, { opacity: 0, y: -300 })

  const hiddenToTop = (identifier) =>
    gsap.fromTo(identifier, { opacity: 1, y: 0 }, { opacity: 0, y: 300 })

  const show = (identifier) => gsap.to(identifier, { opacity: 1 })
  const hidden = (identifier) => gsap.to(identifier, { opacity: 0 })

  const slideDown = () => {
    show(`#image-${active.current + 1}`)
    showFromTop(`#text-${active.current + 1}`)
    hidden(`#image-${active.current}`)
    hiddenToBottom(`#text-${active.current}`)
    active.current = active.current + 1
  }
  const slideUp = () => {
    show(`#image-${active.current - 1}`)
    showFromBottom(`#text-${active.current - 1}`)
    hidden(`#image-${active.current}`)
    hiddenToTop(`#text-${active.current}`)
    active.current = active.current - 1
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const handler = (self) => {
      if (isMobile(window.navigator).phone) return
      const process = self.progress.toFixed(1)
      // console.log("progress", process)
      if (self.direction === 1) {
        // 下滑
        if (active.current === data.banners.length - 1) return
        let next = ((active.current + 1) / data.banners.length).toFixed(1)
        // console.log("下一张切换锚点", next)
        if (process === next) slideDown()
      } else {
        // 上滑
        if (active.current === 0) return
        const last = (active.current / data.banners.length).toFixed(1)
        // console.log("上一张切换锚点", last)
        if (process === last) slideUp()
      }
    }
    ScrollTrigger.create({
      trigger: containerRef.current,
      start: 'top top',
      end: 'bottom bottom',
      // markers: true,
      // id: 'banner',
      onUpdate: (self) => handler(self),
    })
  }, [])

  return (
    <div
      style={{ height: `calc(${data.banners.length * 85}vh - 130px)` }}
      className="md:!h-auto"
      ref={containerRef}
    >
      <div className="sticky top-[65px] overflow-hidden">
        <div className="relative h-[calc(100vh-130px)] md:h-auto md:flex md:flex-col md:gap-y-8">
          {data.banners?.map((banner, index) => {
            const {
              align = 'center',
              justify = 'top',
              gridStyle,
            } = positionMap[data.textPosition || 'centerTop'] ||
            positionMap.centerTop
            return (
              <div
                key={index}
                className="absolute top-0 h-full md:relative md:text-black"
              >
                <TextsLayout
                  id={`text-${index}`}
                  gridStyle={gridStyle}
                  align={align}
                  justify={justify}
                  responsive={[
                    {
                      breakpoint: 769,
                      settings: {
                        position: 'above',
                      },
                    },
                  ]}
                  className={`${
                    index === 0 ? 'opacity-100' : 'opacity-0'
                  } md:opacity-100`}
                >
                  <NormalTexts
                    data={banner}
                    align={align}
                    justify={justify}
                  ></NormalTexts>
                </TextsLayout>
                <div
                  id={`image-${index}`}
                  className={`${
                    index === 0 ? 'opacity-100' : 'opacity-0'
                  } md:opacity-100`}
                >
                  <Picture
                    alt={banner.alt}
                    className="h-[100vh] md:!h-auto w-screen"
                    imgClassName={'object-fit h-[100%]'}
                    source={`${banner.bgUrl}, ${banner.mobileBgUrl} 769`}
                  />
                </div>
                {/* 底部的 note */}
                <TextsLayout align={banner.notePosition || align}>
                  <Text
                    html={banner.note}
                    className="text-center h-full flex items-end justify-center pb-4 text-[18px] only-in-pc"
                  ></Text>
                </TextsLayout>
                <Text
                  html={banner.note}
                  className="text-center h-full text-sm only-in-mobile"
                ></Text>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Banner
