/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react'
import cn from 'classnames'
import s from './index.module.css'
import { Picture } from '@components/ui'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const TabsModel = ({ data }) => {
  if (!data) return null
  const [current, setCurrent] = useState(0)
  const [domMaxH, setDomMaxH] = useState(0)

  const handleTab = (title, index) => {
    if (index == current) return
    setCurrent(index)
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'click',
      eventAction: 'switch_tab',
      eventLabel: title, // 传用户点击 Tab 的文案标题
      eventValue: 'undefined',
      nonInteraction: true,
    })
  }

  useEffect(() => {
    let numbers = []
    const domList = document?.getElementsByClassName(`${s.iconsListBox}`)
    for (let index = 0; index < domList.length; index++) {
      numbers.push((data.height = domList?.[index]?.clientHeight))
    }
    let maxNumber = Math.max(...numbers)
    setDomMaxH(maxNumber)
  }, [])

  return (
    <section className={cn(s.tabsModel)}>
      <div className={cn(s.tabsModelBox)}>
        {data?.title && (
          <h2
            className={s.title}
            dangerouslySetInnerHTML={{ __html: data.title }}
          ></h2>
        )}
        <div className={s.tabsModelCon}>
          <div className={s.imageBox}>
            <Picture
              alt={data?.imageAlt}
              className="aspect-w-[632] aspect-h-[520] w-full"
              source={`${data?.imgUrl}, ${data?.mobileImgUrl} 769`}
            />
          </div>
          <div className={s.iconBox}>
            <div className={cn(s.nav, 'hidden md:block')}>
              <div className={cn(s.navBox)}>
                {data.list.map((item, index) => (
                  <p
                    onClick={() => handleTab(item.tit, index)}
                    className={cn(
                      s.nav_item,
                      s.tit,
                      index == current && s.nav_item_active
                    )}
                    key={'nav-' + index}
                    dangerouslySetInnerHTML={{ __html: item?.tit }}
                  />
                ))}
              </div>
            </div>
            <div
              className={cn('relative', s.iconCon)}
              // style={{ height: `${domMaxH}px` }}
            >
              {data.list.map((item, index) => {
                let mob_col = false
                if (item?.icons?.length <= 2) mob_col = true
                return (
                  <div
                    key={index}
                    onKeyPress={(e) => {
                      if (e.key == 'Enter') {
                        handleTab(item.tot, index)
                      }
                    }}
                    onClick={() => handleTab(item.tit, index)}
                    className={cn(s.item, {
                      [s.item_active]: index == current,
                    })}
                  >
                    <p
                      className={cn(s.tit, 'md:hidden')}
                      dangerouslySetInnerHTML={{ __html: item?.tit }}
                    />
                    <div
                      className={cn(s.iconsList)}
                      style={{
                        height: index == current ? `${domMaxH}px` : 0,
                      }}
                    >
                      <div
                        className={cn(
                          s.iconsListBox,
                          mob_col && 'md:flex-col md:!items-center'
                        )}
                      >
                        {item?.icons?.map((icon, i) => (
                          <div className={s.icon} key={'icon' + i}>
                            <Picture
                              alt={icon?.imgAlt}
                              className="aspect-w-[140] aspect-h-[70] w-full"
                              source={icon?.img}
                            />
                            <p
                              className={s.txt}
                              dangerouslySetInnerHTML={{ __html: icon?.txt }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TabsModel
