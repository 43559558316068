import React from 'react'
import cn from 'classnames'
import 'regenerator-runtime/runtime'

const Affirm = ({ price, className }) => {
  // console.log(price)
  return (
    <p
      data-page-type="page"
      className={cn(className, 'affirm-as-low-as')}
      data-amount={price * 100}
    ></p>
  )
}

export default Affirm
