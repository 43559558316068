import { Picture } from '@components/ui'
import cn from 'classnames'
import s from './index.module.css'
import { useEffect, useState } from 'react'

const Index = ({ data, id }) => {
  return (
    <section id={id} className={cn(s.section_7_wrap)}>
      <Picture
        className="aspect-h-[880] aspect-w-[1920] absolute left-0 top-0 h-full w-full md:aspect-h-[949] md:aspect-w-[375] md:absolute"
        source={`${data.img}, ${data.mobImg} 769}`}
      />
      <div className={cn('layer', s.section_7)}>
        <div className={cn('content')}>
          <p
            className={s.moduleTitle}
            dangerouslySetInnerHTML={{ __html: data.title }}
          />

          <ul className={s.col}>
            {data.list.map((item, index) => (
              <Row item={item} data={data} key={index} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Index

const Row = ({ item, data }) => {
  const [isExpand, setIsExpand] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileStatue, setIsMobileStatue] = useState(true)

  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth <= 768) return setIsMobile(true)
    setIsMobile(false)
  }

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const handleAdd = () => {
    setIsExpand(!isExpand)
    if (isMobile) {
      setIsMobileStatue(!isExpand)
    } else {
      setIsMobileStatue(true)
    }
  }

  return (
    <li className={s.row}>
      {isExpand && (
        <Picture className={s.futureImg} source={`${item.img}, 769}`} />
      )}

      <div className="md:flex-1 md:text-left">
        {isMobileStatue && (
          <p
            className={s.enough}
            dangerouslySetInnerHTML={{ __html: item.enough }}
          />
        )}

        {isMobileStatue && (
          <p
            className={s.tons}
            dangerouslySetInnerHTML={{ __html: item.tons }}
          />
        )}

        {item.household && (
          <p
            style={{ '--p': isExpand ? 2 : 'unset' }}
            className={s.household}
            dangerouslySetInnerHTML={{ __html: item.household }}
          />
        )}

        <div className={s.add} onClick={handleAdd}>
          <Picture
            source={`${isExpand ? data.add : data.reduce}, 769}`}
            className="h-[32px] w-[32px] md:h-[24px] md:w-[24px] xl:h-[20px] xl:w-[20px]"
          />
        </div>
      </div>
    </li>
  )
}
