import { Picture } from '@components/ui'
import cn from 'classnames'
import Slider from 'react-slick'
import s from './SingleSlider.module.css'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

const MySlider = ({ data }) => {
  if (!data) return null
  const NextArrow = (props) => {
    return (
      <button
        aria-label="next Arrow"
        onClick={props.onClick}
        className="absolute top-[50%] right-[-45px] z-10 block w-[50px] cursor-pointer md:right-[3vw] md:top-[35%]"
      >
        <ChevronRightIcon className="stroke-1" />
      </button>
    )
  }

  const PrevArrow = (props) => {
    return (
      <button
        onClick={props.onClick}
        aria-label="prev Arrow"
        className="absolute top-[50%] left-[-45px] z-10 block w-[50px] cursor-pointer md:left-[3vw] md:top-[35%]"
      >
        <ChevronLeftIcon className="stroke-1" />
      </button>
    )
  }

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: data?.dots || false,
    speed: data?.speed || 1000,
    arrows: data?.arrows || false,
    autoplay: data?.autoplay || false,
    autoplaySpeed: data?.autoplaySpeed || 3000,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: data?.mobDots || false,
          arrows: data?.mobArrows || false,
        },
      },
    ],
  }

  return (
    <section className={s.faster}>
      <Slider {...settings}>
        {data?.list.map((item, index) => (
          <div key={index} className="relative">
            <Picture
              className="aspect-w-[1208] aspect-h-[402] w-full md:aspect-w-[414] md:aspect-h-[309]"
              quality={2}
              source={`${item.img}, ${item.mobImg} 769`}
            />
            <div
              className={cn(s.fasterContent, {
                [s.left]: item.textAlign == 'left',
                [s.right]: item.textAlign == 'right',
              })}
            >
              <p
                className={s.h1}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default MySlider
