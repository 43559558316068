/* eslint-disable react-hooks/rules-of-hooks */
import s from './CountDown.module.css'
import { useState, useEffect } from 'react'
import { Text } from '@components/ui'
import jump from 'jump.js'
import cn from 'classnames'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const CountDown = ({ data }) => {
  const { setRelativeLink } = useRelativeLink()
  let { activeDate, expireDate } = data
  // activeDate = '2022-07-20T16:10:00+08:00'
  // expireDate = '2022-07-20T18:00:00+08:00'
  // 没有配置或者配置错误格式的时间
  const isValidatedDate = (str) => Boolean(str && new Date(str))
  if (!isValidatedDate(activeDate)) {
    activeDate = '1999-12-03T10:15:30+01:00'
  }
  if (!isValidatedDate(expireDate)) {
    expireDate = '9999-12-03T10:15:30+01:00'
  }
  const now = new Date().getTime()

  // console.log('now:', now)
  // console.log('activeDate:', new Date(activeDate).getTime())
  // console.log('expireDate:', new Date(expireDate).getTime())
  // 不在活动区间
  if (
    new Date(activeDate).getTime() > now ||
    now > new Date(expireDate).getTime()
  ) {
    return null
  }

  let diff = new Date(expireDate) - now
  // console.log('diff:', diff)
  const [num, setNum] = useState(diff)
  // console.log('num:', num)

  function n(n) {
    return n > 9 ? n : '0' + n
  }

  const days = Math.floor(num / (1000 * 60 * 60 * 24))
  const hours = Math.floor((num % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((num % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((num % (1000 * 60)) / 1000)

  const [showBtn, setShowBtn] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = () => {
    if (window.pageYOffset > 600) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  useEffect(() => {
    let timer = 0
    if (diff > 0 && num !== 0) {
      timer = setTimeout(() => {
        const timeDiff = new Date(expireDate) - new Date().getTime()
        if (timeDiff <= 1000) {
          clearTimeout(timer)
        } else {
          setNum(timeDiff)
        }
      }, 1000)
    }
    return () => {
      // 组件销毁时，清除定时器
      clearTimeout(timer)
    }
  }, [num])

  return (
    <div className="flex h-full w-full items-center justify-center bg-anker-color px-3 py-3 text-white md:justify-start">
      <div className="only-in-pc flex items-center font-normal">
        <Text className={s.prefix} html={data.prefix} />
        <div className={s.number}>{n(days)}</div>
        <div className={s.text}>{days === 1 ? data.day : data.days}</div>
        <div className={s.number}>{n(hours)}</div>
        <div className={s.text}>{data.hours}</div>
        <div className={s.number}>{n(minutes)}</div>
        <div className={s.text}>{data.minutes}</div>
        <div className={s.number}>{n(seconds)}</div>
        <div className={s.text}>{data.seconds}</div>
      </div>
      <div className="only-in-mobile font-normal">
        <Text className={s.prefix} html={data.prefix} />
        <div className="mb-[-16px] grid grid-cols-4 grid-rows-2 justify-items-center gap-x-2">
          <div className={s.number}>{n(days)}</div>
          <div className={s.number}>{n(hours)}</div>
          <div className={s.number}>{n(minutes)}</div>
          <div className={s.number}>{n(seconds)}</div>
          <div className={s.text}>{days === 1 ? data.day : data.days}</div>
          <div className={s.text}>{data.hours}</div>
          <div className={s.text}>{data.minutes}</div>
          <div className={s.text}>{data.seconds}</div>
        </div>
      </div>

      <a
        href={setRelativeLink({ link: data?.to })}
        className={cn(s.button, !showBtn && 'opacity-0')}
        onClick={() => jump(data.to, { duration: 500 })}
      >
        {data.btnText}
      </a>
    </div>
  )
}

export default CountDown
