import { useCallback } from 'react'
import Layout from './Layout'
import s from './Manuals.module.css'

import { useUI } from '@components/ui'

const Specs = ({ data, metafields = {} }) => {
  if (!data?.items) return null

  const { combineoptions } = metafields

  const { combineOption, contextTradeGift } = useUI()

  const showImg = useCallback(
    (related_handlesku, exclude_handlesku) => {
      if (!related_handlesku) return true
      if (combineOption && exclude_handlesku?.length > 0) {
        return Object.entries(combineOption).every(
          ([key, value]) => !exclude_handlesku?.includes(String(value))
        )
      }

      if (contextTradeGift?.length > 0) {
        const tg = contextTradeGift?.some(
          (item) =>
            related_handlesku?.includes(
              `${item?.product?.handle},${item?.variant?.sku},${item?.quantity}`
            ) ||
            related_handlesku?.includes(
              `${item?.product?.handle},${item?.variant?.sku}`
            )
        )
        if (tg) return tg
      }
      if (combineOption && related_handlesku?.length > 0) {
        const co = Object.entries(combineOption).some(([key, value]) => {
          const current = combineoptions?.[key]?.options?.find(
            (item) => item?.related_handlesku === value
          )
          if (current?.special) {
            const hit = Object.entries(current?.special?.select)?.every(
              ([k, v]) => v?.includes(combineOption[k])
            )
            if (hit) {
              return related_handlesku?.includes(current?.special?.handlesku)
            }
          }
          if (value?.part_handlesku) {
            return related_handlesku?.some((item) => {
              const [handle, sku, num] = item?.split(',')
              if (
                value?.part_handlesku === `${handle},${sku}` ||
                value?.related === `${handle},${sku}`
              ) {
                if (num) {
                  return String(value?.value) === String(num)
                }
                return String(value?.value) > 0
              }
            })
          }
          return related_handlesku?.includes(value)
        })
        if (co) return co
      }
      return false
    },
    [combineOption, contextTradeGift?.length]
  )

  return (
    <Layout title={data.title}>
      <div className="flex flex-wrap">
        {data.items.map(
          (item, index) =>
            showImg(item?.related_handlesku) && (
              <div
                className="mb-[40px] w-[290px] md:w-fit md:min-w-[50%] md:pr-[20px]"
                key={index}
              >
                <p
                  className={s.textSmall}
                  dangerouslySetInnerHTML={{ __html: item.name }}
                ></p>
                <p
                  className={s.subTextSmall}
                  dangerouslySetInnerHTML={{ __html: item.presentation }}
                ></p>
              </div>
            )
        )}
      </div>
    </Layout>
  )
}

export default Specs
