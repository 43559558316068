import { Picture, Text } from '@components/ui'
import cn from 'classnames'
import { motion } from 'framer-motion'
import s from './index.module.css'

const Ksp = ({ id, data, isMobile, setYouTubeId, pageHandle, motionConf }) => {
  return (
    <motion.div id={id} {...motionConf}>
      <div className={cn('layer')}>
        <div className="content">
          <Text
            variant="sectionHeading"
            html={data?.title}
            className={cn('solixbg', s.title)}
          />
          <div className={cn(s.mediaContent)}>
            {[...(isMobile ? data?.list_mob : data?.list)]?.map(
              (colData, colIndex) => {
                const cName = [
                  [
                    'aspect-h-[372] aspect-w-[411] md:h-[207px] md:w-[calc(165/390*100vw)]',
                    'aspect-h-[270] aspect-w-[411] md:h-[207px] md:w-[calc(165/390*100vw)]',
                  ],
                  [
                    'aspect-h-[460] aspect-w-[411] md:h-[calc(214/390*100vw)] md:w-[calc(181/390*100vw)]',
                    'aspect-h-[182] aspect-w-[411] md:h-[calc(214/390*100vw)] md:w-[calc(149/390*100vw)]',
                  ],
                  [
                    'aspect-h-[372] aspect-w-[411] md:h-[calc(188/390*100vw)] md:w-[calc(149/390*100vw)]',
                    'aspect-h-[270] aspect-w-[411] md:h-[calc(188/390*100vw)] md:w-[calc(181/390*100vw)]',
                  ],
                ]
                return (
                  <div
                    className="itemCol grid gap-6 md:flex md:items-start md:justify-between md:gap-0"
                    key={colIndex}
                  >
                    {colData?.map((item, index) => (
                      <div key={item?.title} class="itemBox relative !h-auto">
                        <Picture
                          className={cName?.[colIndex]?.[index]}
                          source={`${item?.img}, ${
                            item?.img_mob || item?.img
                          } 769`}
                        />
                        <div className="absolute inset-0 left-0 top-0 flex flex-col items-start justify-start px-[calc(32/1920*100vw)] py-[calc(48/1920*100vw)] text-left md:px-3 md:py-[14px] min-xxl:px-8 min-xxl:py-12">
                          {item?.title && (
                            <Text
                              html={item?.title}
                              variant="sectionSubHeading"
                              className={
                                'mb-3 text-[calc(40/1920*100vw)] font-bold leading-[1.2] text-white md:mb-1.5 md:text-[20px] l:mb-2 min-xxl:text-[40px]'
                              }
                            />
                          )}
                          {item?.subTitle && (
                            <Text
                              html={item?.subTitle}
                              className={
                                '-mt-2 mb-3 text-[24px] font-[500] leading-[1.2] text-white md:-mt-1 md:mb-1.5 md:text-[14px]'
                              }
                            />
                          )}
                          {item?.desc && (
                            <Text
                              html={item?.desc}
                              className={
                                'text-[17px] font-[500] leading-[1.2] text-white md:text-[12px] md:leading-[1.35] l:text-[11px]'
                              }
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Ksp
