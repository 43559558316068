import Icon from "@components/icons"
import Layout from "./Layout"
import s from './Manuals.module.css'

const Compatibility = ({ data, underscore }) => {
  if (!data?.items) return null
  return <Layout title={data.title} underscore={underscore}>
    <div className="">
      {data.items.map((item, index) => {
        return <div className={s.compatibility} key={index}>
          <div className="">
            {item.icon_key && <Icon iconKey={item.icon_key}
              className="row-span-2 md:hidden max-w-[52px] max-h-[68px] mr-[20px]"
            />}
          </div>
          <div className="">
            <p className={s.compatibilityText}
              dangerouslySetInnerHTML={{ __html: item.text }}></p>
            <p className={s.compatibilitySubText}
              dangerouslySetInnerHTML={{ __html: item.sub_text }}></p>
          </div>
        </div>
      })}
    </div>
  </Layout >
}

export default Compatibility