import cn from 'classnames'

const ProductManualLayout = ({
  title,
  children,
  underscore = true,
  titleClass = '',
  className,
}) => (
  <div className={cn('md:mx-4', className)}>
    <h2
      className={cn('text-2xl mb-10', titleClass)}
      dangerouslySetInnerHTML={{ __html: title }}
    ></h2>
    {children}
    <hr className={cn(!underscore && 'hidden', 'w-full pt-5 text-[#999999]')} />
  </div>
)

export default ProductManualLayout
