import s from './AmazonTiles.module.css'
import cn from 'classnames'
import { Picture } from '@components/ui'
import NormalTexts from '@components/common/NormalTexts/NormalTexts'

const AmazonTiles = ({ data, gap = 10, imagesDeviceDifferent = false }) => {
  if (!data) return null

  const sizeMapping = {
    'full-width': {
      width: 4,
      height: 2,
    },
    large: {
      width: 2,
      height: 2,
    },
    medium: {
      width: 2,
      height: 1,
    },
    small: {
      width: 1,
      height: 1,
    },
  }
  const different =
    data.images?.imagesDeviceDifferent != undefined
      ? data.images?.imagesDeviceDifferent
      : imagesDeviceDifferent
  return (
    <div className="relative">
      <NormalTexts data={data} className="pb-[14px] md:pb-[20px]" />
      <div
        className={cn(s.imgContainer, different && 'only-in-pc')}
        style={{ gap }}
      >
        {data.images?.pc?.map((item, index) => {
          return (
            <div
              className={cn(s.item, 'w-full')}
              key={index}
              style={{
                gridColumn: `span ${sizeMapping[item?.size]?.width} / span ${
                  sizeMapping[item?.size]?.width
                }`,
                gridRow: `span ${sizeMapping[item?.size]?.height} / span ${
                  sizeMapping[item?.size]?.height
                }`,
              }}
            >
              <Picture
                className="w-full "
                imgClassName="h-full"
                alt={item.alt || data.title}
                source={`${item.url}, ${
                  different
                    ? ''
                    : data.images?.mobile && data.images.mobile[index] + ' 769'
                }`}
              />
              {item?.tit && (
                <div
                  className={cn(
                    s.txt,
                    'mx-auto my-[24px] w-[70%] text-center md:w-full'
                  )}
                >
                  <p
                    className={cn(
                      s.tit,
                      'text-[22px] font-semibold leading-[1.1] md:text-[20px]'
                    )}
                    dangerouslySetInnerHTML={{ __html: item.tit }}
                  ></p>
                  <p
                    className={cn(
                      s.des,
                      'mt-[16px] text-[18px] font-medium leading-[1.1] md:text-[16px]'
                    )}
                    dangerouslySetInnerHTML={{ __html: item.des }}
                  ></p>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {different && (
        <div className={s.imgContainer + ' only-in-mobile'}>
          {data.images?.mobile?.map((image, index) => {
            return (
              <Picture
                className="w-full"
                imgClassName="h-full"
                key={index}
                source={`${image}`}
              />
            )
          })}
        </div>
      )}
      {data.note && (
        <p
          className="pt-[14px] text-center text-[18px] font-medium md:text-sm"
          dangerouslySetInnerHTML={{ __html: data.note }}
        ></p>
      )}
    </div>
  )
}

export default AmazonTiles
