/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useCallback } from 'react'
import cn from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Picture, Text } from '@components/ui'
import Form from '../subscription/Form'

import styles from './SubscriptionBar.module.css'
import A1780PreStyles from './A1780PreStyles.module.css'

import { useTimer } from 'react-timer-hook'

const SubscriptionBar = ({
  data,
  copywriting,
  styles: gs,
  trace = () => {},
  shopCommon,
}) => {
  if (!data) return null
  // 可传递样式进来替换原样式
  const stylesModule = {
    A1780PreStyles,
  }
  const s = stylesModule[data?.stylesModule]
    ? stylesModule[data?.stylesModule]
    : { ...styles, ...gs }

  const [modal, setModal] = useState(null)

  const [showPop, setShowPop] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = useCallback(() => {
    if (document?.documentElement?.scrollTop > 600) {
      setShowPop(true)
    } else {
      setShowPop(false)
    }
  }, [])

  const updateModal = ({ type, msg }) => {
    let timer
    if (type === 'success') {
      setModal({
        type,
        msg: copywriting?.earlyCouponSuccess || data?.early_coupon_success,
      })
    } else {
      setModal({ type, msg })
    }
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setModal(null)
    }, 12000)
  }

  const hideFormMode = new Date() >= new Date(data.hideFormDate)

  return (
    <div
      className={cn(s.root, {
        [s.hidden]: !showPop,
      })}
    >
      {data?.img && (
        <Picture className={s.img} alt={data.alt} source={`${data.img}`} />
      )}
      <div className="flex flex-col justify-center w-full">
        {!hideFormMode && (
          <Form
            data={data}
            s={s}
            setModal={updateModal}
            trace={trace}
            setEmailFocus={setEmailFocus}
            showPolicy={data.countDown ? emailFocus : true} //配置倒计时时，当 input 聚焦时展示 policy
          />
        )}
        {data.countDown && !emailFocus && (
          <CountDown
            data={{ ...data, ...shopCommon?.countDown }}
            s={styles}
            endTime={data.endTime}
            hideFormMode={hideFormMode}
          />
        )}
      </div>
      {modal && !data?.popup && (
        <p className={s[modal.type]}>{modal.msg || 'welcome'}</p>
      )}
      {modal && data?.popup && (
        <div className={s.masks}>
          <div className={s.popContent}>
            <button className={s.closeBtn} onClick={() => setModal(null)}>
              <XMarkIcon className="w-4 h-4" />
            </button>
            <p
              className={s.popcontent}
              dangerouslySetInnerHTML={{ __html: modal?.msg || 'welcome' }}
            ></p>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubscriptionBar

const CountDown = ({ endTime, data, s, hideFormMode }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(endTime),
  })

  if (new Date() > new Date(endTime)) return null
  return (
    <div className="flex justify-center items-center">
      <Text
        html={data.prefix}
        className={cn(s.copy, { [s.hideFormMode]: hideFormMode })}
        variant="paragraph"
      />
      {days > 0 && (
        <Item time={days} copy={data.days} s={s} hideFormMode={hideFormMode} />
      )}
      <Item time={hours} copy={data.hours} s={s} hideFormMode={hideFormMode} />
      <Item
        time={minutes}
        copy={data.minutes}
        s={s}
        hideFormMode={hideFormMode}
      />
      <Item
        time={seconds}
        copy={data.seconds}
        s={s}
        hideFormMode={hideFormMode}
      />
    </div>
  )
}

const n = (n) => (n > 9 ? '' + n : '0' + n)

const Item = ({ time, copy, s, hideFormMode }) => (
  <div className="flex items-center">
    <div
      suppressHydrationWarning={true}
      className={cn(s.code, { [s.hideFormMode]: hideFormMode })}
    >
      {n(time)}
    </div>
    <div className={cn(s.copy, { [s.hideFormMode]: hideFormMode })}>{copy}</div>
  </div>
)
