import styles from './Specs.module.css'
import cn from 'classnames'

const PowerHouseSpecs = ({ data, productPageMode = false }) => {
  if (!data || Object.keys(data).length === 0) return null
  return (
    <div
      className={cn(
        styles.Specs,
        'specs',
        productPageMode && '!bg-black !pb-9'
      )}
    >
      <div className={`${styles.Specs_content}`}>
        <h1
          className={`${styles.Specs_title} `}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />
        <p
          className="text-3xl md:text-2xl text-white text-center pt-[10px]"
          dangerouslySetInnerHTML={{ __html: data?.sub_title }}
        ></p>
        <div className={`${styles.Specs_flex}`}>
          {data?.Specs_content &&
            data?.Specs_content.map((v, i) => {
              return (
                <div key={i} className={`${styles.Specs_box}`}>
                  {v &&
                    v.map((item, index) => {
                      return (
                        <div key={index} className={`${styles.Specs_box_text}`}>
                          <p
                            className={`${styles.Specs_box_text_p}`}
                            dangerouslySetInnerHTML={{
                              __html: `<span>${item?.subTitle}</span> `,
                            }}
                          />
                          <h2
                            className={`${styles.Specs_box_text_h2}`}
                            dangerouslySetInnerHTML={{
                              __html: `<span>${item?.text}</span> `,
                            }}
                          />
                        </div>
                      )
                    })}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default PowerHouseSpecs
