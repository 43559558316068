// 导入组件和钩子
import useCoupons from '@lib/hooks/useCoupon'
import { cn, rmEmptyKeys } from '@components/helper'
import { useMemo, useState, useRef, useEffect } from 'react'
import { Text, Picture, LoadingDots } from '@components/ui'
import { normalizeStorefrontProduct } from '@components/normalize'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import jump from 'jump.js'
// 导入自定义卡片组件
import { Card } from './Cards'

// 导入样式模块
import s from './index.module.css'
// 导入产品钩子
import useProduct from '@shopify/product/use-product'

// 加载组件
const Loading = () => (
  <div className="flex h-72 w-full items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

// 主组件
const Index = ({ data, pageHandle, id, metafields }) => {
  // 从metafields解构夏季活动折扣
  const { summerCampaignDiscount } = metafields
  // 定义状态
  const [current, setCurrent] = useState(0)
  const [activeProducts, setActiveProducts] = useState(data.list?.[0]?.products)
  const [isMobile, setIsMobile] = useState(false)
  const [isViewMore, setIsViewMore] = useState(data?.hasViewMore || false)

  // 调整窗口大小的函数
  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth <= 768) return setIsMobile(true)
    setIsMobile(false)
  }

  const skus = useMemo(() => {
    const arr = []
    data?.list?.map((item) => {
      item?.products?.map((p) => {
        arr.push(p?.sku)
      })
    })
    return arr
  }, [data?.list])

  // const { coupons } = useCoupons(skus)

  // 监听窗口大小变化
  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  // 获取产品句柄
  const handles = data?.list
    ?.reduce(
      (prev, cur) => [
        ...prev,
        ...(cur.products?.map((product) => product.handle) || []),
      ],
      []
    )
    ?.join(',')

  // 使用产品钩子获取数据
  const { data: result, isLoading } = useProduct({ handles: handles })

  // 过滤出有标签的数据作为标签页
  const tabs = data?.list?.filter((item) => item.label)

  // 计算显示数量
  const displayCount = useMemo(() => {
    const defaultCount = isMobile ? 4 : 8
    const totalProducts = activeProducts?.length || 0
    return isViewMore && totalProducts > defaultCount
      ? data?.sliceNumber || defaultCount
      : totalProducts
  }, [isViewMore, data?.sliceNumber, isMobile, activeProducts])

  // 容器引用
  const containerRef = useRef(null)

  // 滚动到中心的函数
  const scrollToCenter = (element) => {
    const container = containerRef.current
    const containerWidth = container.offsetWidth
    const elementWidth = element.offsetWidth
    const elementLeft = element.offsetLeft

    // 计算滚动位置，使当前元素居中
    const scrollPosition = elementLeft - containerWidth / 2 + elementWidth / 2

    container.scrollTo({
      left: scrollPosition,
      behavior: 'smooth', // 平滑滚动
    })
  }

  // 组件渲染
  return (
    <div
      className={cn(s.autoDealsWrap, {
        ['!pt-0 md:!pt-0']: data?.delTopPadding,
        ['!pb-0 md:!pb-0']: data?.delBottomPadding,
      })}
      style={rmEmptyKeys({ background: data.bgColor })}
    >
      {data?.title && (
        <div className="relative mx-auto flex w-fit items-center justify-center md:block">
          {data?.title_icon_l && (
            <Picture
              source={`${data.title_icon_l}`}
              className="absolute left-[-106px] md:hidden"
              style={rmEmptyKeys({
                width: data?.style?.leftIconWidth,
                top: data?.styles?.leftIconTop,
                left: data?.styles?.leftIconLeft,
              })}
            />
          )}
          <Text
            html={data.title}
            className={s.title}
            variant="sectionHeading"
          />
          {data?.title_icon_r && (
            <Picture
              source={`${data.title_icon_r}`}
              className="absolute right-[-106px] md:hidden"
              style={rmEmptyKeys({
                width: data?.styles?.rightIconWidth,
                top: data?.styles?.rightIconTop,
                right: data?.styles?.rightIconRight,
              })}
            />
          )}
        </div>
      )}
      {data?.subTitle && (
        <Text
          html={data.subTitle}
          className={
            'text-[#939393 mt-[10px] text-center text-[20px] md:text-[16px]'
          }
          variant="paragraph"
        />
      )}

      {isLoading ? (
        <div className="content">
          <Loading />
        </div>
      ) : (
        <>
          {tabs?.length > 1 && (
            <div className={s.tabWrap}>
              <div
                className={cn('auto-deals-tab-wrap', s.tabBox, {
                  ['grid-cols-2']: tabs?.length == 2,
                })}
                ref={containerRef}
              >
                {tabs.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={cn(
                        'relative whitespace-nowrap pb-[14px] text-[16px] text-[#fff] md:pb-[8px] md:text-[12px]',
                        {
                          [s.activeTab]: index === current,
                        }
                      )}
                      onClick={(e) => {
                        setCurrent(index)
                        const products = data.list?.find(
                          (p) => p.label === item?.label
                        )?.products
                        setActiveProducts(products)
                        scrollToCenter(e.target)
                      }}
                    >
                      {item.label}
                    </button>
                  )
                })}
              </div>
            </div>
          )}

          {activeProducts?.length ? (
            <div
              className={cn(
                'mt-[32px] grid w-full grid-cols-4 gap-[16px] md:mt-[24px] md:grid-cols-2 md:gap-[12px] md-x:grid-cols-2'
              )}
            >
              {activeProducts.slice(0, displayCount)?.map((item, index) => {
                let product = normalizeStorefrontProduct(item, result, null)
                return (
                  <Card
                    data={data}
                    sectionId={id}
                    product={product}
                    isMobile={isMobile}
                    key={index + 'card'}
                    pageHandle={pageHandle}
                    buttons={data?.buttons}
                    summerCampaignDiscount={summerCampaignDiscount}
                  />
                )
              })}
            </div>
          ) : (
            ''
          )}
          {data?.hasViewMore && activeProducts?.length > (isMobile ? 4 : 8) && (
            <div
              className="mt-[40px] flex cursor-pointer items-center justify-center gap-[8px] md:mt-[12px]"
              onClick={() => {
                setIsViewMore(!isViewMore)
                if (!isViewMore) {
                  jump(`#${id}`, {
                    duration: 150,
                    offset: 50,
                  })
                }
              }}
            >
              <Text
                html={
                  isViewMore ? data?.viewMore : data?.hide || data?.viewMore
                }
                className={`text-[16px] font-semibold  text-[#fff]`}
                style={rmEmptyKeys({
                  color: data?.styles?.viewMoreColor,
                })}
              />
              <ChevronRightIcon
                className={cn(`rotate-90 text-[#fff]`, {
                  ['rotate-[270deg]']: !isViewMore,
                })}
                style={rmEmptyKeys({
                  color: data?.styles?.viewMoreColor,
                })}
                width={18}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

// 导出组件
export default Index
