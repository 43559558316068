/* eslint-disable react-hooks/rules-of-hooks */
import Slider from 'react-slick'
import cn from 'classnames'

import style from './youtubeSlider.module.css'

import Picture from '../ui/Picture'
import { useState } from 'react'
import { Button, Text } from '@components/ui'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

const PrevArrow = ({ onClick, s }) => (
  <ChevronLeftIcon className={cn(s.arrow, s.arrowLeft)} onClick={onClick} />
)

const NextArrow = ({ onClick, s }) => (
  <ChevronRightIcon className={cn(s.arrow, s.arrowRight)} onClick={onClick} />
)

const YoutubeSlider = ({ data, s: gs, mobileDotes = false }) => {
  const s = { ...style, ...gs }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data.items.length < 5 ? data.items.length : 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow s={s} />,
    nextArrow: <NextArrow s={s} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: mobileDotes,
        },
      },
    ],
  }
  if (!data?.items) return null

  const [videoOpen, setVideoOpen] = useState(null)
  return (
    <>
      {data.title && (
        <Text
          html={data.title}
          className="text-5xl font-bold mb-10 md:text-[1.7rem] text-center"
        />
      )}
      <Slider {...settings}>
        {data.items.map((item, index) => (
          <div
            className="w-full px-2 md:!w-[78%] md:px-0 md:mx-[11%]"
            key={index}
          >
            <div className="relative">
              <Picture
                className="w-full aspect-w-16 aspect-h-9"
                source={`${item.image}`}
              />
              <button
                className="absolute top-[40%] left-[45%]"
                aria-label="play icon"
                onClick={() => setVideoOpen(item.video_url)}
              >
                <Picture
                  className="w-6 hover:scale-150"
                  source={data.play_icon_img}
                />
              </button>
              <p
                className="absolute bottom-1 left-1 text-white font-semibold"
                dangerouslySetInnerHTML={{ __html: item.title }}
              ></p>
            </div>
            <div className="flex flex-col w-full">
              {item.author_info && (
                <div
                  className={cn(
                    'flex items-center ',
                    data.items.length <= 2 ? 'my-5' : 'my-2'
                  )}
                >
                  <Picture
                    className={cn(
                      data.items.length <= 2
                        ? 'w-[80px] md:w-[46px] mr-5 md:mr-2.5'
                        : 'w-[60px] md:w-[36px] mr-3 md:mr-1.5'
                    )}
                    imgClassName="rounded-full"
                    source={`${item.author_info.icon}`}
                  />
                  <div className="flex flex-col ">
                    <Text
                      html={item.author_info.name}
                      className={cn(
                        'mr-5 md:mr-2.5',
                        data.items.length <= 2
                          ? 'text-[20px] md:text-[14px]'
                          : 'md:text-[14px]'
                      )}
                    ></Text>
                    <Text
                      className={s.fans}
                      html={item.author_info.fans}
                    ></Text>
                  </div>
                </div>
              )}
              <p
                className={cn(
                  'mb-2 md:text-[12px]  line-clamp-3 md:line-clamp-5 md:min-h-[90px]',
                  data.items.length <= 2
                    ? 'text-[18px] min-h-[71px]'
                    : 'text-[14px] min-h-[65px]'
                )}
                dangerouslySetInnerHTML={{ __html: item.author_info.quote }}
              ></p>
            </div>
          </div>
        ))}
      </Slider>
      {videoOpen && (
        <div className={cn(s.dialogVide)}>
          <button className={s.closeWrap} onClick={() => setVideoOpen(null)}>
            <XMarkIcon className={s.close} />
          </button>
          <div className={s.box}>
            <iframe
              width="100%"
              height="100%"
              src={videoOpen}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  )
}

export default YoutubeSlider
