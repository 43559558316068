/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react'
import cn from 'classnames'
import s from './index.module.css'
import { Picture } from '@components/ui'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const Tab65w = ({ data }) => {
  if (!data) return null
  const [current, setCurrent] = useState(0)

  const handleTab = (title, index) => {
    if (index == current) return
    setCurrent(index)
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'click',
      eventAction: 'switch_tab',
      eventLabel: title, // 传用户点击 Tab 的文案标题
      eventValue: 'undefined',
      nonInteraction: true,
    })
  }

  return (
    <section className={cn(s.chargingWrap)}>
      <div
        style={{ background: data?.bgColor || '#000' }}
        className={cn('py-[60px]', !data?.mob_nav_scroll && 'md:hidden')}
      >
        {data?.title && (
          <h1
            className={cn(
              'mx-auto max-w-[1280px]',
              !data?.mob_nav_scroll && 'md:hidden'
            )}
            style={{ color: data?.color || '#fff' }}
          >
            {data?.title}
          </h1>
        )}
        <div
          className={cn(
            'mx-auto w-full max-w-[1280px] overflow-hidden',
            data?.nav_position === 'bottom' && 'flex flex-col-reverse'
          )}
        >
          <ul
            className={cn(
              s.col,
              data?.nav_position === 'bottom'
                ? 'mt-[40px] md:mt-[20px]'
                : 'mb-[30px] md:mb-[20px]'
            )}
          >
            {data.list.map((item, index) => (
              <li
                key={index}
                role="menuitem"
                tabIndex="0"
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    handleTab(item.title, index)
                  }
                }}
                onClick={() => handleTab(item.title, index)}
                style={
                  index == current
                    ? {
                        color: data.color,
                        borderBottom: `2px solid ${data.color}`,
                      }
                    : {}
                }
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            ))}
          </ul>
          <div className={s.subtitleWrap}>
            {data?.list.map((item, index) => (
              <div
                key={index}
                className={cn(
                  s.imageWrap,
                  index == current && '!relative !opacity-[1]'
                )}
              >
                <Picture
                  key={index}
                  alt="3334"
                  quality={2}
                  source={`${item.img}, ${item.mobImg} 769`}
                  // className="aspect-w-[1200] aspect-h-[590] w-full md:aspect-w-[320] md:aspect-h-[320]"
                />
                {item.subtitle && <p className={s.subtitle}>{item.subtitle}</p>}
              </div>
            ))}
            {/* <Picture
                alt="3334"
                quality={2}
                className="w-full aspect-w-[1200] aspect-h-[590]"
                source={`${data?.list[current].img} 769`}
              />
              {data?.list[current].subtitle && (
                <p className={s.subtitle}>{data?.list[current].subtitle}</p>
              )} */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <ul
        style={{ background: data?.mobBgColor || '#000' }}
        className={cn(
          s.mobCol,
          !data?.mob_nav_scroll ? 'hidden md:block' : 'hidden'
        )}
      >
        {data?.title && (
          <h1 style={{ color: data?.mobColor || '#333' }}>{data?.title}</h1>
        )}
        {data?.list.map((item, index) => (
          <li key={index}>
            <p className={s.title} style={{ color: data?.mobColor || '#333' }}>
              {item.title}
            </p>
            <Picture
              key={index}
              alt="3334"
              quality={2}
              source={`${item.mobImg} 769`}
              className="aspect-w-[320] aspect-h-[320] w-full"
            />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Tab65w
